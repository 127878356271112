<template>
  <div>
    <v-btn
        color="primary"
        dark @click="openDialogMandat">Nouveau mandat
    </v-btn>
    <v-dialog v-model="dialogNewMandat" max-width="500">
      <v-card>
        <v-card-title class="title-column">
          <p class="pa-0 ma-0">Ajout d'un mandat {{mandateType}}  </p>
          <v-subheader class="pa-0 ma-0">Recherche par numéro client</v-subheader>
        </v-card-title>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12 sm6 md6 class="pa-2">
              <v-text-field
                  label="Numéro du client"
                  v-model="mandateToCreate.customerId"
                  hint='Mettre un numéro de client valide pour pouvoir rechercher'
                  required
              />
            </v-flex>
            <v-flex xs12 sm6 md6 class="pa-2 flex-box">
              <v-btn
                color="primary"
                @click="getCustomerFromId"
                :loading="loading"
                :disabled="this.$v.mandateToCreate.customerId.$invalid"
              >
                Recherche du client
              </v-btn>
            </v-flex>
            <v-flex xs12 sm6 md6 class="pa-2" v-if="customerForNewMandate">
              <v-text-field
                  label="Nom du client"
                  :value="customerForNewMandate.name"
                  disabled
              />
            </v-flex>
            <v-flex xs12  md6 class="pa-2" v-if="customerForNewMandate">
              <v-text-field
                  label="Agence du client"
                  :value="customerForNewMandate.office.name"
                  disabled
              />
            </v-flex>
            <v-flex xs12  md6 class="pa-2" v-if="customerForNewMandate">
              <v-text-field
                  label="Commercial"
                  :value="generatesSalesManName"
                  disabled
              />
            </v-flex>
            <v-flex
                xs12
                md6 class="pa-2" v-if="mandateType === 'lre-simple' && customerForNewMandate">
              <v-switch
                  v-model="isReturnPaperChecked"
                  label="Retour papier"
                  hide-details
              ></v-switch>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <v-select
                  v-if="isReturnPaperChecked"
                  v-model="mandateToCreate.returnPaper"
                  :items="returnPaperNumber"
                  label="Nombre de jours"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat @click="closeDialogMandate">Annuler</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 flat
                 @click="sendDialogData"
                 :disabled="!customerForNewMandate"
          >
            Continuer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, numeric } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import Mandate from '@/models/mandate';

export default {
  name: 'BaseMandateDialog',
  mixins: [validationMixin],
  validations: {
    mandateToCreate: {
      customerId: { required, numeric },
    },
  },
  props: {
    mandateType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogNewMandat: false,
      mandateToCreate: new Mandate(),
      askedLreAt: false,
      activateLreAt: false,
      requiredRules: [
        (v) => !!v || 'Champs requis',
      ],
      returnPaperNumber: ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
      isReturnPaperChecked: false,
    };
  },
  computed: {
    ...mapState({
      mandate: (state) => state.mandate.item,
      customerForNewMandate: (state) => state.mandate.customerForNewMandate,
      loading: (state) => state.mandate.loading,
    }),
    generatesSalesManName() {
      if (this.customerForNewMandate.salesman) {
        return (
          `${this.customerForNewMandate.salesman.firstName

          } ${
            this.customerForNewMandate.salesman.lastName}`);
      }
      return '';
    },
  },
  methods: {
    closeDialogMandate() {
      this.dialogNewMandat = false;
      this.mandateToCreate = new Mandate();
    },
    sendDialogData() {
      if (!this.$v.$invalid && this.customerForNewMandate !== null) {
        this.dialogNewMandat = false;
        const newMandate = {
          customer: this.customerForNewMandate.name,
          office: this.customerForNewMandate.office.name,
          salesMan: this.generatesSalesManName,
          customerId: this.mandateToCreate.customerId,
          mandateType: this.mandateType,
          returnPaper: this.mandateToCreate.returnPaper,
        };
        if (newMandate.mandateType === 'lre-q') {
          delete newMandate.returnPaper;
        }
        this.$store.dispatch('mandate/createNewMandate', newMandate);
        this.mandateToCreate = new Mandate();
      }
    },
    focusActivateLreAt() {
      if (this.mandateToCreate.isActivateLre) {
        this.activateLreAt = true;
      } else {
        this.mandateToCreate.lreActivatedAt = '';
      }
    },
    openDialogMandat() {
      this.dialogNewMandat = true;
    },
    getCustomerFromId() {
      this.$store.dispatch('mandate/getCustomerFromId', this.mandateToCreate.customerId);
    },
  },
};
</script>

<style scoped>
.div-lre-simple{
  width: 100%;
}
.title-column{
  display: flex;
  flex-direction: column;
}
.flex-box{
  display: flex;
  align-items: center;
}
</style>
