import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import upperFirst from 'lodash/upperFirst';
import { getVal } from '@/utils/common';

/**
 *
 * @param {Object} state current module state
 * @param {Object} newState object to affect
 * @param {String|String[]} pickWith pick which properties to merge
 */
export const mergeStateWithObject = (state, newState, pickWith = null) => {
  if (!isObject(newState)) {
    throw new Error('The new state must be an object');
  }
  const stateToMerge = pickWith ? pick(newState, pickWith) : newState;
  Object.keys(stateToMerge).forEach((key) => {
    state[key] = stateToMerge[key];
  });
};

/**
 *
 * @param {Object} state current module state
 * @param {Function} newState the new state to apply
 * @param {String|String[]} pickWith pick which properties to merge
 */
export const resetState = (state, newState, pickWith = null) => {
  if (!isFunction(newState)) {
    throw new Error('The next state must be a function returning an object');
  }
  const initializedNewState = newState();
  return mergeStateWithObject(state, initializedNewState, pickWith);
};

/**
 * Easily reset your module state based on your initialState
 * @param {Function} initialState
 * @param {Object} param
 * @param {String|String[]} param.pickWith pick which properties to merge
 * @param {String} param.name name of the mutation
 * @returns {Object}
 */
export const addResetMutation = (initialState, { pickWith = null, name = 'reset' } = {}) => ({
  [name](state) {
    resetState(state, initialState, pickWith);
  },
});

/**
 * mutation key property naming helper
 * @param {string} prefix
 * @returns {Function}
 */
export const getMutationKeyFromName = (prefix) => (property) => `${prefix}${upperFirst(property)}`;

/**
 * Add basic setter mutation
 * @example
 *  addSetterMutation('alpha') will generate:
 *    {
 *      setAlpha(state, newValue) {
 *        state.alpha = newValue;
 *      }
 *   }
 * @example
 *  addSetterMutation('alpha', { name: 'setWithNewName' }) will generate:
 *    {
 *      setWithNewName(state, newValue) {
 *        state.alpha = newValue;
 *      }
 *   }
 * @example
 *  addSetterMutation('alpha', { name: (property) => `redefinedSetter${property}` }) will generate:
 *    {
 *      redefinedSetteralpha(state, newValue) {
 *        state.alpha = newValue;
 *      }
 *   }
 * @param {String} property
 * @param {Object} param
 * @param {String|Function} param.name the name of the mutation property
 * @returns {Object}
 */
export const addSetterMutation = (property, { name = getMutationKeyFromName('set') } = {}) => {
  const fnName = getVal(name, property);
  return {
    [fnName](state, newValue) {
      state[property] = newValue;
    },
  };
};

/**
 * Add basic setters mutations
 * @example
 *  addSettersMutations(['alpha', 'beta']) will generate:
 *    {
 *      setAlpha(state, newValue) {
 *        state.alpha = newValue;
 *      },
 *        setBeta(state, newValue) {
 *        state.beta = newValue;
 *      }
 *    }
 * @param {String[]|Object[]} properties
 * @returns {Object}
 */
export const addSettersMutations = (properties) => properties.reduce((memo, propertyDescriptor) => {
  let newMutation = {};
  if (isString(propertyDescriptor)) {
    newMutation = addSetterMutation(propertyDescriptor);
  } else {
    const { property, ...rest } = propertyDescriptor;
    newMutation = addSetterMutation(property, ...rest);
  }
  return {
    ...memo,
    ...newMutation,
  };
}, {});

export default {
  addResetMutation,
  resetState,
  mergeStateWithObject,
  addSetterMutation,
  addSettersMutations,
};
