<template>
    <div>
        <base-page-title v-bind:page-title="title"></base-page-title>
        <v-card>
            <form v-if="!showItem">
                <v-layout row wrap justify-center>
                    <v-flex xs12 sm10 md8 lg6>
                        <div class="create-ticket-form">

                            <base-upload :extensions="filesExtension"
                                         :mimeTypes="mimeTypes"
                                         :clear = files
                                         v-on:newUploadedFile="updateFilesToUpload">

                            </base-upload>
                            <div v-if="campaign.uploadedFiles.length>0">
                                <v-data-table
                                        :items="campaign.uploadedFiles"
                                        class="elevation-1"
                                        rows-per-page-text="Nombre de lignes"
                                >
                                    <template slot="items" slot-scope="props">
                                        <td class="text-xs-left">{{ props.item }}</td>
                                        <td class="text-xs-left">
                                            <v-btn
                                                icon
                                                class="mx-0"
                                                @click="removeItem(props.item)">
                                                <v-icon color="red">close</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <template
                                            slot="pageText"
                                            slot-scope="props">
                                      {{ props.pageStart }} - {{ props.pageStop }}
                                      sur {{ props.itemsLength }}
                                    </template>
                                </v-data-table>
                            </div>

                            <div class="text-xs-center text-md-right">
                                <v-btn
                                        :loading="loading"
                                        round
                                        v-if="showActivateBtn"
                                        color="teal" dark large @click="activate">
                                    Activer la campagne
                                </v-btn>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </form>
            <v-data-table
                    :headers="headers"
                    :items="campaign.items"
                    class="elevation-1"
                    v-if="showItem"
                    rows-per-page-text="Nombre de lignes"
            >
                <template slot="items" slot-scope="props">
                    <td class="text-xs-left">{{ props.item.id }}</td>
                    <td class="text-xs-left">{{ props.item.clientReference}}</td>
                    <td class="text-xs-left">{{ props.item.filename }}</td>
                    <td class="text-xs-left">
                        <ul id="example-1" v-if="props.item.messages">
                            <li v-for="(item, key) in props.item.messages" v-bind:key="key">
                                {{ item.email }} | {{ item.status|translateInvitationState }}
                            </li>
                        </ul>
                    </td>
                </template>
                <template
                        slot="pageText"
                        slot-scope="props"
                >{{ props.pageStart }} - {{ props.pageStop }} sur {{ props.itemsLength }}</template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'PageCampaignNew',
  data: () => ({
    toggle_exclusive: null,
    files: [],
    filesExtension: ['csv', 'pdf'],
    mimeTypes: ['application/pdf', 'text/csv', 'application/vnd.ms-excel'],
    headers: [
      {
        value: 'id',
        text: '#',
        sortable: true,
        align: 'left',
      },
      {
        value: 'clientReference',
        text: 'Client',
        sortable: true,
        align: 'left',
      },
      {
        value: 'filename',
        text: 'Fichier',
        sortable: true,
      },
      {
        value: 'status',
        text: 'Messages',
        sortable: false,
        format: 'status',
        style: 'status',
      },
    ],
  }),
  computed: {
    showItem() {
      return (this.campaign.items.length !== 0);
    },
    showActivateBtn() {
      return (this.campaign.status === 'pending' && this.campaign.uploadedFiles.length !== 0);
    },
    ...mapState({
      campaign: (state) => state.campaign.item,
      loading: (state) => state.campaign.loading,
    }),
    title() {
      if (this.campaign.createdAt) {
        return `Campagne ${this.campaign.name} du ${moment(this.campaign.sendDate).format('DD/MM/YYYY')} (${this.translateCampaignState(this.campaign.status)})`;
      }
      return '';
    },
  },
  watch: {
    files: {
      handler() {
        if (this.files.length > 0) {
          this.submit();
          this.files = [];
        }
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      this.$store.dispatch('campaign/sendFiles', {
        owner: this.campaign.id,
        data: this.files,
      });
    },
    activate() {
      this.$store.dispatch('campaign/activate', { owner: this.campaign.id });
    },
    clear() {
      this.$v.$reset();
      this.$store.commit('campaign/resetItem');
    },
    removeItem(item) {
      this.$store.dispatch('campaign/removeItem', {
        owner: this.campaign.id,
        item: { filename: item },
      });
    },
    updateFilesToUpload(value) {
      this.files = value;
    },
    load() {
      if (this.$route.params.id) {
        this.$store.dispatch('campaign/getItem', this.$route.params.id, this.query);
      }
    },
    translateCampaignState(state) {
      let translatedState = '';

      switch (state) {
        case 'created':
          translatedState = 'En attente de fichier';
          break;
        case 'ready':
          translatedState = 'Validée en attente de traitement';
          break;
        case 'pending':
          translatedState = 'Brouillon en attente de validation';
          break;
        case 'done':
          translatedState = 'Traitée';
          break;
        case 'error':
          translatedState = 'Impossible de traiter votre demande';
          break;
        default:
          translatedState = '';
      }

      return translatedState;
    },
  },
  filters: {
    translateInvitationState(state) {
      let translatedState = '';

      switch (state) {
        case 'sent':
          translatedState = 'Envoyé';
          break;
        case 'opened':
          translatedState = 'Ouvert';
          break;
        case 'unknown':
          translatedState = 'Inconnu';
          break;
        case 'queued':
          translatedState = 'En cours de traitement';
          break;
        case 'clicked':
          translatedState = 'Ouvert';
          break;
        case 'spam':
          translatedState = 'Spam';
          break;
        case 'blocked':
          translatedState = 'Bloqué';
          break;
        case 'created':
          translatedState = 'En attente d\'envoi';
          break;
        default:
          translatedState = state;
      }

      return translatedState;
    },
    moment(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },

  },
  created() {
    this.load();
  },
};
</script>

<style scoped>

    .create-ticket-form {
        margin-bottom: 20px;
    }

    @media (max-width: 599px) {

        .create-ticket-form {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
</style>
