export default function createDownloadFile(res, customer, format, filename) {
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  let fileNameTmp = filename;

  if (format === 'pdf') {
    fileNameTmp = `export-${customer.id}-sepa.pdf`;
  }

  if (format === 'zip') {
    fileNameTmp = res.headers['content-disposition'].split('=')[1];
  }

  link.href = url;
  link.setAttribute(
    'download',
    fileNameTmp,
  );
  document.body.appendChild(link);
  link.click();
}
