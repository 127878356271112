import api from '@/utils/api';
import { getDefaultPagination, commitPagination } from '@/utils/store-util';
import { wrapActionWithRequest } from '@/utils/cancel-action';

const state = {
  code: null,
  order: 'id',
  query: {},
  total: 0,
  noDataMessage: '',
  pages: 0,
  loading: false,
  checkInvitationLoading: false,
  items: [],
  states: [],
  item: null,
  itemContacts: [],
  pagination: getDefaultPagination(),
  facets: [],
  header: [
    {
      value: 'id',
      text: 'Numéro de facture',
      sortable: false,
      align: 'left',
    },
    {
      value: 'customer.name',
      text: 'Client',
      sortable: false,
    },
    {
      value: 'office.name',
      text: 'Agence',
      sortable: false,
    },
    {
      value: 'issuedAt',
      text: "Date d'emission",
      sortable: false,
    },
    {
      value: 'dueAt',
      text: 'Date limite de paiement',
      sortable: false,
    },
    {
      value: 'sendAt',
      text: "Date d'envoi",
      sortable: false,
    },
    {
      value: 'totalTtc',
      text: 'Total TTC',
      sortable: false,
    },
    {
      value: 'Status',
      text: 'Statut',
      sortable: false,
    },
    {
      value: 'sendType',
      text: 'Diffusion',
      sortable: false,
    },
    {
      value: 'paymentMode',
      text: 'Mode de règlement',
      sortable: false,
    },
    {
      value: 'name',
      text: 'Actions',
      sortable: false,
    },
  ],
};

const getInvoicesAction = ({ commit }, query = { }) => {
  commit('setLoading', { loading: true });
  commit('setQuery', query);
  api.getData('/api/invoices/search', query).then((res) => {
    commit('setItems', res.data['hydra:member']);
    commit('setFacets', res.data.facets);
    commit('setTotal', res.data['hydra:totalItems']);
    commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], query);
    commit('setNoDataMessage', api.emptyListMessage);
  }).catch(() => {
    commit('setLoading', { loading: false });
    commit('setNoDataMessage', api.emptyListMessage);
  });
};

const actions = {
  get({ commit }, id) {
    commit('setLoading', { loading: true });
    api.getData(`/api/invoices/${id}`).then((res) => {
      commit('setItem', res.data);
      commit('setLoading', { loading: false });
    });
  },
  getInvoices: wrapActionWithRequest(getInvoicesAction, { autoAbort: true }),

  getInvoiceFile({ commit }, { invoiceNumber, invoiceFile }) {
    commit('setLoading', { loading: true });
    api.downloadFile(`/get-aws-file?fileName=${invoiceFile.path}&tag=invoice`)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `facture-${invoiceNumber}.${invoiceFile.type}`,
        );
        document.body.appendChild(link);
        link.click();
        commit('setLoading', { loading: false });
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
    state.loading = false;
  },
  setItem(state, item) {
    state.item = item;
    state.loading = false;
  },
  setTotal(state, total) {
    state.total = total;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setNoDataMessage(state, message) {
    state.noDataMessage = message;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    state.items = [];
    state.total = 0;
  },
  setQuery(state, query) {
    state.query = query;
  },
  setFacets(state, facets) {
    state.facets = facets;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
