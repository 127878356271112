/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import api from '@/utils/api';

const state = {
  oidcUser: null,
  user: null,
  token: null,
  navigation: null,
  userInfo: {
    messages: [],
    notifications: [],
    tasks: [],
  },
  loading: true,
};

const actions = {
  setOidcUser({ commit }, { oidcUser }) {
    commit('setOidcUser', oidcUser);
  },
  setAfcTrust({ commit }, { afcTrust }) {
    commit('setOidcUser', afcTrust);
  },
  setNavigation({ commit }) {
    commit('setLoading', { loading: true });
    api.getData('/access').then((res) => {
      commit('SET_NAVIGATION', res.data);
      commit('setLoading', { loading: false });
    });
  },
  logout({ commit }) {
    commit('setOidcUser', null);
    commit('setAfcTrust', null);
  },
};

const mutations = {
  setOidcUser(state, oidcUser) {
    state.oidcUser = oidcUser;
  },
  setAfcTrust(state, afcTrust) {
    state.token = afcTrust;
  },
  SET_NAVIGATION(state, navigation) {
    state.navigation = navigation;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
