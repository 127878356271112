<template>
    <div>
        <v-layout row wrap align-center class="content-header-2col">
            <v-flex xs6 class="content-header-2col-title">
                <base-page-sub-title v-bind:page-title="pageTitle"></base-page-sub-title>
            </v-flex>
        </v-layout>
        <div class="table-filters">
            <v-layout row wrap>
                <v-flex xs12 sm4>
                    <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Recherche"
                            hide-details
                            dark
                    ></v-text-field>
                </v-flex>
            </v-layout>
        </div>
        <v-card>
            <v-progress-circular
                    v-if="loading"
                    :size="50"
                    indeterminate color="primary"
            >
            </v-progress-circular>
            <v-data-table
                    :headers="headers"
                    :loading="loading"
                    :items="campaigns"
                    :search="search"
                    :server-items-length="total"
                    class="ticket-list"
                    :options.sync="pagination"
                    :no-data-text="noDataMessage"
                    hide-default-footer
            >
                <template v-if="!loading" v-slot:body="{items}" >
                    <tr v-for="(item, i) in items" :key="i" @click="showItem(item)">
                        <td class="text-xs-left">{{ item.id }}</td>
                        <td class="text-xs-left">{{ item.createdAt|moment }}</td>
                        <td class="text-xs-left">{{ item.name }}</td>
                        <td class="text-xs-left">{{ item.sender }}</td>
                        <td class="text-xs-left">{{ item.sendDate|moment }}</td>
                        <td class="text-xs-left">
                          {{ item.status|translateInvitationState }}
                        </td>
                        <td class="justify-center layout px-0">
                            <v-btn icon class="mx-0" @click="showItem(item)">
                                <v-icon color="secondary">visibility</v-icon>
                            </v-btn>
                        </td>
                    </tr>

                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                </v-alert>
            </v-data-table>
            <div v-if="!loading" class="text-xs-center pt-2 campaigns-pagination">
                <v-pagination v-model="page" :total-visible="7" :length="pages">
                </v-pagination>
            </div>
        </v-card>
    </div>

</template>

<script>

import moment from 'moment';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { SEARCH_DEBOUNCE_WAIT_TIMER } from '@/utils/constants';
import BasePageSubTitle from './BasePageSubTitle';

const DEFAULT_ROWS_PER_PAGE = 15;
export default {
  name: 'BaseCampaignList',
  components: { BasePageSubTitle },
  props: {
    title: null,
    campaigns: null,
    total: null,
  },
  data() {
    return {
      page: 1,
      search: '',
      pagination: {
        sortBy: ['id'],
        descending: false,
        rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
      query: {
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        order: {},
      },
    };
  },
  computed: {
    ...mapState({
      headers: (state) => state.campaign.header,
      loading: (state) => state.campaign.loading,
      pages: (state) => state.campaign.pagination.pages,
      noDataMessage: (state) => state.campaign.noDataMessage,
      stateQuery: (state) => state.campaign.query,
    }),
    pageTitle() {
      return this.loading ? `${this.title}` : `${this.title} (${this.total})`;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    page: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
  },
  methods: {
    showItem(item) {
      if (item.status === 'created' || item.status === 'pending') {
        this.$router.push({
          name: 'PageCampaignEdit',
          params: { id: item.id },
        });
      } else {
        this.$router.push({
          name: 'PageCampaignShow',
          params: { id: item.id },
        });
      }
    },
    delaySearch: debounce(function search() {
      this.buildQuery();
      this.$store.dispatch('campaign/getCampaigns', this.query);
    }, SEARCH_DEBOUNCE_WAIT_TIMER),
    buildQuery() {
      this.query.name = this.search;
      this.query.page = this.getCurrentPage();
      this.query.order = {};

      if (this.pagination.sortBy) {
        this.query.order[this.pagination.sortBy] = this.pagination.descending ? 'asc' : 'desc';
      }
    },
    getFilterHash() {
      return JSON.stringify({
        search: this.search,
      });
    },
    getCurrentPage() {
      const filterHash = this.getFilterHash();
      // When the filter change, reset the current page to one.
      if (!this.lastFilterHash || filterHash !== this.lastFilterHash) {
        this.lastFilterHash = filterHash;
        return 1;
      }
      return this.page;
    },
  },
  filters: {
    translateInvitationState(state) {
      let translatedState = '';

      switch (state) {
        case 'created':
          translatedState = 'En attente de fichier';
          break;
        case 'ready':
          translatedState = 'Validée en attente de traitement';
          break;
        case 'pending':
          translatedState = 'Brouillon en attente de validation';
          break;
        case 'done':
          translatedState = 'Traitée';
          break;
        case 'error':
          translatedState = 'Impossible de traiter votre demande';
          break;
        default:
          translatedState = '';
      }

      return translatedState;
    },
    moment(date) {
      return moment(date)
        .format('DD/MM/YYYY HH:mm');
    },

  },
  created() {
    this.delaySearch();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .table-filters {
        background-color: #37474f;
        border-radius: 10px;
        margin-top: 12px;
        margin-bottom: 24px;
        padding: 0 20px;
    }

    .ticket-list {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .campaigns-pagination .v-pagination {
        margin-bottom: 20px;
    }

    @media (max-width: 959px) {
        .campaigns-pagination {
            overflow-y: hidden;
            padding-left: 10px;
            padding-right: 10px;
        }

        .campaigns-pagination .v-pagination {
            overflow-y: scroll;
        }
    }
</style>
