var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"content-header-2col",attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{staticClass:"content-header-2col-title",attrs:{"xs6":""}},[_c('base-page-title',{attrs:{"page-title":_vm.pageTitle}})],1),_c('v-spacer'),_c('ul',{staticClass:"legend"},[_c('li',[_c('span',{staticClass:"red"}),_vm._v(" "+_vm._s(_vm.$i18n.t('Blocked customer LCCA')))])])],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs3":""}},[_c('base-facets',{attrs:{"facets":_vm.facets},on:{"updateFacets":_vm.updateFiltersQuery}})],1),_c('v-flex',{attrs:{"xs9":""}},[_c('v-card',[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":50,"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"options":_vm.pagination,"no-data-text":_vm.noDataMessage,"hide-default-footer":"","items-per-page":30},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm.loading)?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i,class:item._source['isBlockedForLcca'] === true
                                    ? 'red white--text'
                                    : '',attrs:{"title":(item._source['isBlockedForLcca'] === true)
                                   && _vm.$i18n.t('Blocked customer LCCA')},on:{"click":function($event){return _vm.showItem(item._source)}}},_vm._l((_vm.headers),function(field,indexfield){return _c('td',{key:indexfield,staticClass:"text-xs-left"},[(   field.value !== 'office'
                                                && field.value !== 'isBlockedForLcca'
                                                && field.value !== 'logoPath'
                                                && field.value !== 'lreContextType'
                                                && field.value !== 'salesman')?_c('p',[_vm._v(" "+_vm._s(item._source[field.value])+" ")]):_vm._e(),(field.value === 'office' )?_c('p',[_vm._v(" "+_vm._s(item._source[field.value].name))]):_vm._e(),(field.value === 'isBlockedForLcca' )?_c('p',[_vm._v(" "+_vm._s(item._source[field.value] === true ? 'oui' : 'non')+" ")]):_vm._e(),(field.value === 'salesman')?_c('p',[_vm._v(" "+_vm._s(_vm._f("concatSalesmanName")(item._source[field.value]))+" ")]):_vm._e(),(field.value === 'lreContextType')?_c('p',[_vm._v(" "+_vm._s(_vm.$t( item._source[field.value]))+" ")]):_vm._e()])}),0)}),0)]}}:null],null,true)}),_c('div',{staticClass:"text-xs-center pt-2 clients-pagination"},[_c('v-pagination',{attrs:{"total-visible":7,"length":_vm.pages},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }