export default class Director {
  constructor(contact, existingDirector = null) {
    this.contact = contact;
    if (existingDirector !== null) {
      this.refCliContactId = existingDirector.contactid;
      this.customer = existingDirector.customerid;
      this.office = existingDirector.officeid;
      this.accountIdentifier = existingDirector.account_identifier;
      this.contactIdentifier = existingDirector.contact_identifier;
      this.name = existingDirector.name;
      this.firstName = existingDirector.firstname;
      this.email = existingDirector.email;
      this.phone = existingDirector.phone;
      this.mobile = existingDirector.mobile;
      this.firmIdentifier = existingDirector.firm_identifier;
      this.customerType = existingDirector.customer_type;
      this.companyName = existingDirector.company_name;
      this.siren = existingDirector.siren;
      this.officeName = existingDirector.office_name;
      this.siret = existingDirector.siret;
      this.accountName = existingDirector.account_name;
      this.agencyIdentifier = existingDirector.agency_identifier;
      this.third = existingDirector.thirdid;
      this.companyCode = existingDirector.company_code;
    }
  }
}
