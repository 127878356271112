import * as Oidc from 'oidc-client';
import jwtDecode from 'jwt-decode';
import store from '@/store';
import router from '@/router';

// use environment variable

// Oidc.Log.logger = console;
// Oidc.Log.level = Oidc.Log.DEBUG;

const settings = {
  authority: process.env.VUE_APP_AUTHORITY,
  client_id: process.env.VUE_APP_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_REDIRECT_URI,
  post_logout_redirect_uri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
  response_type: process.env.VUE_APP_RESPONSE_TYPE,
  // response_mode:'fragment',
  scope: process.env.VUE_APP_SCOPE,

  popup_redirect_uri: 'http://localhost:15000/identityserver-sample-popup-signin.html',
  // popup_post_logout_redirect_uri: 'http://localhost:15000/identityserver-sample-popup-signout.html',

  silent_redirect_uri: process.env.VUE_APP_REDIRECT_URI,
  automaticSilentRenew: true,
  // silentRequestTimeout:10000,
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
};

const mgr = new Oidc.UserManager(settings);

// mgr.events.addUserLoaded(() => {
//   mgr.getUser()
//     .then(() => {
//       console.log('getUser loaded user after userLoaded event fired');
//     });
// });
// mgr.events.addUserUnloaded(() => {
//   console.log('User logged out locally');
// });
// mgr.events.addAccessTokenExpiring(() => {
//   console.log('Access token expiring...');
// });
// mgr.events.addSilentRenewError((err) => {
//   console.log(`Silent renew error: ${err.message}`);
// });
// mgr.events.addUserSignedOut(() => {
//   console.log('User signed out of OP');
// });

export default {
  resetUserData() {
    store.commit('user/setOidcUser', null);
    sessionStorage.removeItem('oidc-calback');
  },
  login() {
    this.resetUserData();
    mgr.signinRedirect()
      .then(() => {
        console.log('signinRedirect done');
      })
      .catch((err) => {
        console.log(err);
      });
  },
  isLoggedIn() {
    if (store.state.user.oidcUser !== null) {
      return true;
    }
    return false;
  },
  logout() {
    this.resetUserData();
    mgr.signoutRedirect();
  },
  backToAuthenticationPage() {
    router.push({ path: '/403' });
  },
  isGranted($role) {
    if ($role === undefined) {
      return false;
    }
    const decodeToken = jwtDecode(store.state.user.oidcUser.access_token);
    if (decodeToken.resource_access.herculefront.roles.includes('ROLE_SUPERADMIN')) {
      return true;
    }
    return $role.every((o) => decodeToken.resource_access.herculefront.roles.includes(o));
  },
  getUser() {
    return mgr.getUser();
  },
};
