export default class Lre {
  constructor() {
    this.type = '';
    this.senderAddress = '';
    this.recipientAddress = '';
    this.recipientLastName = '';
    this.recipientFirstName = '';
    this.subject = '';
    this.content = '';
  }
}
