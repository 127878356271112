const state = {
  url: null,
};

const mutations = {
  setUrl(state, url) {
    state.url = url;
  },
  unsetUrl(state) {
    state.url = null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,

};
