import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import user from './store/modules/user';
import notice from './store/modules/notice';
import customer from './store/modules/customer';
import sdcBankingAccount from './store/modules/sdcBankingAccount';
import order from './store/modules/order';
import config from './store/modules/config';
import clientContact from './store/modules/clientContact';
import campaign from './store/modules/campaign';
import mandate from './store/modules/mandate';
import mandateListSetting from './store/modules/mandateListSetting';
import note from './store/modules/note';
import invoice from './store/modules/invoice';
import tag from './store/modules/tag';
import communication from './store/modules/communication';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  modules: {
    user,
    notice,
    customer,
    sdcBankingAccount,
    clientContact,
    config,
    order,
    campaign,
    mandate,
    mandateListSetting,
    note,
    invoice,
    tag,
    communication,
  },
});
