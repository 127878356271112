import 'babel-polyfill';
import FileUpload from 'vue-upload-component';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import './components/_globals';

import App from './App';
import router from './router';
import store from './store';
import i18n from './i18n';
import filters from './filters';

Vue.use(filters);
Vue.config.productionTip = false;
Vue.use(Vuetify, {
  theme: {
    primary: '#2980b9',
    secondary: '#37474f',
    accent: '#2980b9',
    error: '#e73434',
    white: '#ffffff',
    notification: '#e74c3c',
    greydarken5: '#333333',
    orangeInvitation: '#f26539',
    greenActif: '#28ac37',
  },
});

Vue.component('file-upload', FileUpload);
const app = new Vue({
  router,
  store,
  i18n,
  vuetify: new Vuetify(),
  render: (h) => h(App),
}).$mount('#app');

if (window.Cypress) {
  window.app = app;
}
