<template>
  <div class="menu-top hidden-sm-and-down">
      <v-btn text :to="pathMenu" class="btn-nohover">{{labelMenu}}</v-btn>
  </div>
</template>

<script>
export default {
  name: 'BaseMenuTop',
  data: () => ({
  }),
  props: {
    labelMenu: String,
    pathMenu: String,
  },
};
</script>

<style scoped>
.menu-top{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.menu-top .btn-nohover{
  font-size: 13px;
  font-family: 'UbuntuBold';
  color: #37474f;
}
.menu-top .btn-nohover:hover{
  color: #2980b9;
}
.menu-top .btn-nohover:hover:before{
  opacity: 0;
}
.menu-top .btn-nohover:hover:after{
  content: '';
  display: block;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -14px;
  left: 0;
  background-color: #2980b9;
  border-radius: 4px;
}
.menu-top .v-btn--active{
  color: #2980b9;
}
.menu-top .v-btn--active .v-btn__content:before{
  background-color: transparent;
}
.menu-top .v-btn--active:before{
  content: '';
  display: block;
  height: 5px;
  width: 100%;
  position: absolute;
  top: auto;
  bottom: -14px;
  background-color: #2980b9;
  border-radius: 4px;
  opacity: 1;
}
</style>
