<template>
  <div>
    <v-progress-circular
        v-if="loading"
        :size="50"
        indeterminate color="primary"
    />
    <div v-if="!loading">
      <div class="d-flex justify-space-between">
        <base-page-title v-bind:page-title="title"></base-page-title>
        <v-spacer/>
        <v-btn
            color="primary"
            outlined
            rounded
            depressed
            @click="returnToOrderList"
            :replace=true
        >
          <v-icon left light>arrow_back</v-icon>
          Retour
        </v-btn>
      </div>
      <v-layout>
        <v-flex xs6>
          <order-detail-information class="ma-2"/>
        </v-flex>
        <v-flex xs6>
<!--          <order-detail-identity class="ma-2"/>-->
        </v-flex>
      </v-layout>
      <order-detail-documents class="ma-2"/>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import OrderDetailInformation from '../components/commande/OrderDetailInformation';
import OrderDetailDocuments from '../components/commande/OrderDetailDocuments';

export default {
  components: {
    OrderDetailDocuments, OrderDetailInformation,
  },
  data() {
    return {
      title: 'Détail de la Commande',
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.order.loading,
      order: (state) => state.order.item,
    }),
  },
  methods: {
    returnToOrderList() {
      this.$router.push({ name: 'PageOrdersList' });
    },
  },
  created() {
    this.$store.dispatch('order/get', this.$route.params.id);
  },
};
</script>
<style>
.user-detail .content-header-2col{
  margin-bottom: 8px!important;
}
#user-name{
  font-size: 14px;
  font-family: 'ubuntumedium', sans-serif;
  color: #333333;
}
.user-detail-title{
  padding-top: 35px;
  color: #333333;
  font-family: 'ubuntumedium', sans-serif;
}
.user-detail-title span{
  margin-left: 10px;
}
.user-detail-infos .item-title{
  margin-bottom: 4px;
  font-size: 11px;
  color: #999999;
}
.user-detail-infos .item-title .v-icon{
  margin-left: 2px;
}
.user-detail-infos .item{
  font-size: 15px;
  color: #333333;
  font-family: Arial, sans-serif;
}
.v-divider{
  margin-left: 24px;
  margin-right: 24px;
}
.user-roles-table{
  width: 100%;
  table-layout: fixed;
  border-spacing: 2px;
  margin-top: 8px;
  margin-bottom: 55px;
}
.user-roles-table tr{
  background-color: #f3f3f3;
}
.user-roles-table tr td{
  width: 50%;
  padding: 12px 15px;
  color: #565656;
}
.user-roles-table tr td:first-child{
  text-align: left;
}
.user-roles-table tr td:last-child{
  padding-bottom: 0;
}
.user-roles-table tr td:last-child >>> .v-input--checkbox{
  margin: 0;
  padding: 0;
}
.user-roles-table tr td:last-child >>> .v-input--checkbox .v-input__slot{
  margin-bottom: 0;
  justify-content: center;
}
.user-btn-actions-wrap{
  padding: 45px 0 35px;
}
.user-btn-actions{
  padding: 0 15px;
}
@media (max-width: 599px){
  .user-detail-layout{
    display: block;
  }
  .user-roles-table{
    margin-bottom: 20px;
  }
  .user-btn-actions-wrap{
    display: block;
    padding-bottom: 10px;
  }
  .user-btn-actions{
    margin-bottom: 25px;
  }
}
</style>
