import api from '@/utils/api';
import { commitPagination, getDefaultPagination } from '../../utils/store-util';
import { wrapActionWithRequest } from '../../utils/cancel-action';

const state = {
  items: [],
  item: null,
  query: {},
  loading: false,
  total: 0,
  noDataMessage: '',
  pages: 0,
  pagination: getDefaultPagination(),
  headers: [
    {
      value: 'id',
      text: 'Numéro',
      sortable: true,
      align: 'left',
    },
    {
      value: 'type',
      text: 'Type d\'envoi',
      sortable: true,
      align: 'center',
    },
    {
      value: 'senderAddress',
      text: 'Expediteur',
      sortable: true,
      align: 'center',
    },
    {
      value: 'recipientAddress',
      text: 'destinataire',
      sortable: true,
      align: 'center',
    },
    {
      value: 'status',
      text: 'status de l\'envoi',
      sortable: true,
      align: 'center',
    },
    {
      value: 'providerStatus',
      text: 'status de reception',
      sortable: true,
      align: 'center',
    },
  ],
};

const getCommunicationAction = ({ commit }, query = { }) => {
  commit('setLoading', { loading: true });
  commit('setQuery', query);
  api.getData('/api/demo-client', query).then((res) => {
    commit('setItems', res.data['hydra:member']);
    commit('setTotal', res.data['hydra:totalItems']);
    commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], query);
    commit('setNoDataMessage', api.emptyListMessage);
  }).catch(() => {
    commit('setLoading', { loading: false });
    commit('setNoDataMessage', api.emptyListMessage);
  });
};

const actions = {
  createCommunication({ commit }, communication) {
    commit('SET_LOADING', { loading: true });
    api.postData('/api/demo-client', communication)
      .then((res) => {
        commit('CREATE_COMMUNICATION', res.data);
        commit(
          'notice/setNotice',
          {
            text: `${communication.type} envoyer`,
            color: 'info',
          },
          { root: true },
        );
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      })
      .finally(() => {
        commit('SET_LOADING', { loading: false });
      });
  },
  getCommunication: wrapActionWithRequest(getCommunicationAction, { autoAbort: true }),

};

const mutations = {
  SET_LOADING(state, { loading }) {
    state.loading = loading;
  },
  CREATE_COMMUNICATION(state, communication) {
    state.items.push(communication);
  },
  setTotal(state, total) {
    state.total = total;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setNoDataMessage(state, message) {
    state.noDataMessage = message;
  },
  setItems(state, items) {
    state.items = items;
    state.loading = false;
  },
  setItem(state, item) {
    state.item = item;
    state.loading = false;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    state.items = [];
    state.total = 0;
  },
  setQuery(state, query) {
    state.query = query;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
