import api from '@/utils/api';
import Mandate from '@/models/mandate';
import Router from '../../router';
import { commitPagination, getDefaultPagination } from '../../utils/store-util';
import { wrapActionWithRequest } from '../../utils/cancel-action';

const getDefaultState = () => ({
  items: [],
  query: {},
  item: null,
  loading: false,
  total: 0,
  noDataMessage: '',
  pages: 0,
  pagination: getDefaultPagination(),
  customerForNewMandate: null,
});

const state = getDefaultState();

const mutations = {
  SET_LOADING(state, { loading }) {
    state.loading = loading;
  },
  ADD_NEW_MANDATE(state, newMandate) {
    state.items.push(newMandate);
  },
  SET_MANDATE_LIST(state, mandateLit) {
    state.items = mandateLit;
    state.loading = false;
  },
  SET_MANDATE(state, mandate) {
    state.item = mandate;
    state.loading = false;
  },
  RESET_MANDATE(state) {
    state.item = new Mandate();
  },
  UPDATE_MANDATE(state, mandateResponse) {
    state.item = mandateResponse;
  },
  UPDATE_FILE_MANDATE(state, payload) {
    const mandateFile = payload.mandate;
    mandateFile.uploadedFiles = [];
    payload.base64.forEach((element) => mandateFile.uploadedFiles.push(element[0].file));
    state.item = mandateFile;
  },
  DELETE_MANDATE(state, mandateId) {
    state.items.splice(state.items.indexOf(mandateId), 1);
  },
  DELETE_MANDATE_FILE(state, mandateUpdate) {
    state.item = mandateUpdate;
  },
  SET_CUSTOMER_FOR_NEW_MANDATE(state, customerInfo) {
    state.customerForNewMandate = customerInfo;
    state.loading = false;
  },
  RESET_CUSTOMER_FOR_NEW_MANDATE(state) {
    state.customerForNewMandate = null;
  },
  setTotal(state, total) {
    state.total = total;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setNoDataMessage(state, message) {
    state.noDataMessage = message;
  },
  setItems(state, items) {
    state.items = items;
    state.loading = false;
  },
  setItem(state, item) {
    state.item = item;
    state.loading = false;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    state.items = [];
    state.total = 0;
  },
  setQuery(state, query) {
    state.query = query;
  },
};
const getMandateAction = ({ commit }, query = { }) => {
  commit('setLoading', { loading: true });
  commit('setQuery', query);
  api.getData('/api/mandates', query).then((res) => {
    commit('setItems', res.data['hydra:member']);
    commit('setTotal', res.data['hydra:totalItems']);
    commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], query);
    commit('setNoDataMessage', api.emptyListMessage);
  }).catch(() => {
    commit('setLoading', { loading: false });
    commit('setNoDataMessage', api.emptyListMessage);
  });
};

const actions = {
  addNewMandateInList({ commit }, newMandate) {
    commit('ADD_NEW_MANDATE', newMandate);
  },
  updateMandate({ commit }, mandate) {
    api.putData(`/api/mandates/${mandate.id}`, mandate)
      .then((res) => {
        commit('UPDATE_MANDATE', res.data);
        commit(
          'notice/setNotice',
          {
            text: `Mandat pour le client ${res.data.customer} a été mis à jour. `,
            color: 'info',
          },
          { root: true },
        );
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  deleteMandate({ commit }, mandateId) {
    api.deleteData(`/api/mandates/${mandateId}`)
      .then(() => {
        commit('DELETE_MANDATE', mandateId);
        commit(
          'notice/setNotice',
          {
            text: 'Le mandat est Supprimé. ',
            color: 'info',
          },
          { root: true },
        );
      });
  },
  deleteMandateFile({ commit }, payload) {
    api.putData(`/api/mandates/${payload.mandate.id}/delete-file`, { fileId: payload.fileId })
      .then((res) => {
        commit('DELETE_MANDATE_FILE', res.data);
        commit(
          'notice/setNotice',
          {
            text: 'Ce document a été Supprimé. ',
            color: 'info',
          },
          { root: true },
        );
      });
  },
  getMandate: wrapActionWithRequest(getMandateAction, { autoAbort: true }),
  uploadMandateFile({ commit }, payload) {
    const data = new FormData();
    payload.files.forEach((element, index) => {
      data.append(`files[${index}]`, element.file);
    });

    api
      .uploadloadFiles(`/api/mandates/${payload.mandate.id}/upload-files`, data)
      .then((res) => {
        commit('UPDATE_MANDATE', res.data);
        commit(
          'notice/setNotice',
          {
            text: 'Document(s) ajouté(s)',
            color: 'info  ',
          },
          { root: true },
        );
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },

  transformBase64Mandate({ commit }, mandate) {
    api
      .getData(`/api/mandates/${mandate.id}/files`)
      .then((res) => {
        commit('UPDATE_FILE_MANDATE', { mandate, base64: res.data });
      })
      .catch(() => {
      });
  },
  setMandateById({ commit }, id) {
    commit('SET_LOADING', { loading: true });
    api
      .getData(`/api/mandates/${id}`)
      .then((res) => {
        commit('RESET_MANDATE');
        commit('SET_MANDATE', res.data);
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  createNewMandate({ commit }, newMandate) {
    api
      .postData('/api/mandates', newMandate)
      .then((res) => {
        Router.push({ name: 'PageMandateDetails', params: { id: res.data.id }, query: { mandateType: res.data.mandateType } });
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  getCustomerFromId({ commit }, customerId) {
    commit('SET_LOADING', { loading: true });
    api.getData(`/api/customers/${customerId}`)
      .then((respsonse) => {
        commit('SET_CUSTOMER_FOR_NEW_MANDATE', respsonse.data);
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  resetCustomerForNewMandate({ commit }) {
    commit('RESET_CUSTOMER_FOR_NEW_MANDATE');
  },
};

const getters = {
  getMandateById: (state) => (id) => state.items.find((mandate) => mandate.id === id),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
