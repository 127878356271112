import api from '@/utils/api';
import { getDefaultPagination, commitPagination } from '@/utils/store-util';
import { wrapActionWithRequest } from '@/utils/cancel-action';

const state = {
  code: null,
  order: 'id',
  query: {},
  total: 0,
  noDataMessage: '',
  pages: 0,
  loading: false,
  checkInvitationLoading: false,
  items: [],
  states: [],
  item: null,
  itemContacts: [],
  pagination: getDefaultPagination(),
  header: [
    {
      value: 'id',
      text: 'Numéro',
      sortable: false,
      align: 'left',
    },
    {
      value: 'createdAt',
      text: 'Date de création',
      sortable: false,
    },
    {
      value: 'customer.office.name',
      text: 'Agence',
      sortable: false,
    },
    {
      value: 'customer.clientReference',
      text: 'Reference Client',
      sortable: false,
    },
    {
      value: 'customer.name',
      text: 'Client',
      sortable: false,
    },
    {
      value: 'deliveryType',
      text: 'Type de commande',
      sortable: false,
    },
    {
      value: 'sendType',
      text: 'Diffusion',
      sortable: false,
    },
    {
      value: 'dla',
      text: 'Date limite d\'affranchissement',
      sortable: false,
    },
    {
      value: 'status',
      text: 'status',
      sortable: false,
    },
    {
      value: 'name',
      text: 'Actions',
      sortable: false,
    },
  ],
};

const getOrdersAction = ({ commit }, query = { }) => {
  commit('setLoading', { loading: true });
  commit('setQuery', query);
  api.getData('/api/customers/orders', query).then((res) => {
    commit('setItems', res.data['hydra:member']);
    commit('setTotal', res.data['hydra:totalItems']);
    commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], query);
    commit('setNoDataMessage', api.emptyListMessage);
  }).catch(() => {
    commit('setLoading', { loading: false });
    commit('setNoDataMessage', api.emptyListMessage);
  });
};

const actions = {
  get({ commit }, id) {
    commit('setLoading', { loading: true });
    api.getData(`/api/customers/orders/${id}`).then((res) => {
      commit('setItem', res.data);
    });
  },
  getOrders: wrapActionWithRequest(getOrdersAction, { autoAbort: true }),
  getOrdersType({ commit }, payload) {
    commit('setLoading', { loading: true });
    api.getData(`/api/customers/orders/status/${payload[1]}`, payload[0])
      .then((res) => {
        commit('setItems', res.data['hydra:member']);
        commit('setTotal', res.data['hydra:totalItems']);
        commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], payload[0]);
        commit('setLoading', { loading: false });
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
    state.loading = false;
  },
  setItem(state, item) {
    state.item = item;
    state.loading = false;
  },
  setTotal(state, total) {
    state.total = total;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setNoDataMessage(state, message) {
    state.noDataMessage = message;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    state.items = [];
    state.total = 0;
  },
  setQuery(state, query) {
    state.query = query;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
