<template>
  <v-container fluid pa-0>
    <v-progress-circular
        v-if="loading"
        :size="50"
        indeterminate color="primary"
    />
    <v-layout row wrap v-if="!loading">
      <v-flex xs7 class="pa-2">
        <base-mandate-update :mandate-type="this.$route.query.mandateType"/>
      </v-flex>
      <v-flex xs5 class="pa-2">
        <base-mandate-file-render
            v-if="mandate.uploadedFiles.length > 0"
            :uploaded-files="mandate.uploadedFiles"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import BaseMandateUpdate from '@/components/BaseMandateUpdate';
import BaseMandateFileRender from '@/components/BaseMandateFileRender';

export default {
  name: 'PageMandateDetails',
  components: {
    BaseMandateFileRender,
    BaseMandateUpdate,
  },
  props: {
    title: null,
  },
  data: () => ({
    filesExtension: ['csv', 'pdf'],
    mimeTypes: ['application/pdf', 'text/csv', 'application/vnd.ms-excel'],
  }),
  computed: {
    ...mapState({
      mandate: (state) => state.mandate.item,
      loading: (state) => state.mandate.loading,
    }),
  },
  created() {
    this.$store.dispatch('mandate/setMandateById', this.$route.params.id);
  },
};
</script>

<style scoped>
.flex-h{
  height: 254px;
}
</style>
