<template>
  <div>
    <base-order-list title="Commandes"></base-order-list>
  </div>
</template>

<script>
export default {
  name: 'PageOrdersList',

};
</script>
