import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import api from '@/utils/api';
import { isPromise } from '@/utils/common';

// Adaptation of https://github.com/sebdiem/vuex-cancellable-actions
// Will be replace by a shared library cf:https://github.fiducial.dom/SIWEB/src-pfc/pull/103

const ACTION_CANCELLED_ERROR_MESSAGE = 'ActionCancelledError';

class ActionCancelledError extends Error {
  constructor(message) {
    super(message);
    this.message = message;
    this.name = ACTION_CANCELLED_ERROR_MESSAGE;
  }
}

export const isActionCancelledError = (error) => error && error.name === ACTION_CANCELLED_ERROR_MESSAGE;

function actionCancellerFactory() {
  const cancelledActions = new Set();
  return {
    cancelAction({ source }) {
      api.abort(source);
      cancelledActions.add(source.token);
    },
    isActionCancelled(cancelToken) {
      return cancelledActions.has(cancelToken);
    },
    cleanAction(cancelToken) {
      cancelledActions.delete(cancelToken);
    },
  };
}

const actionCanceller = actionCancellerFactory();

function actionWrapper(action) {
  return async function next(originalContext, ...wrapArgs) {
    const originaSource = get(originalContext, '__request.source', null);
    const hasCancelToken = Boolean(originaSource);
    const source = hasCancelToken ? originaSource : api.getCancelToken();
    const cancelToken = source.token;
    const { commit, dispatch } = originalContext;

    const newContext = {
      ...originalContext,
      __request: {
        source,
      },
      request: {
        cancelToken,
      },
      commit: function wrappedCommit(...args) {
        try {
          if (actionCanceller.isActionCancelled(cancelToken)) {
            throw new ActionCancelledError('Concurrent commit action called');
          }
          return commit(...args);
        } catch (error) {
          return null;
        }
      },
      dispatch: function wrappedDispatch(...args) {
        try {
          if (actionCanceller.isActionCancelled(cancelToken)) {
            throw new ActionCancelledError('Concurrent dispatch action called');
          }
          return dispatch(...args);
        } catch (error) {
          return null;
        }
      },
    };

    // handle async And sync actions
    let ret = action(newContext, ...wrapArgs);
    if (!isPromise(ret)) ret = Promise.resolve(ret);
    await ret;
    if (hasCancelToken) {
      actionCanceller.cleanAction(cancelToken);
    }
    return ret;
  };
}

/* Add a `request` to the context of
* each action, which is passed to subsequent calls to dispatch
* or commit.
* This enables to cancel an action by preventing it to further
* commit to the store, using the cancelAction below.
*
* Note: commits already performed won't be undone.
*
  To abort a request through axios, you must forward request to the api to cancel the request
*/
export function makeCancellable(refActions) {
  if (isFunction(refActions)) {
    return actionWrapper(refActions);
  }
  return Object.keys(refActions).reduce((acc, key) => {
    acc[key] = actionWrapper(refActions[key]);
    return acc;
  }, {});
}

/* Cancel an action that was made cancellable by the wrapper above.
 *
 * Note: commits already performed won't be undone.
 */
export function cancelAction(internalRequest) {
  actionCanceller.cancelAction(internalRequest);
}

/* `takeLatest` enables to decorate an action to guarantee that
 * only the last call is running at any time.
 * As soon as a new call is triggered the previous call commits
 * are guaranteed to be ignored (even if they happen later on due
 * to asynchronicity).
 *
 * Note: this function requires all involved actions to be made
 * cancellable by the makeCancellable function.
 * Note: commits already performed are never undone.
 */
export function takeLatest(action) {
  const previousCalls = [];
  // eslint-disable-next-line consistent-return
  return async function takeLatestActionWrapper(context, ...args) {
    while (previousCalls.length > 0) {
      cancelAction(previousCalls.pop());
    }
    // eslint-disable-next-line no-underscore-dangle
    previousCalls.push(context.__request);
    try {
      return await action(context, ...args);
    } catch (e) {
      if (e.name !== 'ActionCancelledError') throw e;
    }
  };
}

/**
 * Add `request` to the context
 * Note: You MUST forward the request to the api
 * @param {Function} action
 * @param {Object} opts
 * @param {Boolean} opts.autoAbort
 *  automatically abort the request when the action is called a second time
 * @returns {Function}
 */
export const wrapActionWithRequest = (action, { autoAbort = false } = {}) => {
  if (!autoAbort) return action;
  return makeCancellable(takeLatest(action));
};

export default {
  isActionCancelledError,
  makeCancellable,
  cancelAction,
  takeLatest,
  wrapActionWithRequest,
};
