<template>
  <v-layout class="upload" row wrap>
    <v-flex  d-flex xs12 sm12 md12>
      <v-list two-line >
        <v-list-tile
                v-for="item in files"
                :key="item.id"
                avatar
                @click.prevent="$refs.upload.remove(item)"
                color="indigo"
        >
          <v-list-tile-avatar>
            <v-icon right color="red dark">delete</v-icon>
          </v-list-tile-avatar>

          <v-list-tile-content color="blue-grey">
            <v-list-tile-title > {{(item.name)}} {{item.size|formatFileSize}}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-flex>
    <v-flex d-flex xs12 sm6 md6>
      <file-upload
        :extensions="extensions"
        :accept="accept"
        :directory="directory"
        :maximum="maxFiles"
        :multiple=true
        :size="maxFileSize"
        v-model="files"
        :drop=true
        :drop-directory=true
        @input-filter="inputFilter"
        @input="onInputUpdateParent"
        ref="upload"
      >
        <v-btn color="blue-grey" class="white--text">Ajouter un fichier
          <v-icon right dark>cloud_upload</v-icon>
        </v-btn>
      </file-upload>
    </v-flex>
    <v-flex v-for=" error in errors" :key="error.id" d-flex xs12 sm12 md12>
      <v-alert :value="true" color="error" icon="warning" dismissible outline>{{ error }}</v-alert>
    </v-flex>
  </v-layout>
</template>

<script>
import truncate from 'lodash/truncate';
import { fileSizeFormat } from '@/utils/common';

const UPLOAD_MAX_FILES = 200;
const UPLOAD_MAX_FILE_SIZE = 1024 * 1024 * 10; // 10Mo

export default {
  name: 'BaseUpload',
  props: {
    extensions: {
      type: Array,
      default: null,
    },
    mimeTypes: {
      type: Array,
      default: () => [],
    },
    clear: null,
  },
  data: () => ({
    files: [],
    errors: [],
    maxFileSize: UPLOAD_MAX_FILE_SIZE,
    maxFiles: UPLOAD_MAX_FILES,
    directory: false,
  }),
  watch: {
    clear: {
      handler() {
        this.empty();
      },
      deep: true,
    },
  },
  methods: {
    onInputUpdateParent() {
      this.$emit('newUploadedFile', this.files);
    },
    onClearUpdateParent() {
      this.$emit('newUploadedFile', this.files);
    },
    empty() {
      this.files = [];
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        if (UPLOAD_MAX_FILES !== 0 && this.files.length === UPLOAD_MAX_FILES) {
          this.errors.push(`Le nombre de fichiers est limité à ${UPLOAD_MAX_FILES}`);
          return prevent();
        }

        if (this.mimeTypes.length && !this.mimeTypes.includes(newFile.type)) {
          this.errors.push(`Le type du fichier ${newFile.name} n'est pas accepté`);
          return prevent();
        }

        if (newFile.size > UPLOAD_MAX_FILE_SIZE) {
          this.errors.push(`La taille des fichiers est limité à ${fileSizeFormat(UPLOAD_MAX_FILE_SIZE)}`);
          return prevent();
        }
      }

      return true;
    },
    concatWithColon() {
      return this.mimeTypes.length
        ? this.mimeTypes.reduce((accumulator, currentValue) => `${accumulator},${currentValue}`)
        : null;
    },
    truncateName(name) {
      return truncate(name, 10);
    },
  },
  filters: {
    formatFileSize(size) {
      return fileSizeFormat(size);
    },
  },
  computed: {
    accept() {
      return this.concatWithColon();
    },
  },
};
</script>

<style scoped>
</style>
