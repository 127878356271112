<template>
    <v-form>
    <v-row>
      <v-col
          cols="6"
          v-for="(indexfield, index) in headers"
          v-bind:key="index"
          class="pa-5"
      >
        <v-text-field
            :label="indexfield.text"
            :value="item[indexfield.value]"
            v-model="editSdcMandate[indexfield.value]"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-space-around pa-4">
      <v-btn
          color="red"
          text
          @click.prevent="cancelEditSdc"
      >
        Annuler
      </v-btn>
      <v-btn
          color="primary"
          text
          @click.prevent="edit"
      >
        Modifier
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BaseEditSdcMandate',
  props: {
    headers: {},
    item: {},
    cancelEdit: {},
  },
  data() {
    return {
      editSdcMandate: {
        buildingNumber: null,
        iban: null,
        businessIdentifierCode: null,
        bankDomiciliation: null,
        rum: null,
        sdcName: null,
      },
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customer,
    }),
  },
  methods: {
    ...mapActions([
      'sdcBankingAccount/update',
    ]),
    cancelEditSdc() {
      this.cancelEdit();
      this.resetSdcEditMandate();
    },
    resetSdcEditMandate() {
      this.headers.forEach((item) => {
        this.editSdcMandate[item.value] = this.item[item.value];
      });
    },
    edit() {
      const id = this.item.id;
      const data = this.editSdcMandate;
      this['sdcBankingAccount/update']({ id, data });
    },
  },
  mounted() {
    this.resetSdcEditMandate();
  },
};
</script>
