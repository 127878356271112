<template>
  <base-order-list title="Commandes en boite de reception" status="inbox"></base-order-list>
</template>

<script>
import BaseOrderList from '@/components/BaseOrderList';

export default {
  name: 'PageOrderListInbox',
  components: { BaseOrderList },
};
</script>

<style scoped>

</style>
