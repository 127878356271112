<template>
  <div>
    <base-invoice-list :title="'Liste de factures'"/>
  </div>
</template>

<script>
import BaseInvoiceList from '@/components/BaseInvoiceList';

export default {
  name: 'PageInvoiceList',
  components: { BaseInvoiceList },
};
</script>
