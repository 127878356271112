<template>
  <div>
    <v-list v-if="items">
      <v-list-item-group class="d-flex flex-column align-stretch">
        <v-list-item
                class="my-2 px-4"
                v-for="(item, index) in items"
                :key="index"
                :to="item.path"
                exact
                :v-if="isGranted(item.roles)"
        >
          <v-icon>{{item.action}}</v-icon>
          <v-list-item-content >
            <v-list-item-title class="d-flex ml-5" :to="item.path">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import AuthenticationGuard from '@/utils/AuthGuard';

export default {
  name: 'BaseMenu',
  data: () => ({
  }),
  props: {
    items: null,
  },
  methods: {
    isGranted(role) {
      return AuthenticationGuard.isGranted(role);
    },
  },
};
</script>

<style scoped>
  .menu-left .v-btn{
    font-size: 13px;
    font-family: 'UbuntuBold';
  }
  .menu-left .v-list__item__title{
    text-align: center;
    font-size: 13px;
    font-family: 'UbuntuBold';
    text-transform: uppercase;
  }
  .menu-left .v-list__group.v-list__group--active:before{
    background: transparent;
  }
  .menu-left .v-list__group.v-list__group--active:after{
    background: transparent;
  }
  .menu-left .v-list__group__items .v-list__item--link{
    padding: 0;
  }
  .menu-left .v-list__group__items .v-list__item--link .v-icon{
    padding-right: 15px;
  }
  .menu-left .v-list__group__header--active > div{
    position: relative;
  }
  .menu-left .v-list__group__header--active > div:before{
    content: '';
    display: block;
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2980b9;
  }
  .menu-left .v-list__group__items .v-list__item--active .v-list__item__content{
    color: #ffffff;
  }
  .menu-left > .v-btn{
    width: 100%;
    margin: 0;
    height: 48px;
  }
</style>
