<template>
  <v-card class="pa-2">
    <v-card-title class="user-detail-title">
      <v-avatar color="primary" size="34">
        <v-icon dark>account_circle</v-icon>
      </v-avatar>
      <span>Appel de fonds</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="showFoundCall = !showFoundCall">
        <v-icon>{{ isShowFoundCall }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-slide-y-transition>
      <v-container fluid v-show="showFoundCall">
        <v-layout
            row
            v-for="(value, key, index) in customer.setting"
            :key="index"
            :v-if="key !== 'id'"
        >
          <v-flex xs6 class="list-item pa-0">
            <p><i>{{$t(key)}}</i></p>
          </v-flex>
          <v-flex xs6 class="list-item pa-0">
            <p><b>{{$t(value)}}</b></p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-slide-y-transition>
  </v-card>
</template>
<script>
export default {
  name: 'base-funds-call-detail',
  props: {
    customer: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showFoundCall: true,
    };
  },
  computed: {
    isShowFoundCall() {
      return this.showFoundCall ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
  },
};
</script>
<style scoped>
.list-item {
  display: flex;
  justify-content: start;
}

.user-detail-title span {
  margin-left: 10px;
}

.user-roles-table tr {
  background-color: #f3f3f3;
}

.user-roles-table tr td {
  width: 50%;
  padding: 12px 15px;
  color: #565656;
}

.user-roles-table tr td:first-child {
  text-align: left;
}

.user-roles-table tr td:last-child {
  padding-bottom: 0;
}

@media (max-width: 599px) {
}
</style>
