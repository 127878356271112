<template>
  <div>
    <v-container fluid fill-height text-xs-center>
      <v-layout row wrap>
        <v-flex xs12>
          <base-customer-list title="Clients"/>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'PageSupportCustomersList',
};
</script>
