<template>

  <v-container fluid pa-0>
    <v-progress-circular
        v-if="loading"
        :size="50"
        indeterminate color="primary"
    />
    <v-layout row>
    <v-flex xs6 >
      <base-page-title :page-title="pageTitle"/>
    </v-flex>
    <v-flex xs6 class="btn-back">
      <v-btn
          color="primary"
          outlined
          rounded
          depressed
          :to="back"
      >
        <v-icon left light>arrow_back</v-icon>
        Retour à la liste
      </v-btn>
    </v-flex>
  </v-layout>
    <v-layout row>
      <v-flex xs6 class="">
        <base-invoice-additional-information :item="item"/>
      </v-flex>
      <v-flex xs6 class="">
        <base-invoice-recipient-address :item="item"/>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs6 class="">
        <base-invoice-general-info :item="item"/>
      </v-flex>
      <v-flex xs6 class="">
        <base-invoice-price-info :item="item"/>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BasePageTitle from '@/components/BasePageTitle';
import BaseInvoiceGeneralInfo from '@/components/BaseInvoiceGeneralInfo';
import BaseInvoicePriceInfo from '@/components/BaseInvoicePriceInfo';
import BaseInvoiceAdditionalInformation from '@/components/BaseInvoiceAdditionalInformation';
import BaseInvoiceRecipientAddress from '@/components/BaseInvoiceRecipientAddress';

export default {
  name: 'BaseInvoiceDetail',
  components: {
    BaseInvoiceRecipientAddress,
    BaseInvoiceAdditionalInformation,
    BaseInvoicePriceInfo,
    BaseInvoiceGeneralInfo,
    BasePageTitle,
  },
  data() {
    return {
      invoiceId: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.invoice.loading,
      item: (state) => state.invoice.item,
    }),
    pageTitle() {
      return `Detail de la facture ${this.item.number}`;
    },
    back() {
      return '/finance';
    },
  },
  methods: {
    ...mapActions([
      'invoice/get',
    ]),
  },
  created() {
    this.invoiceId = this.$route.params.id;
    this['invoice/get'](this.invoiceId);
  },
};
</script>

<style>
.list-item {
  display: flex;
  justify-content: start;
}
.btn-back{
  display: flex;
  justify-content: end;
}
</style>
