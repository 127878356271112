<template>
  <base-order-list title="Commandes en cours de production" status="processed"></base-order-list>
</template>

<script>
import BaseOrderList from '@/components/BaseOrderList';

export default {
  name: 'PageOrderListProcessed',
  components: { BaseOrderList },
};
</script>

<style scoped>

</style>
