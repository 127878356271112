/**
 * Reverse the comparator parameters (ASC -> DESC or DESC -> ASC)
 * @param {Function} comparator
 * @returns {Function}
 */
export const byDesc = (comparator = () => {}) => (a, b) => comparator(b, a);

/**
 * Alphabetical Comparator - ASC
 * @param {String} a
 * @param {String} b
 * @returns {Number}
 */
export const alphabetically = (a, b) => a.localeCompare(b);
export const alphabeticallyDESC = byDesc(alphabetically);

/**
 * Number Comparator - ASC
 * @param {Number} a
 * @param {Number} b
 * @returns {Number}
 */
export const numerically = (a, b) => a - b;
export const numericallyDESC = byDesc(numerically);

/**
 * Can sort an array of objects with his key
 * @example
 *  [{ name: 'b' }, { name: 'a' }].sort(withKey('name')) => [{ name: 'a' }, { name: 'b' }]
 *  [{ value: 2 }, { value: 1 }].sort(withKey('name', numerically)) => [{ value: 1 }, { value: 2 }]
 * @param {String} key
 * @param {Function} comparator
 * @returns {Function}
 */
export const withKey = (key = null, comparator = alphabetically) => (a, b) => comparator(a[key], b[key]);

export default {
  byDesc,
  alphabetically,
  alphabeticallyDESC,
  numerically,
  numericallyDESC,
  withKey,
};
