export default class ClientContact {
  constructor(contact = {}) {
    this.name = contact.name ? contact.name : '';
    this.honorificTitle = contact.honorificTitle ? contact.honorificTitle['@id'] : '';
    this.email = contact.email ? contact.email : '';
    this.firstName = contact.firstName ? contact.firstName : '';
    this.phone = contact.phone ? contact.phone : '';
    this.mobile = contact.mobile ? contact.mobile : '';
    if (contact['@id']) this['@id'] = contact['@id'];
  }
}
