<template>
  <div class="rich-text-area">
    <p class="text-xs-left v-label">Description*</p>
    <div>
      <editor-menu-bar :editor="editor">
        <div slot-scope="{ commands, isActive }" class="rich-text-menu">
          <v-btn
            v-if="bold"
            flat
            small
            icon
            class="menubar__button"
            :input-value="isActive.bold()"
            @click="commands.bold"
          >
            <v-icon>format_bold</v-icon>
          </v-btn>

          <v-btn
            v-if="italic"
            flat
            small
            icon
            class="menubar__button"
            :input-value="isActive.italic()"
            @click="commands.italic"
          >
            <v-icon>format_italic</v-icon>
          </v-btn>

          <v-btn
            v-if="strike"
            flat
            small
            icon
            class="menubar__button"
            :input-value="isActive.strike()"
            @click="commands.strike"
          >
            <v-icon>format_clear</v-icon>
          </v-btn>

          <v-btn
            v-if="underline"
            flat
            small
            icon
            class="menubar__button"
            :input-value="isActive.underline()"
            @click="commands.underline"
          >
            <v-icon>format_underlined</v-icon>
          </v-btn>

          <v-btn
            flat
            small
            icon
            class="menubar__button"
            :input-value="isActive.paragraph()"
            @click="commands.paragraph"
          >P</v-btn>

          <v-btn
            v-for="level in heading"
            v-bind:key="level"
            flat
            small
            icon
            class="menubar__button"
            :input-value="isActive.heading({ level })"
            @click="commands.heading({ level })"
          >H{{ level }}</v-btn>

          <v-btn
            flat
            small
            icon
            class="menubar__button"
            :input-value="isActive.bullet_list()"
            @click="commands.bullet_list"
          >
            <v-icon>format_list_bulleted</v-icon>
          </v-btn>

          <v-btn
            flat
            small
            icon
            class="menubar__button"
            :input-value="isActive.ordered_list()"
            @click="commands.ordered_list"
          >
            <v-icon>format_list_numbered</v-icon>
          </v-btn>

          <v-btn
            flat
            small
            icon
            class="menubar__button"
            :input-value="isActive.blockquote()"
            @click="commands.blockquote"
          >
            <v-icon>format_quote</v-icon>
          </v-btn>

          <!-- <v-btn flat small icon
            class="menubar__button"
            :input-value="isActive.code_block()"
            @click="commands.code_block"
          >
            <v-icon>format_code</v-icon>
          </v-btn>-->
          <v-btn flat small icon class="menubar__button" @click="commands.undo">
            <v-icon>undo</v-icon>
          </v-btn>

          <v-btn flat small icon class="menubar__button" @click="commands.redo">
            <v-icon>redo</v-icon>
          </v-btn>
        </div>
      </editor-menu-bar>
      <!-- FUTURE KEV - USE MIN-WIDHT ON THE PROMOSOR EDITOR CONTENT THING <3 -->
      <editor-content class="text-xs-left editor__content" :editor="editor"/>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';

import {
  Blockquote,
  // CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions';
import { isNumber } from 'util';

// Available extensions
// the [key] will be usable as a component prop
const EXTENSIONS_PROP_MAPPER = {
  blockquote: () => new Blockquote(),
  bold: () => new Bold(),
  bulletList: () => new BulletList(),
  code: () => new Code(),
  // codeBlock: () => new CodeBlock(),
  hardBreak: () => new HardBreak(),
  heading: {
    props: {
      default: () => [1, 2, 3],
      type: [Boolean, Array],
      validator: (value) => isBoolean(value) || (Array.isArray(value) && value.every(isNumber)),
    },
    init: (value) => new Heading({ levels: isBoolean(value) ? [1, 2, 3] : value }),
  },
  history: () => new History(),
  italic: () => new Italic(),
  link: () => new Link(),
  listItem: () => new ListItem(),
  orderedList: () => new OrderedList(),
  strike: () => new Strike(),
  todoItem: () => new TodoItem(),
  todoList: () => new TodoList(),
  underline: () => new Underline(),
};

const EXTENSIONS_KEYS = Object.keys(EXTENSIONS_PROP_MAPPER);

const getExtensionFromProps = (extensionKey, val) => {
  const ref = EXTENSIONS_PROP_MAPPER[extensionKey];
  if (isFunction(ref)) {
    return ref(val);
  }
  return ref.init(val);
};

const getExtensionsFromProps = (props) => EXTENSIONS_KEYS.reduce((memo, extensionKey) => {
  if (extensionKey in props) {
    const value = props[extensionKey];
    if (value) {
      return [...memo, getExtensionFromProps(extensionKey, value)];
    }
  }
  return memo;
}, []);

const getExtensionProp = (propKey) => ({
  default: true,
  type: Boolean,
  ...(EXTENSIONS_PROP_MAPPER[propKey].props || {}),
});

const extensionsProps = EXTENSIONS_KEYS.reduce((memo, propKey) => ({
  ...memo,
  [propKey]: getExtensionProp(propKey),
}), {});

export default {
  name: 'BaseRichTextArea',
  props: {
    autoFocus: {
      default: false,
      type: Boolean,
    },
    value: {
      default: '',
      type: String,
    },
    ...extensionsProps,
  },
  components: {
    EditorContent,
    EditorMenuBar,
  },
  data: (cmp) => ({
    editor: new Editor({
      editable: true,
      autoFocus: cmp.autoFocus,
      extensions: getExtensionsFromProps(cmp),
      content: cmp.value,
      emptyDocument: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
      useBuiltInExtensions: true,
      dropCursor: {},
      onInit: () => {},
      onUpdate: () => {},
      onFocus: () => {},
      onBlur: () => {
        cmp.$emit('input', cmp.editor.getHTML());
      },
    }),
  }),
  beforeDestroy() {
    this.editor.destroy();
  },
  computed: {
    hasHeading() {
      return Array.isArray(this.props.heading) ? this.props.heading.length : this.props.heading;
    },
  },
};
</script>

<style scoped>
.menu-left .v-btn {
  font-family: "UbuntuBold";
  font-size: 13px;
}
</style>
