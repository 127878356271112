import api from '@/utils/api';
import router from '@/router';
import ClientContact from '@/models/clientContact';

const state = {
  user: {},
  loading: false,
  dialog: false,
  loadingDialog: false,
  item: new ClientContact(),
  honorifics: [],
  existingDirectors: [],
  order: 'name',
  noDataMessage: '',

};

const actions = {
  create({ commit }, clientContact) {
    commit('setLoading', { loading: true });
    api.postData('/api/clients/contacts', clientContact)
      .then((res) => {
        const contactSaved = res.data;
        commit('setItem', contactSaved);
        commit('setLoading', { loading: false });
        router.push({ name: 'PageClientContactList' });
        commit('notice/setNotice', {
          text: 'Contact créé.',
          color: 'success',
        }, { root: true });
      }).catch(() => {
        commit('notice/setNotice', {
          text: `${api.errorMessage}`,
          color: 'error',
        }, { root: true });
        commit('setLoading', { loading: false });
      });
  },
  update({ commit }, clientContact) {
    commit('setLoading', { loading: true });
    api.putData(clientContact['@id'], clientContact)
      .then((res) => {
        commit('setItem', res.data);
        commit('setLoading', { loading: false });
        router.push({ name: 'PageClientContactShow', params: { hash: res.data.id } });
        commit('notice/setNotice', {
          text: 'Contact mis à jour.',
          color: 'success',
        }, { root: true });
      }).catch(() => {
        commit('notice/setNotice', {
          text: `${api.errorMessage}`,
          color: 'error',
        }, { root: true });
        commit('setLoading', { loading: false });
      });
  },
  get({ dispatch, commit }, hash) {
    commit('setLoading', { loading: true });
    api.getData(`/api/clients/contacts/${hash}`, { invitationState: true }).then((res) => {
      const user = res.data;
      commit('setItem', user);
      commit('setLoading', { loading: false });
      commit('setLoadingDialog', { loading: false });
    }).catch((err) => {
      api.redirectOnNotFound(err.status);
      dispatch('commitNotice', ' Impossible d\'afficher le contact');
      commit('setLoading', { loading: false });
      commit('setLoadingDialog', { loading: false });
    });
  },
  delete({ dispatch, commit }, hash) {
    commit('setLoading', { loading: true });
    api.deleteData(`/api/clients/contacts/${hash}`).then(() => {
      commit('setLoading', { loading: false });
      router.push({ name: 'PageClientContactList' });
      commit('notice/setNotice', {
        text: 'Contact supprimé.',
        color: 'success',
      }, { root: true });
    }).catch(() => {
      dispatch('commitNotice', ' Impossible de supprimer le contact');
      commit('setLoading', { loading: false });
    });
  },
  sendInvitation({ commit, dispatch }, invitation) {
    commit('setLoading', { loading: true });
    api.postData('/api/directors/invitation', invitation)
      .then((res) => {
        if (res.data && res.data['hydra:member']) {
          commit('setExistingDirector', res.data['hydra:member']);
          commit('dialogSwich', true);
        } else {
          commit('dialogSwich', false);
          commit('notice/setNotice', {
            text: 'Une nouvelle invitation a été envoyée',
            color: 'success',
          }, { root: true });
        }
        const id = invitation.contact;
        dispatch('get', id.replace(/\/api\/clients\/contacts\//g, ''));
      }).catch(() => {
        dispatch('commitNotice', 'Impossible d\'envoyer une nouvelle invitation');
        commit('setLoading', { loading: false });
        commit('setLoadingDialog', { loading: false });
      });
  },

  getHonorifics({ commit }) {
    api.getData('/api/contacts/honorifics')
      .then((res) => {
        commit('setHonorifics', res.data['hydra:member']);
      });
  },
  commitNotice({ commit }, error) {
    commit('notice/setNotice', {
      text: `${error}`,
      color: 'error',
    }, { root: true });
  },
};

const mutations = {
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  setLoadingDialog(state, { loading }) {
    state.loadingDialog = loading;
  },
  setItem(state, clientContact) {
    state.item = clientContact;
  },
  resetItem(state) {
    state.item = new ClientContact();
  },
  setNoDataMessage(state, message) {
    state.noDataMessage = message;
  },
  dialogSwich(state, dialog) {
    state.dialog = dialog;
  },
  setExistingDirector(state, directorsList) {
    state.existingDirectors = directorsList;
  },
  setHonorifics(state, honorifics) {
    state.honorifics = honorifics;
  },
  unsetExistingDirector(state) {
    state.existingDirectors = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
