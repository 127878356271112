export default class Mandate {
  constructor() {
    this.id = 0;
    this.createdAt = '';
    this.updatedAt = '';
    this.signedAt = '';
    this.returnedAt = '';
    this.customer = '';
    this.office = '';
    this.salesMan = '';
    this.customerId = '';
    this.isActivate = false;
    this.sendMode = '';
    this.isActivateLre = false;
    this.isWorkFlowReady = false;
    this.uploadedFiles = [];
    this.returnPaper = '0';
  }
}
