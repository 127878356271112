<template>
    <div>
        <v-layout row wrap align-center class="content-header-2col">
            <v-flex xs6 class="content-header-2col-title">
                <base-page-title v-bind:page-title="pageTitle"></base-page-title>
            </v-flex>
            <v-dialog v-model="dialog" fullscreen hide-overlay
                      transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title>IMPORT DES RIBS SDC </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-divider></v-divider>
                  <v-container
                      fluid
                  >
                    <v-layout row wrap>
                      <v-flex xs4>
                        <v-card>
                          <v-card-title primary-title>
                            <div>
                              <h3 class="headline mb-0">Choix du délimiteur de votre CSV</h3>
                            </div>
                          </v-card-title>

                          <v-card-actions>
                            <v-radio-group v-model="csvDelimiter" :mandatory="true">
                              <v-radio label="Virgule ( , )" value=","></v-radio>
                              <v-radio label="Point virgule ( ; )" value=";"></v-radio>
                            </v-radio-group>
                          </v-card-actions>
                        </v-card>
                        <v-card v-if="csvHeaderToJson.length>0">
                          <v-card-title primary-title>
                            <div>
                              <h3 class="headline mb-0">Configuration des colonnes à extraire</h3>
                            </div>
                          </v-card-title>

                          <v-card-actions>
                            <v-select
                                :items="csvHeaderToJson"
                                label="N°Imm"
                                v-model="csvMapping.buildingNumber"
                            ></v-select>
                            <v-select
                                :items="csvHeaderToJson"
                                label="Mandat"
                                v-model="csvMapping.rum"
                            ></v-select>
                            <v-select
                                :items="csvHeaderToJson"
                                label="IBAN"
                                v-model="csvMapping.iban"
                            ></v-select>
                            <v-select
                                :items="csvHeaderToJson"
                                label="BIC"
                                v-model="csvMapping.businessIdentifierCode"
                            ></v-select>
                            <v-select
                                :items="csvHeaderToJson"
                                label="Domiciliation"
                                v-model="csvMapping.bankDomiciliation"
                            ></v-select>
                            <v-select
                                :items="csvHeaderToJson"
                                label="Nom du syndic"
                                v-model="csvMapping.sdcName"
                            ></v-select>
                          </v-card-actions>
                        </v-card>

                        <v-card v-if="csvConvertedToJsonCount > 1">
                          <v-card-title primary-title>
                            <div>
                              <h3 class="headline mb-0">
                                {{ csvConvertedToJsonCount }} RIBS dans le csv
                              </h3>
                            </div>
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1"
                                   :disabled="disabledImportBtn"
                                   flat @click="importSdcRib()">
                              Importer
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-flex>
                      <v-flex xs6>
                        <v-data-table
                            :headers="csvHeaders"
                            :items="csvConvertedToJson"
                            class="elevation-1"
                            :loading="isCsvDataLoading"
                        >
                          <v-progress-linear v-slot:progress color="blue" indeterminate>
                          </v-progress-linear>
                          <template slot="items" slot-scope="props">
                            <td class="text-xs-left">{{ props.item.buildingNumber }}</td>
                            <td class="text-xs-left">{{ props.item.rum }}</td>
                            <td class="text-xs-left">{{ props.item.iban }}</td>
                            <td class="text-xs-left">{{ props.item.businessIdentifierCode }}</td>
                            <td class="text-xs-left">{{ props.item.bankDomiciliation }}</td>
                            <td class="text-xs-left">{{ props.item.sdcName }}</td>
                            <td class="layout">

                              <div v-if="typeof (props.item.isLoading) === 'boolean'
                               && props.item.isLoading === true">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                              </div>
                              <div v-else-if="typeof (props.item.id) === 'number'">
                                <v-icon
                                    small
                                    color="green darken-2"
                                >
                                  check_circle
                                </v-icon>
                              </div>
                              <div v-else>
                                <v-icon
                                    small
                                    @click="deleteBankingAccountItem(props.item)"
                                >
                                  delete
                                </v-icon>
                              </div>

                            </td>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
            </v-dialog>
        </v-layout>
        <div class="table-filters">
        </div>
        <v-layout row wrap>
          <v-flex id="container-send-sdc" >
            <v-btn @click="exportCsv()" color="blue-grey" class="white--text ma-2">
              Export CSV pour Sage
              <v-icon right dark>cloud_download</v-icon>
            </v-btn>
            <file-upload
                name="base-customer-item-banking-account-list-upload-csv"
                :extensions="extensionsBankingAccount"
                :accept="accept"
                :directory="directoryBankingAccount"
                :maximum="maxFilesBankingAccount"
                :multiple=false
                :size="maxFileSizeBankingAccount"
                v-model="filesBankingAccount"
                :drop=true
                :drop-directory=true
                @input-filter="inputFilterBankingAccount"
                @input="uploadSdcBankingAccount"
                ref="uploadedCsv"
                class="ma-2"
            >
              <v-btn color="blue-grey" class="white--text">Import CSV
                <v-icon right dark>cloud_upload</v-icon>
              </v-btn>
            </file-upload>
            <v-flex v-for=" error in errorsBankingAccount"
                    :key="error.id" d-flex xs12 sm12 md12>
              <v-alert :value="true"
                       color="error"
                       icon="warning" dismissible outline>{{ error }}
              </v-alert>
            </v-flex>
            <v-btn
                v-if="countSelectedRibToSend > 0"
                color="info"
                @click.stop="openDialogRib"
                :disabled="loading"
                class="ma-2"
            >
              Générer le mandat pour les Rib sélectionnés
            </v-btn>
            <v-btn
                v-if="countSelectedRibToSend <= 0"
                color="primary"
                @click.stop="openDialogAllRib"
                :disabled="loading"
                class="ma-2"
            >
              Générer le mandat pour tous les Rib non signés
            </v-btn>
            <v-dialog
            v-model="dialogRib"
            max-width="500"
            >
            <v-card>
              <v-card-text>
                <p>{{getTilteSelectedRib}}</p>
              </v-card-text>

              <v-card-actions>
                <v-row align="center">
                  <v-col xs6>
                    <v-btn
                        color="green"
                        text
                        @click="generateSelectedRib('pdf')"
                    >
                      Un PDF multi pages
                    </v-btn>
                  </v-col>
                  <v-col xs6>
                    <v-btn
                      color="green"
                      text
                      @click="generateSelectedRib('zip')"
                    >
                      ZIP (contenant les mandats unitairement)
                    </v-btn>
                  </v-col>
                  <v-col xs6>
                    <v-btn
                        color="red white-text"
                        text
                        class="white--text"
                        @click="cancelDialogRib"
                    >
                      Annuler
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog
                v-model="dialogAllRib"
                max-width="500"
            >
              <v-card>
                <v-card-text>
                  <p>Veuillez choisir le format souhaité</p>
                </v-card-text>

                <v-card-actions>
                  <v-row align="center">
                    <v-col xs6>
                      <v-btn
                          color="green"
                          text
                          @click="generateAllRib('pdf')"
                      >
                        Un PDF multi pages
                      </v-btn>
                    </v-col>
                    <v-col xs6>
                      <v-btn
                          color="green"
                          text
                          @click="generateAllRib('zip')"
                      >
                        ZIP (contenant les mandats unitairement)
                      </v-btn>
                    </v-col>
                    <v-col xs6>
                      <v-btn
                          color="red white-text"
                          text
                          class="white--text"
                          @click="cancelDialogAllRib"
                      >
                        Annuler
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <file-upload
                name="base-customer-item-banking-account-signedSepa-upload-pdf"
                :extensions="extensionsBankingAccountSignedSepa"
                :accept="acceptSignedSepa"
                :directory="directoryBankingAccountSignedSepa"
                :maximum="maxFilesBankingAccountSignedSepa"
                :multiple=false
                :size="maxFileSizeBankingAccountSignedSepa"
                v-model="filesBankingAccountSignedSepa"
                :drop=true
                :drop-directory=true
                @input-filter="inputFilterBankingAccountSignedSepa"
                @input="uploadSdcBankingAccountSignedSepa"
                ref="uploadedPdf"
            >
              <v-btn color="blue-grey" class="white--text ma-2">Import Mandats Signés
                <v-icon right dark>cloud_upload</v-icon>
              </v-btn>
            </file-upload>
            <v-flex v-for=" error in errorsBankingAccountSignedSepa"
                    :key="error.id" d-flex xs12 sm12 md12>
              <v-alert :value="true"
                       color="error"
                       icon="warning" dismissible outline>{{ error }}
              </v-alert>
            </v-flex>
            <BaseGenerateMandatSdc :loading="loading"/>
          </v-flex>
            <v-flex xs12>
                <v-card>
                    <v-data-table
                            :headers="headers"
                            :loading="loading"
                            :items="items"
                            :options.sync="pagination"
                            :items-per-page="15"
                            :no-data-text="noDataMessage"
                            hide-default-footer
                    >
                      <template v-if="!loading" v-slot:body="{items}">
                        <tbody>
                        <tr v-for="(item, i) in items" v-bind:key="i">
                          <td v-for="(field, indexfield) in headers" v-bind:key="indexfield">
                            <div class="action-item">
                              <v-btn
                                icon
                                @click="showItem(item.id)"
                                v-if="field.value === '#'"
                            >
                              <v-icon
                                  color="secondary"
                              >
                                visibility
                              </v-icon>
                            </v-btn>
                              <v-checkbox
                                  v-if="field.value === '#' && item.rumPath === '' || item.rumPath === null"
                                  v-model="selectedForSepa[item.id]"
                              />
                            </div>
                            <div>
                              <p v-if="field.value === 'uploadedAt'">
                                {{ item[field.value] | cleanDateTime }}
                              </p>
                              <p v-if="field.value === 'rumPath'">
                                <v-btn
                                    @click="downloadSdcBankingAccountSignedSepa(item)"
                                    v-if="item.uploadedAt !== null"
                                    :disabled="loading"
                                    icon class="mx-0"
                                    color="light"
                                >
                                  <v-icon>
                                    picture_as_pdf
                                  </v-icon>
                                </v-btn>
                              </p>
                              <p v-if="field.value !== 'rumPath'
                                    && field.value !=='uploadedAt'">
                                {{ item[field.value] }}
                              </p>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                        <v-alert slot="no-results" :value="true" color="error"
                                 icon="warning" v-if="!loading">
                            Votre recherche pour "{{ search }}" ne trouve aucun résultat.
                        </v-alert>
                    </v-data-table>
                    <div class="text-xs-center pt-2 clients-pagination">
                        <v-pagination v-model="page" :total-visible="7" :length="pages">
                        </v-pagination>
                    </div>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { SEARCH_DEBOUNCE_WAIT_TIMER } from '@/utils/constants';
import { csvToJson, fileSizeFormat, getCsvHeaderToJson } from '@/utils/common';

import { DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER } from '../models/customerSdcBankingAccountsHeader';
import BaseGenerateMandatSdc from './BaseGenerateMandatSdc';

const Vue = require('vue');

const DEFAULT_ROWS_PER_PAGE = 15;
const UPLOAD_MAX_FILES = 1;
const UPLOAD_MAX_FILE_SIZE = 1024 * 100; // 20ko
const UPLOAD_MAX_FILES_SIGNED_SEPA = 1;
const UPLOAD_MAX_FILE_SIZE_SIGNED_SEPA = 1024 * 20000;

export default {
  name: 'BaseCustomerItemBankingAccountList',
  components: { BaseGenerateMandatSdc },
  data() {
    return {
      selectedForSepa: {},
      dialogRib: false,
      dialogAllRib: false,
      search: '',
      headers: DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER,
      page: 1,
      pagination: {
        sortBy: [], descending: null, rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
      query: {
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        order: {},
        filters: {},
      },
      lastFilterHash: null,
      title: 'Liste des RIB SDC',
      show: true,
      filesBankingAccount: [],
      errorsBankingAccount: [],
      maxFileSizeBankingAccount: UPLOAD_MAX_FILE_SIZE,
      maxFilesBankingAccount: UPLOAD_MAX_FILES,
      directoryBankingAccount: false,
      extensionsBankingAccount: ['csv'],
      mimeTypesBankingAccount: ['text/csv', 'text/plain', 'application/vnd.ms-excel'],
      csv: null,
      csvConvertedToJson: [],
      csvHeaderToJson: [],
      csvDelimiter: null,
      csvMapping: {
        buildingNumber: null,
        iban: null,
        rum: null,
        bankDomiciliation: null,
        businessIdentifierCode: null,
        sdcName: null,
      },
      filesBankingAccountSignedSepa: [],
      errorsBankingAccountSignedSepa: [],
      maxFileSizeBankingAccountSignedSepa: UPLOAD_MAX_FILE_SIZE_SIGNED_SEPA,
      maxFilesBankingAccountSignedSepa: UPLOAD_MAX_FILES_SIGNED_SEPA,
      directoryBankingAccountSignedSepa: false,
      extensionsBankingAccountSignedSepa: ['pdf'],
      mimeTypesBankingAccountSignedSepa: ['application/pdf'],
      dialog: false,
      notifications: false,
      csvHeaders: [
        { text: 'N° Immeuble', value: 'Libell' },
        { text: 'Ref Mandat', value: 'Mandat' },
        { text: 'IBAN', value: 'IBAN' },
        { text: 'BIC', value: 'BIC' },
        { text: 'Domiciliation', value: 'Domiciliation' },
        { text: 'Nom du sdc', value: 'sdcName' },
        { text: 'Actions', value: 'name', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.customer.loading,
      pages: (state) => state.customer.pagination.pages,
      items: (state) => state.customer.itemSdcBankingAccounts,
      noDataMessage: (state) => state.customer.noDataMessage,
      total: (state) => state.customer.itemSdcBankingAccountsTotal,
      stateQuery: (state) => state.customer.query,
      customer: (state) => state.customer.item,
      basicUser: (state) => state.user.oidcUser,
    }),
    getTilteSelectedRib() {
      const countSelectedRib = Object.keys(this.selectedForSepa)
        .map((key) => this.selectedForSepa[key])
        .filter((item) => item === true).length;
      return `Confirmez-vous la génération de ${countSelectedRib} mandats ?`;
    },
    pageTitle() {
      return this.loading ? `${this.title}` : `${this.title} (${this.total})`;
    },
    accept() {
      return this.concatWithColon();
    },
    acceptSignedSepa() {
      return this.concatWithColonSignedSepa();
    },
    csvConvertedToJsonCount() {
      return this.csvConvertedToJson.length;
    },
    isMappingFull() {
      return this.csvMapping.buildingNumber !== null
          && this.csvMapping.iban !== null && this.csvMapping.rum !== null
          && this.csvMapping.bankDomiciliation !== null
          && this.csvMapping.businessIdentifierCode !== null
          && this.csvMapping.sdcName !== null;
    },
    disabledImportBtn() {
      const isloadingElement = this.csvConvertedToJson.filter((element) => typeof (element.isLoading) === 'boolean'
          && element.isLoading === true);
      const maybeImportedElement = this.csvConvertedToJson.filter((element) => typeof (element.id) !== 'number');

      return isloadingElement.length > 0 || maybeImportedElement.length === 0;
    },
    isCsvDataLoading() {
      const isloadingElement = this.csvConvertedToJson.filter((element) => typeof (element.isLoading) === 'boolean'
          && element.isLoading === true);
      return isloadingElement.length > 0;
    },
    countSelectedRibToSend() {
      const array = Object.values(this.selectedForSepa);
      const count = array.filter((value) => value === true).length;
      return count;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    page: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    search: {
      handler() {
        this.query.string = this.search;
        this.delaySearch();
      },
      deep: true,
    },
    advancedFilters: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    stateFilter: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    classificationFilter: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    csvDelimiter: {
      handler() {
        this.csvHeaderToJson = getCsvHeaderToJson(this.csv, this.csvDelimiter);
      },
      deep: true,
    },
    csvMapping: {
      handler() {
        if (this.isMappingFull) {
          this.csvConvertedToJson = csvToJson(this.csv, this.csvMapping, this.csvDelimiter);
          this.csvConvertedToJson.forEach((element) => this.addUniqIdentifierIfExist(element));
        }
      },
      deep: true,
    },
    dialog: {
      handler() {
        if (this.dialog === false) {
          this.csvConvertedToJson = [];
          this.csvDelimiter = null;
          this.csvMapping = {
            buildingNumber: null,
            iban: null,
            rum: null,
            bankDomiciliation: null,
            businessIdentifierCode: null,
            sdcName: null,
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'customer/getCustomerSdcBankingAccounts', // -> this['order/getOrders']()
      'customer/addNewCustomerSdcBankingAccount',
      'customer/checkIfBanckingAccountExist',
      'customer/exportCustomerSdcBankingAccounts',
      'customer/generateAllSepa',
      'customer/generateSelectedSepa',
      'customer/uploadCustomerSdcBankingAccountSignedSepa',
      'sdcBankingAccount/downloadSdcBankingAccountSignedSepa',
    ]),
    buildQuery() {
      this.query.page = this.page;
      this.query.sortBy = [];
      this.query.exists = { deletedAt: false };
      if (this.pagination.sortBy) {
        this.query.order[this.pagination.sortBy] = this.pagination.descending ? 'asc' : 'desc';
      }
    },
    delaySearch: debounce(function search() {
      this.buildQuery();
      this['customer/getCustomerSdcBankingAccounts'](this.query);
    }, SEARCH_DEBOUNCE_WAIT_TIMER),
    showItem(item) {
      this.$router.push({
        name: 'PageMandateSdcDetail',
        params: { id: item },
      });
    },
    updateFiltersQuery(filters) {
      this.query.filters = filters;
      this.delaySearch();
    },
    uploadSdcBankingAccount() {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.csv = e.target.result;
        this.dialog = true;
      };
      reader.readAsText(this.filesBankingAccount[0].file, 'ISO-8859-1');
      this.empty();
    },
    uploadSdcBankingAccountSignedSepa() {
      this['customer/uploadCustomerSdcBankingAccountSignedSepa']({ customer: this.customer, filesBankingAccountSignedSepa: this.filesBankingAccountSignedSepa, basicUser: this.basicUser.profile });
      this.empty();
    },
    downloadSdcBankingAccountSignedSepa(sdcBankingAccount) {
      this['sdcBankingAccount/downloadSdcBankingAccountSignedSepa']({ customer: this.customer, sdcBankingAccount });
      this.empty();
    },
    deleteSelectedRib(keySelected) {
      Vue.default.delete(this.selectedForSepa, keySelected);
    },
    empty() {
      this.filesBankingAccount = [];
    },
    openDialogRib() {
      this.dialogRib = !this.dialogRib;
    },
    openDialogAllRib() {
      this.dialogAllRib = !this.dialogAllRib;
    },
    cancelDialogRib() {
      this.dialogRib = false;
      this.selectedForSepa = {};
    },
    cancelDialogAllRib() {
      this.dialogAllRib = false;
    },
    inputFilterBankingAccount(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        if (UPLOAD_MAX_FILES !== 0 && this.filesBankingAccount.length === UPLOAD_MAX_FILES) {
          this.errors.push(`Le nombre de fichiers est limité à ${UPLOAD_MAX_FILES}`);
          return prevent();
        }

        if (this.mimeTypesBankingAccount.length
            && !this.mimeTypesBankingAccount.includes(newFile.type)) {
          this.errorsBankingAccount.push(`Le type du fichier ${newFile.name} n'est pas accepté`);
          return prevent();
        }

        if (newFile.size > UPLOAD_MAX_FILE_SIZE) {
          this.errorsBankingAccount.push(`La taille des fichiers est limité à ${fileSizeFormat(UPLOAD_MAX_FILE_SIZE)}`);
          return prevent();
        }
      }
      return true;
    },
    concatWithColon() {
      return this.mimeTypesBankingAccount.length
        ? this.mimeTypesBankingAccount.reduce((accumulator, currentValue) => `${accumulator},${currentValue}`)
        : null;
    },
    inputFilterBankingAccountSignedSepa(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        if (
          UPLOAD_MAX_FILES_SIGNED_SEPA !== 0
            && this.filesBankingAccountSignedSepa.length === UPLOAD_MAX_FILES_SIGNED_SEPA
        ) {
          this.errors.push(`Le nombre de fichiers est limité à ${UPLOAD_MAX_FILES_SIGNED_SEPA}`);
          return prevent();
        }

        if (this.mimeTypesBankingAccountSignedSepa.length
            && !this.mimeTypesBankingAccountSignedSepa.includes(newFile.type)) {
          this.errorsBankingAccountSignedSepa.push(`Le type du fichier ${newFile.name} n'est pas accepté`);
          return prevent();
        }

        if (newFile.size > UPLOAD_MAX_FILE_SIZE_SIGNED_SEPA) {
          this.errorsBankingAccountSignedSepa.push(`La taille des fichiers est limité à ${fileSizeFormat(UPLOAD_MAX_FILE_SIZE_SIGNED_SEPA)}`);
          return prevent();
        }
      }

      return true;
    },
    concatWithColonSignedSepa() {
      return this.mimeTypesBankingAccountSignedSepa.length
        ? this.mimeTypesBankingAccountSignedSepa.reduce((accumulator, currentValue) => `${accumulator},${currentValue}`)
        : null;
    },
    deleteBankingAccountItem(item) {
      const index = this.csvConvertedToJson.indexOf(item);

      // eslint-disable-next-line no-alert,no-restricted-globals
      if (confirm('Are you sure you want to delete this item?')) {
        this.csvConvertedToJson.splice(index, 1);
      }
    },
    sdcBankingAccountExist(item) {
      return typeof (item.id) === 'number';
    },
    addUniqIdentifierIfExist(item) {
      const accountExist = (element) => element.buildingNumber === item.buildingNumber
          && element.iban === item.iban
          && element.rum === item.rum;

      const itemIndex = this.csvConvertedToJson.findIndex(accountExist);
      const editedItem = { ...item };
      editedItem.isLoading = true;
      this.csvConvertedToJson.splice(itemIndex, 1, editedItem);

      const query = {
        buildingNumber: item.buildingNumber,
        iban: item.iban,
        exists: { deletedAt: false },
      };

      this['customer/checkIfBanckingAccountExist']({ customer: this.customer, query })
        .then((res) => {
          const resItemIndex = res['hydra:member'].findIndex(accountExist);
          if (resItemIndex !== -1) {
            this.csvConvertedToJson.splice(itemIndex, 1, res['hydra:member'][resItemIndex]);
          } else {
            this.csvConvertedToJson.splice(itemIndex, 1, item);
          }
        }).catch(() => {
          this.csvConvertedToJson.splice(itemIndex, 1, item);
        });
    },
    importSdcRib() {
      this.csvConvertedToJson.forEach((element) => {
        if (typeof (element.id) !== 'number') {
          const accountExist = (item) => element.buildingNumber === item.buildingNumber
              && element.iban === item.iban
              && element.rum === item.rum;
          const itemIndex = this.csvConvertedToJson.findIndex(accountExist);
          const editedItem = { ...element };
          editedItem.isLoading = true;
          this.csvConvertedToJson.splice(itemIndex, 1, editedItem);
          const newSdcBanking = { ...element, customer: this.customer['@id'] };
          this['customer/addNewCustomerSdcBankingAccount']({ newSdcBanking })
            .then((res) => {
              this.csvConvertedToJson.splice(itemIndex, 1, res);
            })
            .catch(() => {
              this.csvConvertedToJson.splice(itemIndex, 1, element);
            });
        }
      });
    },
    exportCsv() {
      this['customer/exportCustomerSdcBankingAccounts'](this.customer);
    },
    generateAllRib(format) {
      this['customer/generateAllSepa']({ customer: this.customer, format });
    },
    generateSelectedRib(format) {
      this.dialogRib = false;
      this['customer/generateSelectedSepa']({ customer: this.customer, selectedForSepa: this.selectedForSepa, format });
    },
  },
  created() {
    this.delaySearch();
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #container-send-sdc{
      display: flex;
      justify-content: start;
      margin-right: 50px;
    }
    .table-filters {
        background-color: #37474f;
        border-radius: 10px;
        margin-top: 12px;
        margin-bottom: 24px;
        padding: 0 20px;
        color: #FFFF;
    }

    .ticket-list {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .clients-pagination .v-pagination{
        margin-bottom: 20px;
    }
    .theme--light.v-sheet {
        padding: 10px;
    }
    .v-card__title--primary {
        padding-bottom: 5px;
    }
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }
    @media (max-width: 959px){
        .clients-pagination{
            overflow-y: hidden;
            padding-left: 10px;
            padding-right: 10px;
        }
        .clients-pagination .v-pagination{
            overflow-y: scroll;
        }
    }

    .action-item{
      display: flex;
      align-items: center;
      justify-content: start;
    }
</style>
