<template>
  <v-app id="app">
      <v-progress-circular
          v-if="loadingNav"
          :size="50"
          indeterminate color="primary"
      />
    <div v-if="!loadingNav">
      <v-navigation-drawer
          v-model="drawer"
          class="secondary nav-left"
          clipped
          left
          app
          dark
          v-if="login && isLeftNavigationAllowed()"
      >
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <base-menu v-bind:items="itemMenu"/>
        </div>
      </v-navigation-drawer>
      <v-app-bar v-if="login" clipped-left color="white" class="nav-toolbar-top">
        <div class="col-left">
          <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
              :class="showMenuClass"
          ></v-app-bar-nav-icon>
          <v-toolbar-title>
            <v-btn :to="links.dashboard" text class="btn-nohover">
              <img class="logo" src="../src/assets/logotype-Athome-2021-RVB.jpg">
            </v-btn>
          </v-toolbar-title>
        </div>
        <base-menu-top
            v-for="(item, index) in this.baseMenuList"
            :key="index"
            :path-menu="item.path"
            :label-menu="item.label"
        />
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                icon
                color="primary"
                class="ma-2"
                v-bind="attrs"
                v-on="on"
            >
              mdi-account
            </v-icon>
          </template>
          <span>{{user.profile.given_name}} {{user.profile.family_name}}</span>
        </v-tooltip>
        <v-btn @click="logout" icon class="ma-2">
          <v-icon dark>exit_to_app</v-icon>
        </v-btn>

      </v-app-bar>      <v-main>
      <v-container fluid fill-height grid-list-xl>
        <v-layout
            justify-center
        >
          <v-flex xs12 text-xs-center>
            <v-progress-circular v-if="!login"
                                 :size="300"
                                 :width="7" indeterminate
                                 color="purple"></v-progress-circular>
            <router-view/>
          </v-flex>
        </v-layout>
        <v-snackbar
            v-model="notice.snackbar"
            :color="notice.color"
            :timeout="notice.timeout"
            right
        >
          {{ notice.text }}
          <v-btn
              dark
              text
              @click="closeNotice()"
          >
            Close
          </v-btn>
        </v-snackbar>
      </v-container>
    </v-main>
      <v-footer height="auto" color="transparent">
        <v-container fluid>
          <v-layout row wrap align-center>
            <v-flex d-flex class="justify-center">
              <div>&copy; Hercule By Athome {{ new Date().getFullYear() }}</div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AuthenticationGuard from '@/utils/AuthGuard';

export default {
  name: 'App',
  data: () => ({
    drawer: null,
    key: '',
    links: {
      dashboard: '/',
    },
    menuSupervision: null,
    menuDocuments: null,
    labelMenuList: {
      clients: 'Clients',
      Finance: 'Finance',
      Recouvrement: 'Recouvrement',
      Mandate: 'Mandat',
      Commandes: 'orders',
    },
    showMenuClass: '',
  }),
  props: {
    source: String,
  },
  computed: {
    ...mapState({
      notice: (state) => state.notice,
      user: (state) => state.user.oidcUser,
      itemMenuList: (state) => state.user.navigation.itemMenuList,
      baseMenuList: (state) => state.user.navigation.baseMenuList,
      loadingNav: (state) => state.user.loading,
    }),
    itemMenu() {
      return this.itemMenuList[this.key];
    },
    labelMenu() {
      return this.labelMenuList[this.key];
    },
    login() {
      if (!AuthenticationGuard.isLoggedIn()) {
        AuthenticationGuard.login();
      }
      return AuthenticationGuard.isLoggedIn();
    },
  },
  watch: {
    $route() {
      this.getKey();
      this.getClass();
    },
  },
  methods: {
    ...mapActions([
      'user/setNavigation',
    ]),
    closeNotice() {
      this.$store.commit('notice/unsetNotice');
    },
    getKey() {
      this.key = this.$router.currentRoute.path.split('/')[1];
    },
    getClass() {
      this.showMenuClass = `menu-burger ${this.$router.currentRoute.name === 'Home' && this.$vuetify.breakpoint.mdAndUp ? 'hide' : ''}`;
    },
    logout() {
      AuthenticationGuard.logout();
    },
    isGranted(role) {
      return AuthenticationGuard.isGranted(role);
    },
    isLeftNavigationAllowed() {
      const allowedNavigationList = [
        'PageMandateList',
        'PageMandateListLreSimple',
        'PageMandateDetails',
        'PageMandateListLreQ',
        'PageMandateListSimple',
        'PageOrdersList',
        'PageOrdersListInbox',
        'PageOrderListProcessed',
      ];

      return allowedNavigationList.includes(this.$router.currentRoute.name);
    },
  },
  mounted() {
    AuthenticationGuard.getUser().then((user) => {
      if (user !== null) {
        this.$store.commit('user/setOidcUser', user);
        this.getKey();
        this.getClass();
        this['user/setNavigation']();
      }
    });
    if (!this.itemMenuList || !this.baseMenuList) {
      this['user/setNavigation']();
    }
  },
  beforeCreated() {
    this['user/setNavigation']();
  },
};
</script>

<style>
  @font-face {
    font-family: 'UbuntuBold';
    src: url('./assets/fonts/UbuntuBold.eot');
    src: url('./assets/fonts/UbuntuBold.eot') format('embedded-opentype'),
    url('./assets/fonts/UbuntuBold.woff2') format('woff2'),
    url('./assets/fonts/UbuntuBold.woff') format('woff'),
    url('./assets/fonts/UbuntuBold.ttf') format('truetype'),
    url('./assets/fonts/UbuntuBold.svg#UbuntuBold') format('svg');
  }
  @font-face {
    font-family: 'UbuntuBoldItalic';
    src: url('./assets/fonts/UbuntuBoldItalic.eot');
    src: url('./assets/fonts/UbuntuBoldItalic.eot') format('embedded-opentype'),
    url('./assets/fonts/UbuntuBoldItalic.woff2') format('woff2'),
    url('./assets/fonts/UbuntuBoldItalic.woff') format('woff'),
    url('./assets/fonts/UbuntuBoldItalic.ttf') format('truetype'),
    url('./assets/fonts/UbuntuBoldItalic.svg#UbuntuBoldItalic') format('svg');
  }
  @font-face {
    font-family: 'UbuntuItalic';
    src: url('./assets/fonts/UbuntuItalic.eot');
    src: url('./assets/fonts/UbuntuItalic.eot') format('embedded-opentype'),
    url('./assets/fonts/UbuntuItalic.woff2') format('woff2'),
    url('./assets/fonts/UbuntuItalic.woff') format('woff'),
    url('./assets/fonts/UbuntuItalic.ttf') format('truetype'),
    url('./assets/fonts/UbuntuItalic.svg#UbuntuItalic') format('svg');
  }
  @font-face {
    font-family: 'ubuntulight';
    src: url('./assets/fonts/ubuntu-light.eot');
    src: url('./assets/fonts/ubuntu-light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/ubuntu-light.woff2') format('woff2'),
    url('./assets/fonts/ubuntu-light.woff') format('woff'),
    url('./assets/fonts/ubuntu-light.ttf') format('truetype'),
    url('./assets/fonts/ubuntu-light.svg#ubuntulight') format('svg');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'ubuntulight_italic';
    src: url('./assets/fonts/ubuntu-lightitalic.eot');
    src: url('./assets/fonts/ubuntu-lightitalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/ubuntu-lightitalic.woff2') format('woff2'),
    url('./assets/fonts/ubuntu-lightitalic.woff') format('woff'),
    url('./assets/fonts/ubuntu-lightitalic.ttf') format('truetype'),
    url('./assets/fonts/ubuntu-lightitalic.svg#ubuntulight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'ubuntumedium';
    src: url('./assets/fonts/ubuntu-medium.eot');
    src: url('./assets/fonts/ubuntu-medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/ubuntu-medium.woff2') format('woff2'),
    url('./assets/fonts/ubuntu-medium.woff') format('woff'),
    url('./assets/fonts/ubuntu-medium.ttf') format('truetype'),
    url('./assets/fonts/ubuntu-medium.svg#ubuntumedium') format('svg');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'ubuntumedium_italic';
    src: url('./assets/fonts/ubuntu-mediumitalic.eot');
    src: url('./assets/fonts/ubuntu-mediumitalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/ubuntu-mediumitalic.woff2') format('woff2'),
    url('./assets/fonts/ubuntu-mediumitalic.woff') format('woff'),
    url('./assets/fonts/ubuntu-mediumitalic.ttf') format('truetype'),
    url('./assets/fonts/ubuntu-mediumitalic.svg#ubuntumedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'ubunturegular';
    src: url('./assets/fonts/ubuntu-regular.eot');
    src: url('./assets/fonts/ubuntu-regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/ubuntu-regular.woff2') format('woff2'),
    url('./assets/fonts/ubuntu-regular.woff') format('woff'),
    url('./assets/fonts/ubuntu-regular.ttf') format('truetype'),
    url('./assets/fonts/ubuntu-regular.svg#ubunturegular') format('svg');
    font-weight: normal;
    font-style: normal;

  }
  #app {
    font-family: 'ubunturegular', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #666666;
    margin-top: 60px;
    background-color: #eceff1;
  }
  h1{
    font-size: 1.58em;
    color: #37474f;
    font-family: 'ubuntulight';
  }
  .v-toolbar__title{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .nav-toolbar-top{
    position: fixed;
    top: 0px;
    left: 0;
    max-height: 64px;
    z-index: 10;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.30);
  }
  .nav-toolbar-top .col-left{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0;
  }
  .menu-burger{
    margin-left: 0!important;
    margin-right: 0!important;
  }
  .menu-burger.hide{
    visibility: hidden;
    transition: none;
  }
  .logo{
    width: 150px;
  }
  .nav-left{
    padding-top: 48px;
  }
  .nav-left.v-navigation-drawer--is-mobile{
    z-index: 3;
  }
  .v-overlay{
    z-index: 3;
  }
  .v-btn{
    font-family: 'ubuntumedium', sans-serif;
  }
  .nav-toolbar-top .btn-ico{
    min-width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav-toolbar-top .btn-ico .v-btn__content{
    padding: 0;
  }
  .v-btn.primary:hover .v-btn__content{
    background-color: #166090;
  }
  .v-btn.primary:hover:before{
    background-color: #166090;
    opacity: 1;
  }
  .btn-nohover:hover:before, .btn-nohover.v-btn--active:before{
    background-color: transparent;
  }
  .v-card{
    border: 1px solid #c6d5dd;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
    border-radius: 8px;
  }
  .menu-left .v-list__group__header__append-icon{
    display: none;
  }
  .menu-left .v-list__group__items .v-list__item--link{
    padding-left: 20px;
  }
  .menu-left .v-list__group__items .v-list__item__title{
    font-family: 'ubunturegular';
    font-weight: normal;
    text-align: left;
  }
  .menu-left .v-list__group__items .v-list__item--active{
    background: #4e5e66;
  }
  .mobile-menu-add-btn{
    position: absolute;
    bottom: 27px;
    left: 0;
    right: 0;
  }

  /**** HEADER CONTENT ***/
  .content-header-2col{
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-bottom: 20px!important;
  }
  .content-header-2col-title{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .content-header-2col-title .content-header-h1{
    margin-bottom: 0;
  }
  .content-header-2col-btn{
    text-align: right;
  }
  .content-header-2col-btn .v-btn{
    margin: 0;
  }
  @media (max-width: 425px){
    .content-header-2col-btn .v-btn{
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  /*** /HEADER CONTENT ***/

  /*** FORM ***/
  .theme--light
  .input-group.input-group--textarea:not(.input-group--full-width)
  .input-group__input{
    border-color: #9a9a9a;
    border-radius: 10px;
  }
  @media (max-width: 599px){
    .v-select-list .v-list__item__action{
      min-width: unset;
    }
    .v-select-list .v-list__item__content .v-list__item__title{
      font-size: 14px;
    }
  }
  /** /FORM **/

  /**** TABLE ***/
  .theme--light.v-table thead tr:first-child,
  .theme--light.v-table tbody tr:not(:last-child)
  {
    border-bottom-color: #c6d5dd;
  }
  .theme--light.v-datatable .v-datatable__actions{
    border-top-color: #c6d5dd;
  }
  /*** /TABLE ***/

  @media (min-width: 960px){
    .menu-left .v-list .v-list__group .v-list__group__header{
      display: none;
    }
    .menu-left .v-list__group__items{
      display: block!important;
    }
  }
  @media (max-width: 1260px){
    .nav-left{
      margin-top: 64px!important;
      max-height: calc(100% - 64px)!important;
    }
  }
  @media (max-width: 767px){
    h1{
      font-size: 14px;
    }
  }
  @media (max-width: 599px){
    .toolbar .v-toolbar__content>:not(.v-btn):not(.v-menu):first-child:not(:only-child){
      margin-left: 0;
    }
    .col-left .v-toolbar__title{
      margin-left: 0;
    }
    .col-left .v-toolbar__title > .v-btn{
      margin-left: 0;
    }
    .col-left .v-toolbar__title > .v-btn > .v-btn__content{
      padding-left: 0;
    }
    .nav-toolbar-top .btn-ico{
      margin: 0;
    }
    .nav-toolbar-top .btn-ico{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
</style>
