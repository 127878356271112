<template>
  <div>
    <base-invoice-detail/>
  </div>

</template>

<script>
import BaseInvoiceDetail from '@/components/BaseInvoiceDetail';

export default {
  name: 'PageInvoiceShow',
  components: { BaseInvoiceDetail },
  data() {
    return {
      title: '',
    };
  },
};
</script>

<style scoped>

</style>
