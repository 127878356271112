<template>
    <div class="user-detail">
      <v-layout row wrap align-center class="content-header-2col">
        <v-flex xs6 class="content-header-2col-title">
          <base-page-title v-bind:page-title="title"></base-page-title>
        </v-flex>
        <v-flex xs6 class="content-header-2col-btn">
          <v-btn
                  right
                  color="primary"
                  outlined
                  rounded
                  depressed
                  :replace=true
                  @click="addNewCampaign"
                  v-if="hideNewButton"
          >
            <v-icon left light>send</v-icon>
            {{btnCampaignNew}}
          </v-btn>
          <v-btn
                  color="primary"
                  outlined
                  rounded
                  depressed
                  to="/recouvrements/outils/campagne-prelevement"
                  :replace=true
                  v-if="!hideBackButton"
          >
            <v-icon left light>arrow_back</v-icon>
            Retour
          </v-btn>
        </v-flex>
      </v-layout>
        <base-campaign-dialog></base-campaign-dialog>
        <v-divider></v-divider>
          <router-view></router-view>
        <v-divider></v-divider>

    </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'PageCampaignWrapper',
  data() {
    return {
      title: 'Campagne de prélèvements',
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.campaign.items,
    }),
    hideNewButton() {
      return this.$route.name === 'PageCampaignEdit' || this.$route.name === 'PageCampaignNew'
              || this.$route.name === 'PageRecouvrement' || this.$route.name === 'PageCampaignList';
    },
    hideBackButton() {
      return this.$route.name === 'PageCampaignList' || this.$route.name === 'PageRecouvrement';
    },
    btnCampaignNew() {
      if (this.$route.params.id) {
        return 'Edition de la campagne';
      }
      return 'Nouvelle campagne';
    },
  },
  methods: {
    addNewCampaign() {
      this.$store.commit('campaign/dialogSwich', true);
    },
  },
};
</script>

<style scoped>
  .user-detail .content-header-2col{
    margin-bottom: 8px!important;
  }
  #user-name{
    font-size: 14px;
    font-family: 'ubuntumedium', sans-serif;
    color: #333333;
  }
  .user-detail-title{
    padding-top: 35px;
    color: #333333;
    font-family: 'ubuntumedium', sans-serif;
  }
  .user-detail-title span{
    margin-left: 10px;
  }
  .user-detail-infos .item-title{
    margin-bottom: 4px;
    font-size: 11px;
    color: #999999;
  }
  .user-detail-infos .item-title .v-icon{
    margin-left: 2px;
  }
  .user-detail-infos .item{
    font-size: 15px;
    color: #333333;
    font-family: Arial, sans-serif;
  }
  .v-divider{
    margin-left: 24px;
    margin-right: 24px;
  }
  .user-roles-table{
    width: 100%;
    table-layout: fixed;
    border-spacing: 2px;
    margin-top: 8px;
    margin-bottom: 55px;
  }
  .user-roles-table tr{
    background-color: #f3f3f3;
  }
  .user-roles-table tr td{
    width: 50%;
    padding: 12px 15px;
    color: #565656;
  }
  .user-roles-table tr td:first-child{
    text-align: left;
  }
  .user-roles-table tr td:last-child{
    padding-bottom: 0;
  }
  .user-roles-table tr td:last-child >>> .v-input--checkbox{
    margin: 0;
    padding: 0;
  }
  .user-roles-table tr td:last-child >>> .v-input--checkbox .v-input__slot{
    margin-bottom: 0;
    justify-content: center;
  }
  .user-btn-actions-wrap{
    padding: 45px 0 35px;
  }
  .user-btn-actions{
    padding: 0 15px;
  }
  @media (max-width: 599px){
    .user-detail-layout{
      display: block;
    }
    .user-roles-table{
      margin-bottom: 20px;
    }
    .user-btn-actions-wrap{
      display: block;
      padding-bottom: 10px;
    }
    .user-btn-actions{
      margin-bottom: 25px;
    }
  }
</style>
