<template>
  <div>
    <v-progress-circular
        v-if="loading"
        :size="50"
        indeterminate color="primary"
    >
    </v-progress-circular>
    <div class="user-detail" v-if="customer">
      <v-layout row wrap align-center class="content-header-2col">
        <v-flex xs6 class="content-header-2col-title">
          <base-page-title v-bind:page-title="title"></base-page-title>
        </v-flex>
        <v-flex xs6 class="content-header-2col-btn">
          <v-btn
                  color="primary"
                  outlined
                  rounded
                  depressed
                  to="/clients"
                  :replace=true

          >
            <v-icon left light>arrow_back</v-icon>
            Retour
          </v-btn>

        </v-flex>
      </v-layout>
      <template>
        <v-tabs
                class="mb-4"
                v-model="tab"
                color="cyan"
                grow
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab
                  v-for="item in itemsTabs"
                  :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
      <v-tabs-items v-model="tab" class="transparent">
        <v-tab-item>
          <v-layout>
            <v-flex xs10>
              <v-layout>
                <v-flex xs6>
                  <base-detail-customer-info :customer="customer"/>
                </v-flex>
                <v-flex xs6>
                  <base-customer-location :customer="customer"/>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs6>
                  <base-customer-salesman-detail :customer="customer"/>
                </v-flex>
                <v-flex xs6>
                  <base-funds-call-detail :customer="customer"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2>
              <BaseNoteInformation :customer="customer" :user="user"/>
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item>
         <base-customer-item-banking-account-list></base-customer-item-banking-account-list>
        </v-tab-item>
      </v-tabs-items>

    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import BaseFundsCallDetail from '@/components/BaseFundCallDetail';
import BaseCustomerSalesmanDetail from '@/components/BaseCustomerSalesmanDetail';
import BaseCustomerLocation from '@/components/BaseCustomerLocation';
import BaseDetailCustomerInfo from '@/components/BaseDetailCustomerInfo';
import BaseNoteInformation from '@/components/BaseNoteInformation';
import BaseCustomerItemBankingAccountList from '../components/BaseCustomerItemBankingAccountList';

export default {
  name: 'PageSupportClientShow',
  components: {
    BaseCustomerItemBankingAccountList,
    BaseNoteInformation,
    BaseDetailCustomerInfo,
    BaseCustomerLocation,
    BaseCustomerSalesmanDetail,
    BaseFundsCallDetail,
  },
  data() {
    return {
      tab: null,
      itemsTabs: [
        'Fiche client', 'RIB SDC',
      ],
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customer.item,
      loading: (state) => state.customer.loading,
      user: (state) => state.user.oidcUser.profile,
    }),
    title() {
      return `N°${this.customer.id} ${this.customer.name}`;
    },
  },
  created() {
    this.$store.commit('customer/resetItem');
    this.$store.dispatch('customer/getCustomer', this.$route.params.id);
  },
  mounted() {
    if (this.$route.query.isSdc) {
      this.tab = 1;
    }
  },
};
</script>

<style scoped>
  .user-detail .content-header-2col{
    margin-bottom: 8px!important;
  }

  .user-detail-title span{
    margin-left: 10px;
  }
  .user-detail-infos .item-title{
    margin-bottom: 4px;
    font-size: 11px;
    color: #999999;
  }
  .user-detail-infos .item-title .v-icon{
    margin-left: 2px;
  }
  .user-detail-infos .item{
    font-size: 15px;
    color: #333333;
    font-family: Arial, sans-serif;
  }
  .user-roles-table tr{
    background-color: #f3f3f3;
  }
  .user-roles-table tr td{
    width: 50%;
    padding: 12px 15px;
    color: #565656;
  }
  .user-roles-table tr td:first-child{
    text-align: left;
  }
  .user-roles-table tr td:last-child{
    padding-bottom: 0;
  }
  .user-roles-table tr td:last-child >>> .v-input--checkbox{
    margin: 0;
    padding: 0;
  }
  .user-roles-table tr td:last-child >>> .v-input--checkbox .v-input__slot{
    margin-bottom: 0;
    justify-content: center;
  }
  @media (max-width: 599px){
    .user-detail-layout{
      display: block;
    }
    .user-roles-table{
      margin-bottom: 20px;
    }
    .user-btn-actions-wrap{
      display: block;
      padding-bottom: 10px;
    }
    .user-btn-actions{
      margin-bottom: 25px;
    }
  }
</style>
