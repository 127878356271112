import api from '@/utils/api';
import createDownloadFile from '@/utils/generateDownloadPdf';
import { getDefaultPagination, commitPagination } from '@/utils/store-util';
import { wrapActionWithRequest } from '@/utils/cancel-action';

const state = {
  code: null,
  order: 'corporateName',
  query: null,
  total: 0,
  noDataMessage: '',
  loading: false,
  checkInvitationLoading: false,
  items: [],
  states: [],
  item: null,
  itemContacts: [],
  itemSdcBankingAccounts: [],
  itemSdcBankingAccountsTotal: 0,
  pagination: getDefaultPagination(),
  facets: [],
};
const getCustomersAction = ({ commit }, query = { }) => {
  commit('setLoading', { loading: true });
  commit('setQuery', query);
  api.getData('/api/customers/search', query).then((res) => {
    commit('setItems', res.data['hydra:member']);
    commit('setFacets', res.data.facets);
    commit('setTotal', res.data['hydra:totalItems']);
    commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], query);
    commit('setNoDataMessage', api.emptyListMessage);
  }).catch(() => {
    commit('setLoading', { loading: false });
  });
};
const getCustomerSdcBankingAccountsAction = ({ commit, state }, query = { }) => {
  commit('setLoading', { loading: true });
  commit('setQuery', query);
  api.getData(`/api/customer-dtos/${state.item.id}/sdc-banking-accounts`, query).then((res) => {
    commit('setCustomerSdcBankingAccounts', res.data['hydra:member']);
    commit('setItemSdcBankingAccountsTotal', res.data['hydra:totalItems']);
    commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], query);
    commit('setNoDataMessage', api.emptyListMessage);
  }).catch(() => {
    commit('setLoading', { loading: false });
  });
};

const actions = {
  getCustomer({ commit }, id) {
    commit('setLoading', { loading: true });
    api.getData(`/api/customers/${id}`).then((res) => {
      commit('setItem', res.data);
      commit('setLoading', { loading: false });
    });
  },
  // eslint-disable-next-line no-unused-vars
  checkIfBanckingAccountExist({ commit }, payload) {
    return api.getData(`/api/customer-dtos/${payload.customer.id}/sdc-banking-accounts`, payload.query).then((res) => res.data);
  },
  getCustomers: wrapActionWithRequest(getCustomersAction, { autoAbort: true }),
  // eslint-disable-next-line max-len
  getCustomerSdcBankingAccounts: wrapActionWithRequest(getCustomerSdcBankingAccountsAction, { autoAbort: true }),
  getStates({ commit }) {
    api.getData('/api/customers/states')
      .then((res) => {
        commit('setStates', res.data['hydra:member']);
      });
  },
  uploadCustomerLogoFile({ commit }, payload) {
    commit('setLoading', { loading: true });
    const data = new FormData();
    payload.files.forEach((element, index) => {
      data.append(`files[${index}]`, element.file);
    });
    api
      .uploadloadFiles(`/api/customers/${payload.customer.id}/logo`, data)
      .then((res) => {
        commit('setItem', res.data);
        commit('setLoading', { loading: false });
        commit(
          'notice/setNotice',
          {
            text: 'Document(s) ajouté(s)',
            color: 'info  ',
          },
          { root: true },
        );
      })
      .catch((err) => {
        commit(
          'notice/setNotice',
          {
            text: `${err.data['hydra:description']}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  updateCustomerInfo({ commit }, payload) {
    commit('setLoading', { loading: true });
    api.patchData(`api/customers/${payload.id}`, payload.data)
      .then((res) => {
        console.log(res);
        commit('setItem', res.data);
        commit('setLoading', { loading: false });
        commit(
          'notice/setNotice',
          {
            text: 'La mise a jour du client a ete faite',
            color: 'info  ',
          },
          { root: true },
        );
      })
      .catch((err) => {
        commit(
          'notice/setNotice',
          {
            text: `${err.data['hydra:description']}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  uploadCustomerSdcBankingAccountSignedSepa({ commit }, payload) {
    commit('setLoading', { loading: true });
    const data = new FormData();
    payload.filesBankingAccountSignedSepa.forEach((element) => {
      data.append('signedSepa', element.file);
    });
    data.append('userIdentifier', payload.basicUser.email);
    api
      .uploadloadFiles(`/api/customers/${payload.customer.id}/banking/sdc-accounts/upload-signed-sepa`, data)
      .then(() => {
        commit('setLoading', { loading: false });
        commit(
          'notice/setNotice',
          {
            text: 'Document(s) ajouté(s)',
            color: 'info  ',
          },
          { root: true },
        );
      })
      .catch((err) => {
        commit(
          'notice/setNotice',
          {
            text: `${err.data['hydra:description']}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  addNewCustomerSdcBankingAccount({ commit }, payload) {
    return api.postData('/api/sdc-banking-accounts', payload.newSdcBanking)
      .then((res) => {
        commit(
          'notice/setNotice',
          {
            text: 'import RIB SDC',
            color: 'info',
          },
          { root: true },
        );
        return res.data;
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  exportCustomerSdcBankingAccounts({ commit }, customer) {
    commit('setLoading', { loading: true });
    return api.downloadFile(`/api/customers/${customer.id}/banking/sdc-accounts-sage-export`)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `export-${customer.id}-rib_sdc.csv`,
        );
        document.body.appendChild(link);
        link.click();
        commit('setLoading', { loading: false });
        return res.data;
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  generateSelectedSepa({ commit }, { customer, selectedForSepa, format }) {
    commit('setLoading', { loading: true });
    // eslint-disable-next-line max-len
    return api.downloadFilePost(`/api/customers/${customer.id}/banking/sdc-accounts/generate-sdc`, { pendingOnly: true, sdcBankingAccountIdList: Object.keys(selectedForSepa), format })
      .then((res) => {
        createDownloadFile(res, customer, format);
        commit('setLoading', { loading: false });
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  generateAllSepa({ commit }, { customer, format }) {
    commit('setLoading', { loading: true });
    commit(
      'notice/setNotice',
      {
        text: 'Genenaration du mandat pour tous les documents',
        color: 'primary',
      },
      { root: true },
    );
    console.log(format);
    return api.downloadFilePost(`/api/customers/${customer.id}/banking/sdc-accounts/generate-sdc`, { pendingOnly: true, format })
      .then((res) => {
        createDownloadFile(res, customer, format);
        commit('setLoading', { loading: false });
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
};

const mutations = {
  setCustomers(state, customer) {
    state.code = customer.code;
  },
  setItems(state, items) {
    state.items = items;
    state.loading = false;
  },
  setCustomerSdcBankingAccounts(state, items) {
    state.itemSdcBankingAccounts = items;
    state.loading = false;
  },
  setFacets(state, facets) {
    state.facets = facets;
  },
  setTotal(state, total) {
    state.total = total;
  },
  setItemSdcBankingAccountsTotal(state, total) {
    state.itemSdcBankingAccountsTotal = total;
  },
  setStates(state, states) {
    state.states = states;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setNoDataMessage(state, message) {
    state.noDataMessage = message;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  setItem(state, customer) {
    state.item = customer;
    state.itemContacts = customer.contacts;
    state.loading = false;
  },
  setItemContacts(state, contacts) {
    state.itemContacts = contacts;
    state.checkInvitationLoading = false;
    state.loading = false;
  },
  resetItemContacts(state) {
    state.itemContacts = [];
  },
  resetItem(state) {
    state.item = null;
  },
  reset(state) {
    state.items = [];
    state.total = 0;
  },
  unsetCustomer(state) {
    state.code = null;
    state.itemContacts = [];
  },
  setQuery(state, query) {
    state.query = query;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
