<template>
  <div>
    <base-campaign-list title="Liste des campagnes"
                     :campaigns="campaigns" :total="total" :disabled=true :deleted=true >
    </base-campaign-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseCampaignList from '../components/BaseCampaignList';

export default {
  name: 'PageCampaignList',
  components: { BaseCampaignList },
  computed: {
    ...mapState({
      campaigns: (state) => state.campaign.items,
      total: (state) => state.campaign.total,
    }),
  },
  mounted() {

  },

};
</script>
