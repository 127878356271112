import isFunction from 'lodash/isFunction';
import _capitalize from 'lodash/capitalize';

export const isPromise = (val) => val && typeof val.then === 'function';

/**
 * Return
 * @example
 *  getVal('test') returns 'test'
 * @example
 *  getVal(() => 'test') returns 'test'
 * @example
 *  getVal((val) => val, 'test') returns 'test'
 * @example
 *  getVal((...args) => Array.from(args).join(''), 't', 'e', 's', 't') returns 'test'
 * @param {Function|*} val
 * @param  {...any} args
 */
export const getVal = (val, ...args) => (isFunction(val) ? val(...args) : val);

const unitNames = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

export const convertSizeToByte = (src, currentUnit = 'Bytes') => src * (1024 ** unitNames.findIndex((v) => v.toUpperCase() === currentUnit.toUpperCase()));

export const fileSizeFormat = (bytes, capitalize = false) => {
  if (bytes === 0) return '0 Byte';
  const unitIndex = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  const unitName = capitalize ? _capitalize(unitNames[unitIndex]) : unitNames[unitIndex];
  return `${Math.round(bytes / (1024 ** unitIndex))} ${unitName}`;
};

export const csvToJson = (string, headerMapping, separator = ',') => {
  const lines = string.split('\n');

  const result = [];

  const headers = lines[0].split(separator);

  for (let lineIndex = 1; lineIndex < lines.length - 1; lineIndex += 1) {
    const obj = {};
    const currentline = lines[lineIndex].split(separator);

    Object.entries(headerMapping).forEach(([key, value]) => {
      const data = currentline[headers.indexOf(value)];
      obj[key] = typeof data === 'string' ? data.replace(/(\r\n|\n|\r)/gm, '') : data;
    });

    result.push(obj);
  }

  return result; // JavaScript object
};
export const getCsvHeaderToJson = (string, separator = ',') => {
  const lines = string.split('\n');
  return lines[0].split(separator);
};
export default {
  isPromise,
  getVal,
  fileSizeFormat,
  csvToJson,
  getCsvHeaderToJson,
};
