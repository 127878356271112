<template>
  <v-card>
    <v-card-title>
      <h1>Liste des Mandats {{titleMandateType}}</h1>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="search"
          label="Nom du client"
          single-line
          hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <base-mandate-dialog :mandate-type="mandateType"/>
    </v-card-title>
    <v-data-table
        :headers="headersChoice"
        :items="mandateList"
        :search="search"
        :loading="loading"
        hide-default-footer
        :items-per-page="30"
        no-data-text="Aucun résultat"
        :options.sync="pagination"
        no-results-text="Aucun resultat pour cette recherche"
        item-key="name"
        footer-items-per-page-text="Nombre de mandat par page"
        light
    >
      <template v-if="!loading" v-slot:body="{items}">
        <template v-for="(item, i) in items">
          <tr v-if="mandateType==='lre-q'" :key="i" @click="showMandate(item.id)">
            <td>{{ item.customer }}</td>
            <td>{{ item.office }}</td>
            <td>{{ item.salesMan }}</td>
            <td>{{ item.customerId }}</td>
            <td>{{item.isActivate ? 'Mandat ok' : 'suspension envoi'}} </td>
            <td v-if="!item.sendMode || item.sendMode === 'null'"> </td>
            <td v-else>{{ item.sendMode }}</td>
            <td v-if="!item.isActivateLre"> </td>
            <td v-else><v-icon>check</v-icon></td>
            <td v-if="!item.activationLreSimpleAt"> </td>
            <td v-else>{{ item.activationLreSimpleAt | cleanDate}}</td>
            <td v-if="!item.isWorkFlowReady"> </td>
            <td v-else><v-icon>check</v-icon></td>
            <td v-if="!item.signedAt"> </td>
            <td v-else>{{ item.signedAt | cleanDate}}</td>
            <td v-if="!item.returnedAt"> </td>
            <td v-else>{{ item.returnedAt | cleanDate}}</td>
            <td>{{item.comment}}</td>
            <td>{{item.createdAt | cleanDate }}</td>
            <td class="justify-center layout px-0">
              <v-btn icon class="mx-0" @click="showMandate(item.id)">
                <v-icon color="secondary">visibility</v-icon>
              </v-btn>
            </td>
          </tr>
          <!-- lre simple-->
          <tr v-if="mandateType==='lre-simple' && !loading" :key="i" @click="showMandate(item.id)">
            <td>{{ item.customer }}</td>
            <td>{{ item.customerId }}</td>
            <td v-if="item.returnedPaper">
              {{item.returnedPaper === '0' ? 'non' : `${item.returnedPaper} jours`}}
            </td>
            <td v-else>non</td>
            <td v-if="!item.isActivateLre"> </td>
            <td v-else><v-icon>check</v-icon></td>
            <td v-if="!item.isActivate"> </td>
            <td v-else><v-icon>check</v-icon> </td>
            <td v-if="!item.isWorkFlowReady"> </td>
            <td v-else><v-icon>check</v-icon></td>
            <td v-if="!item.askedAt"> </td>
            <td v-else>{{ item.askedAt | cleanDate}}</td>
            <td v-if="!item.activationLreSimpleAt"> </td>
            <td v-else>{{ item.activationLreSimpleAt | cleanDate}}</td>
            <td>{{ item.office }}</td>
            <td>{{ item.salesMan }}</td>
            <td>{{ item.createdAt | cleanDate }}</td>
            <td>{{item.comment}}</td>
            <td class="justify-center layout px-0">
              <v-btn icon class="mx-0" @click="showMandate(item.id)">
                <v-icon color="secondary">visibility</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" @click="showMandate(item.id)">
                <v-icon color="secondary">edit</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </template>
    </v-data-table>
    <div v-if="!loading" class="text-xs-center pt-2 mandate-pagination">
      <v-pagination v-model="page" :total-visible="10" :length="pages">
      </v-pagination>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import debounce from 'lodash/debounce';
import BaseMandateDialog from '@/components/BaseMandateDialog';
import { SEARCH_DEBOUNCE_WAIT_TIMER } from '../utils/constants';

const DEFAULT_ROWS_PER_PAGE = 30;

export default {
  name: 'BaseMandateListLreSimple',
  components: { BaseMandateDialog },
  data() {
    return {
      search: '',
      page: 1,
      pagination: {
        sortBy: ['id'], descending: false, rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
      query: {
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
    };
  },
  props: {
    mandateType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      headers: (state) => state.mandateListSetting.headers,
      mandateList: (state) => state.mandate.items,
      lreSimpleHeader: (state) => state.mandateListSetting.lreSimpleHeader,
      loading: (state) => state.mandate.loading,
      noDataMessage: (state) => state.mandate.noDataMessage,
      total: (state) => state.mandate.total,
      stateQuery: (state) => state.mandate.query,
      pages: (state) => state.mandate.pagination.pages,

    }),
    headersChoice() {
      return (this.mandateType === 'lre-simple' ? this.lreSimpleHeader : this.headers);
    },
    titleMandateType() {
      return (this.mandateType === 'lre-q' ? 'LRE-Q' : this.mandateType);
    },
  },
  methods: {
    ...mapActions([
      'mandate/getMandate',
    ]),
    showMandate(item) {
      this.$router.push({
        name: 'PageMandateDetails',
        params: { id: item },
        query: { mandateType: this.mandateType },
      });
    },
    buildQuery() {
      this.query.page = this.page;
    },
    delaySearch: debounce(function search() {
      this.buildQuery();
      this['mandate/getMandate'](this.query);
    }, SEARCH_DEBOUNCE_WAIT_TIMER),
  },
  filters: {
    cleanDate(date) {
      return moment(String(date)).format('DD/MM/YYYY');
    },
  },
  mounted() {
    this.$store.dispatch('mandate/getMandate', { mandateType: this.mandateType });
  },
  watch: {
    search: {
      handler() {
        this.query.id = this.search;
        this.delaySearch();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    page: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.mandate-pagination .v-pagination{
  margin-bottom: 20px;
}
@media (max-width: 959px){
  .mandate-pagination{
    overflow-y: hidden;
    padding-left: 10px;
    padding-right: 10px;
  }
  .mandate-pagination .v-pagination{
    overflow-y: scroll;
  }
}
</style>
