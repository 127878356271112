<template>
  <div>
    <v-progress-circular
        v-if="loading"
        :size="50"
        indeterminate color="primary"
    >
    </v-progress-circular>
    <BaseCustomerDetailSdcBanking
        v-if="!loading"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseCustomerDetailSdcBanking from '../components/BaseCustomerDetailSdcBanking';

export default {
  name: 'PageMandateSdcDetail',
  methods: {
    ...mapActions([
      'sdcBankingAccount/get',
    ]),
  },
  components: {
    BaseCustomerDetailSdcBanking,
  },
  computed: {
    ...mapState({
      loading: (state) => state.customer.loading,
    }),
  },
  created() {
    this['sdcBankingAccount/get'](this.$route.params.id);
  },
};
</script>

<style scoped>

</style>
