import api from '@/utils/api';
import { wrapActionWithRequest } from '@/utils/cancel-action';

const state = {
  noDataMessage: '',
  loading: false,
  items: [],
  item: null,
};

const getTagsAction = ({ commit }, query = { }) => {
  commit('setLoading', { loading: true });
  commit('setQuery', query);
  api.getData('/api/tags', query).then((res) => {
    commit('setItems', res.data['hydra:member']);
    commit('setNoDataMessage', api.emptyListMessage);
  }).catch(() => {
    commit('setLoading', { loading: false });
  });
};

const actions = {
  getTags: wrapActionWithRequest(getTagsAction, { autoAbort: true }),
};

const mutations = {
  setItems(state, tags) {
    state.items = tags;
    state.loading = false;
  },
  setNoDataMessage(state, message) {
    state.noDataMessage = message;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    state.items = [];
    state.total = 0;
  },
  setQuery(state, query) {
    state.query = query;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
