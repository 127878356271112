<template>
  <div>
    <v-btn
        color="primary ma-2"
        @click.stop="isCreated = true"
    >
      Ajouter un mandat
    </v-btn>

    <v-dialog
        v-model="isCreated"
        max-width="420"
    >
      <v-card>
        <v-card-title>
          Création d'un mandat
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                  cols="6"
                  v-for="(indexfield, index) in headersNewSdc"
                  v-bind:key="index"
                  class="pa-5"
              >
                <v-text-field
                    :label="indexfield.text"
                    v-model="newSdcMandate[indexfield.value]"
                    v-if="indexfield.value !== 'rum'"
                    required
                />
              </v-col>
            </v-row>
            <div class="d-flex justify-space-around">
              <v-btn
                  color="red"
                  text
                  @click="toggleDialog"
              >
                Annuler
              </v-btn>

              <v-btn
                  color="blue"
                  text
                  @click.prevent="addSdcMandate"
              >
                Ajouter
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER_EDIT } from '../models/customerSdcBankingAccountsHeader';

export default {
  name: 'BaseGenerateMandatSdc',
  props: {
    loading: {},
  },
  data() {
    return {
      isCreated: false,
      headersNewSdc: DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER_EDIT,
      newSdcMandate: {
        buildingNumber: null,
        iban: null,
        businessIdentifierCode: null,
        bankDomiciliation: null,
        rum: null,
        sdcName: null,
      },
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customer,
    }),
  },
  methods: {
    ...mapActions([
      'sdcBankingAccount/create',
    ]),
    addSdcMandate() {
      const customerId = this.customer.item.id;
      const data = this.newSdcMandate;
      this['sdcBankingAccount/create']({ customerId, data });
      this.isCreated = false;
    },
    toggleDialog() {
      this.isCreated = !this.isCreated;
    },
  },
};
</script>
<style scoped>

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

@media (max-width: 959px) {
}
</style>
