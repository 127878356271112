<template>
  <div>
    <v-dialog v-model="dialog"

              hide-overlay
              transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="blue-grey darken-3">
          <v-btn icon dark @click.native="close">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Nous avons trouvé {{count}} {{labelDirigeant}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click.native="close">OK</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader> Vous devez choisir parmi ces propositions</v-subheader>
        </v-list>
        <v-divider></v-divider>
        <v-container fluid grid-list-md>
          <v-data-iterator
                  :items="existingDirectors"
                  :rows-per-page-items="rowsPerPageItems"
                  :pagination.sync="pagination"
                  content-tag="v-layout"
                  row
                  wrap
          >
            <v-flex
                    slot="item"
                    slot-scope="props"
                    xs12
                    sm6
                    md4
                    lg3
                    @click="select(props.item)"
            >
              <v-hover>
                <v-card
                        slot-scope="{ hover }"
                        :class="`elevation-${hover ? 12 : 2}`"
                        class="mx-auto"
                        width="344"
                >
                  <v-card-title class="subheading font-weight-bold">
                    #{{ props.item.thirdid }} {{ props.item.firstname }} {{ props.item.name }}
                  </v-card-title>

                  <v-divider></v-divider>

                  <v-list dense>
                    <v-list-tile>
                      <v-list-tile-content>Nom Prénom:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.name }} {{ props.item.firstname }}
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-content>Adresse mail:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.email }}
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-content>Date de naissance:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.birthdate }}
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-content>N° téléphone fixe:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.phone }}
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-content>N° de téléphone mobile:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.mobile }}
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-content>Libellé client:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.company_name }}
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile>
                      <v-list-tile-content>Code Agence:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.agency_identifier }}
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile>
                      <v-list-tile-content>N° SIREN:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.siren }}
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile>
                      <v-list-tile-content>Etablissement:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.office_name }}
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile>
                      <v-list-tile-content>N° SIRET:</v-list-tile-content>
                      <v-list-tile-content class="align-end">
                        {{ props.item.siret }}
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-hover>
            </v-flex>
          </v-data-iterator>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
            v-model="dialog2"
            max-width="550"
    >
      <v-card>
        <v-card-title class="headline">Souhaitez vous continuer?</v-card-title>

        <v-card-text>
          Le contact sélectionné sera enregistré comme dirigeant du client {{client.corporateName}}
        </v-card-text>

        <v-card-text v-if="director && message">
          <v-alert
                  :value="true"
                  color="error"
                  icon="warning"
                  outline
          >
            {{message}}
          </v-alert>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
                  color="green darken-1"
                  flat="flat"
                  @click="dialog2 = false"
          >
            Annuler
          </v-btn>

          <v-btn
                  color="green darken-1"
                  flat="flat"
                  @click="continuer"
          >
            Continuer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
            v-model="loadingDialog"
            persistent
            width="300"
    >
      <v-card
              color="primary"
              dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import Director from '@/models/director';

export default {
  name: 'BaseInvitationDialog',
  data: () => ({
    rowsPerPageItems: [4, 8, 12],
    pagination: {
      rowsPerPage: 4,
    },
    dialog2: false,
    invitation: null,
    director: null,
  }),
  computed: {
    ...mapState({
      dialog: (state) => state.clientContact.dialog,
      client: (state) => state.client.item,
      clientContact: (state) => state.clientContact.item,
      existingDirectors: (state) => state.clientContact.existingDirectors,
      loadingDialog: (state) => state.clientContact.loadingDialog,
    }),
    message() {
      return this.director && this.director.email !== this.clientContact.email
        ? "L'email du contact doit être identique à celui du dirigeant sélectionné" : false;
    },
    count() {
      return this.existingDirectors.length;
    },
    labelDirigeant() {
      const string = 'dirigeant';

      if (this.existingDirectors.length > 1) {
        return `${string}s`;
      }

      return string;
    },
  },
  methods: {
    close() {
      this.$store.commit('clientContact/dialogSwich', false);
    },
    select(item) {
      this.director = item;
      this.dialog2 = true;
    },
    continuer() {
      this.invitation = new Director(this.clientContact['@id'], this.director);
      this.dialog2 = false;
      this.$store.commit('clientContact/setLoadingDialog', { loading: true });
      this.$store.dispatch('clientContact/sendInvitation', this.invitation);
    },
  },
};
</script>
