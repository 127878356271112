<template>
  <v-card class="pa-2">
    <v-card-title class="user-detail-title">
      <v-avatar color="primary" size="34">
        <v-icon dark>account_circle</v-icon>
      </v-avatar>
      <span>Informations générales</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="showLocalisation = !showLocalisation">
        <v-icon>{{ isShow }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-slide-y-transition>
      <v-container fluid v-show="showLocalisation">
        <v-layout row v-for="(item, index) in displayCustomerField" :key="index">
          <div class="list-item-customer">
            <v-flex xs6 class="list-item pa-0">
              <p><i>{{item.key}}</i></p>
            </v-flex>
            <v-flex xs6 class="list-item pa-0">
              <p v-if="item.value !== 'salesman'"><b>{{getAllValue(item.value)}}</b></p>
              <p v-else><b>{{getAllValue(item.value) | formatSalesmanName}}</b></p>
            </v-flex>
          </div>
        </v-layout>
        <v-img v-if="logoPath && logoPath.length > 0" :src='logoPath' aspect-ratio="1.7"></v-img>
      </v-container>
    </v-slide-y-transition>
    <file-upload
            name="base-detail-customer-info-upload-logo"
            :extensions="extensions"
            :accept="accept"
            :directory="directory"
            :maximum="maxFiles"
            :multiple=true
            :size="maxFileSize"
            v-model="files"
            :drop=true
            :drop-directory=true
            @input-filter="inputFilter"
            @input="sendLogo"
            ref="upload"
    >
      <v-btn color="blue-grey" class="white--text">Ajouter un logo
        <v-icon right dark>cloud_upload</v-icon>
      </v-btn>
    </file-upload>
    <v-flex v-for=" error in errors" :key="error.id" d-flex xs12 sm12 md12>
      <v-alert :value="true" color="error" icon="warning" dismissible outline>{{ error }}</v-alert>
    </v-flex>
  </v-card>
</template>
<script>
import truncate from 'lodash/truncate';
import { fileSizeFormat } from '@/utils/common';
import fetchValue from '../utils/fetch-value';

const UPLOAD_MAX_FILES = 1;
const UPLOAD_MAX_FILE_SIZE = 1024 * 20; // 20ko

export default {
  name: 'base-detail-customer-info',
  props: {
    customer: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showLocalisation: true,
      files: [],
      errors: [],
      maxFileSize: UPLOAD_MAX_FILE_SIZE,
      maxFiles: UPLOAD_MAX_FILES,
      directory: false,
      extensions: ['png'],
      mimeTypes: ['image/png'],
      displayCustomerField: [
        {
          key: 'Nom',
          value: 'name',
        },
        {
          key: 'Reference Client:',
          value: 'clientReference',
        },
        {
          key: 'Centre:',
          value: 'office.name',
        },
        {
          key: 'Centre de production:',
          value: 'office.name',
        },
      ],
    };
  },
  computed: {
    isShow() {
      return this.showLocalisation ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
    accept() {
      return this.concatWithColon();
    },
    logoPath() {
      if (this.customer.setting.logoPath === '') {
        return null;
      }
      if (this.customer.setting.logoPath === ''
          || typeof this.customer.setting.logoPath === 'undefined'
          || this.customer.setting.logoPath === null) {
        return null;
      }
      return `${process.env.VUE_APP_LOGO_BASE_URL}${this.customer.setting.logoPath}`;
    },
  },
  methods: {
    getAllValue(value) {
      return fetchValue(value, this.customer);
    },
    sendLogo() {
      const payload = { customer: this.customer, files: this.files };
      this.$store.dispatch('customer/uploadCustomerLogoFile', payload);
      this.empty();
    },
    empty() {
      this.files = [];
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        if (UPLOAD_MAX_FILES !== 0 && this.files.length === UPLOAD_MAX_FILES) {
          this.errors.push(`Le nombre de fichiers est limité à ${UPLOAD_MAX_FILES}`);
          return prevent();
        }

        if (this.mimeTypes.length && !this.mimeTypes.includes(newFile.type)) {
          this.errors.push(`Le type du fichier ${newFile.name} n'est pas accepté`);
          return prevent();
        }

        if (newFile.size > UPLOAD_MAX_FILE_SIZE) {
          this.errors.push(`La taille des fichiers est limité à ${fileSizeFormat(UPLOAD_MAX_FILE_SIZE)}`);
          return prevent();
        }
      }

      return true;
    },
    concatWithColon() {
      return this.mimeTypes.length
        ? this.mimeTypes.reduce((accumulator, currentValue) => `${accumulator},${currentValue}`)
        : null;
    },
    truncateName(name) {
      return truncate(name, 10);
    },
  },
};
</script>
<style scoped>
.list-item {
  display: flex;
  justify-content: start;
}

.user-detail-title span {
  margin-left: 10px;
}

.user-roles-table tr {
  background-color: #f3f3f3;
}

.user-roles-table tr td {
  width: 50%;
  padding: 12px 15px;
  color: #565656;
}

.user-roles-table tr td:first-child {
  text-align: left;
}

.user-roles-table tr td:last-child {
  padding-bottom: 0;
}

.list-item-customer{
  display: flex;
  width: 100%;
  align-items: center;
}

@media (max-width: 599px) {
}
</style>
