<template>
  <div>
    <v-layout row wrap align-center class="content-header-2col">
      <v-flex xs6 class="content-header-2col-title">
        <base-page-title v-bind:page-title="pageTitle"></base-page-title>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs3>
        <base-facets v-on:updateFacets="updateFiltersQuery" :facets="facets"/>
      </v-flex>
      <v-flex xs9>
        <v-card>
          <v-progress-circular
              v-if="loading"
              :size="50"
              indeterminate color="primary"
          />
          <v-data-table
              :headers="headers"
              :loading="loading"
              :items="items"
              :server-items-length="total"
              class="order-list"
              :options.sync="pagination"
              :no-data-text="noDataMessage"
              hide-default-footer
              :items-per-page=30
          >
            <template v-if="!loading" v-slot:body="{items}">
              <tbody>
                <tr v-for="(item, i) in items" v-bind:key="i">
                  <td v-for="(field, indexfield) in headers" v-bind:key="indexfield">
                    <p class="text-xs-left" v-if="field.value === 'id'">
                      {{ item._source.number }}
                    </p>
                    <p class="text-xs-left" v-if="field.value === 'customer.name'">
                      {{ item._source.customer.name }}
                    </p>
                    <p class="text-xs-left" v-if="field.value === 'office.name'">
                      {{ item._source.office.name }}
                    </p>
                    <p class="text-xs-left" v-if="field.value === 'issuedAt'">
                      {{ item._source.issuedAt | cleanDate }}
                    </p>
                    <p class="text-xs-left" v-if="field.value === 'dueAt'">
                      {{ item._source.dueAt | cleanDate }}
                    </p>
                    <p class="text-xs-left" v-if="field.value === 'sendAt'">
                      {{ item._source.sendedAt | cleanDate}}
                    </p>
                    <p class="text-xs-left" v-if="field.value === 'totalTtc'">
                      {{ item._source.totalTtc | getCurrentMoney }}
                    </p>
                    <p class="text-xs-left" v-if="field.value === 'Status'">
                      {{ $t(item._source.status) }}
                    </p>
                    <p class="text-xs-left" v-if="field.value === 'sendType'">
                      {{ $t(item._source.sendType) }}
                    </p>
                    <p class="text-xs-left" v-if="field.value === 'paymentMode'">
                      {{ (item._source.paymentMode) ?  $t(item._source.paymentMode) : "Virement"}}
                    </p>
                    <p class="justify-center layout px-0" v-if="field.value === 'name'">
                      <v-btn
                          icon class="mx-0"
                          @click="getInvoiceDetail(item._source.id)"
                      >
                        <v-icon
                            color="secondary"
                        >
                          visibility
                        </v-icon>
                      </v-btn>
                      <v-btn
                          icon class="mx-0"
                          v-for="(invoiceFile, key) in item._source.invoiceFiles"
                          :key="key" @click="getFile(item._source.number, invoiceFile)"
                      >
                        <v-icon
                            color="secondary"
                            v-if="invoiceFile.type === 'pdf'"
                        >
                          picture_as_pdf
                        </v-icon>
                        <v-icon
                            class="icon-width"
                            v-if="invoiceFile.type === 'csv'"
                        >
                          table_view
                        </v-icon>
                      </v-btn>
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <div v-if="!loading" class="text-xs-center pt-2 orders-pagination">
            <v-pagination v-model="page" :total-visible="20" :length="pages">
            </v-pagination>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import { SEARCH_DEBOUNCE_WAIT_TIMER } from '@/utils/constants';
import { withKey } from '@/utils/sort';

const DEFAULT_ROWS_PER_PAGE = 15;
export default {
  name: 'BaseInvoiceList',
  props: {
    title: null,
  },
  data() {
    return {
      page: 1,
      pagination: {
        sortBy: 'id', descending: false, rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
      query: {
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        order: {},
        filters: {},
      },
      lastFilterHash: null,
      order: { createdAt: 'desc' },
    };
  },
  computed: {
    ...mapState({
      headers: (state) => state.invoice.header,
      loading: (state) => state.invoice.loading,
      pages: (state) => state.invoice.pagination.pages,
      currentPage: (state) => state.invoice.pagination.currentPage,
      states: (state) => state.invoice.states.sort(withKey('name')),
      items: (state) => state.invoice.items,
      noDataMessage: (state) => state.invoice.noDataMessage,
      total: (state) => state.invoice.total,
      orders: (state) => state.invoice.items,
      stateQuery: (state) => state.invoice.query,
      facets: (state) => state.invoice.facets,
    }),
    pageTitle() {
      return this.loading ? `${this.title}` : `${this.title} (${this.total})`;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    page: {
      handler() {
        this.query.page = this.page;
        this.delaySearch();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'invoice/getInvoices',
      'invoice/getInvoiceFile',
    ]),
    getFile(invoiceNumber, invoiceFile) {
      this['invoice/getInvoiceFile']({ invoiceNumber, invoiceFile });
    },
    buildQuery() {
      this.query.order = this.order;
    },
    getInvoiceDetail(invoiceId) {
      this.$router.push({
        name: 'PageInvoiceDetail',
        params: { id: invoiceId },
      });
    },
    delaySearch: debounce(function search() {
      this.buildQuery();
      this['invoice/getInvoices'](this.query);
    }, SEARCH_DEBOUNCE_WAIT_TIMER),
    updateFiltersQuery(values) {
      if (values instanceof Object) {
        this.query.filters = values;
      } else {
        this.query.string = values;
      }
      this.query.page = 1;
      this.page = 1;
      this.delaySearch();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.table-filters {
  background-color: #37474f;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 24px;
  padding: 0 20px;
}

.order-list {
  padding-top: 10px;
  padding-bottom: 10px;
}
.orders-pagination .v-pagination{
  margin-bottom: 20px;
}
tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
tr:hover{
  background-color: rgba(0, 0, 0, .10);
  cursor: pointer;
}
@media (max-width: 959px){
  .orders-pagination{
    overflow-y: hidden;
    padding-left: 10px;
    padding-right: 10px;
  }
  .orders-pagination .v-pagination{
    overflow-y: scroll;
  }
}
.icon-width{
  width: 25px;
}

</style>
