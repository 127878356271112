<template>
    <div>
        <v-layout row wrap align-center class="content-header-2col">
            <v-flex xs6 class="content-header-2col-title">
                <base-page-title v-bind:page-title="pageTitle"></base-page-title>
            </v-flex>
        </v-layout>
        <div class="table-filters">
            <v-layout row wrap>
                <!--<v-flex xs12 sm4>
                    <v-autocomplete
                            :items="states"
                            v-model="stateFilter"
                            label="Statut"
                            dark
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            color="primary"
                            item-text="name"
                            item-value="code"
                            prepend-icon="query_builder"
                    ></v-autocomplete>
                </v-flex>-->
                <v-flex xs12 sm4>
                    <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Recherche"
                            hide-details
                            dark
                    ></v-text-field>
                </v-flex>
            </v-layout>
        </div>
        <v-card>
            <v-progress-circular
                    v-if="loading"
                    :size="50"
                    indeterminate color="primary"
            >
            </v-progress-circular>
            <v-data-table
                    :headers="headers"
                    :loading="loading"
                    :items="orders"
                    :search="search"
                    :server-items-length="total"
                    class="order-list"
                    :options.sync="pagination"
                    :no-data-text="noDataMessage"
                    hide-default-footer
            >
                <template v-if="!loading" v-slot:body="{items}" >
                    <tr v-for="(item, i) in items" :key="i" @click="showItem(item)">
                        <td class="text-sm-left pa-4">{{ item.id }}</td>
                        <td class="text-sm-left">{{ item.createdAt | cleanDate }}</td>
                        <td class="text-sm-left">{{ item.customer.office.name}}</td>
                        <td class="text-sm-left">{{ item.customer.clientReference}}</td>
                        <td class="text-sm-left">{{ item.customer.name}}</td>
                        <td class="text-sm-left">{{ $t(item.sendType) }}</td>
                        <td class="text-sm-left">{{ $t(item.deliveryType) }}</td>
                        <td class="text-sm-left">{{ item.dla |cleanDate }}</td>
                        <td class="text-sm-left">{{ $t(item.status) }}</td>
                        <td class="justify-center layout px-0">
                            <v-btn icon class="mx-0" @click="showItem(item)">
                                <v-icon color="secondary">visibility</v-icon>
                            </v-btn>
                        </td>
                    </tr>

                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                </v-alert>
            </v-data-table>
            <div v-if="!loading" class="text-xs-center pt-2 orders-pagination">
                <v-pagination v-model="page" :total-visible="20" :length="pages">
                </v-pagination>
            </div>
        </v-card>

    </div>

</template>

<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import { SEARCH_DEBOUNCE_WAIT_TIMER } from '@/utils/constants';
import { withKey } from '@/utils/sort';

const DEFAULT_ROWS_PER_PAGE = 30;
export default {
  name: 'BaseOrderList',
  props: {
    title: null,
    status: {
      type: String,
    },
  },
  data() {
    return {
      search: '',
      page: 1,
      pagination: {
        sortBy: 'id', descending: false, rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
      query: {
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
      lastFilterHash: null,
    };
  },
  computed: {
    ...mapState({
      headers: (state) => state.order.header,
      loading: (state) => state.order.loading,
      pages: (state) => state.order.pagination.pages,
      states: (state) => state.order.states.sort(withKey('name')),
      order: (state) => state.order.order,
      noDataMessage: (state) => state.order.noDataMessage,
      total: (state) => state.order.total,
      orders: (state) => state.order.items,
      stateQuery: (state) => state.order.query,
    }),
    pageTitle() {
      return this.loading ? `${this.title}` : `${this.title} (${this.total})`;
    },
  },
  watch: {
    search: {
      handler() {
        this.query.id = this.search;
        this.delaySearch();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    page: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'order/getOrders', // -> this['order/getOrders']()
      'order/getOrdersType', // -> this['order/getOrders']()
    ]),
    showItem(item) {
      this.$router.push({ name: 'PageOrderForm', params: { id: item.id } });
    },
    buildQuery() {
      this.query.page = this.page;
    },
    delaySearch: debounce(function search() {
      this.buildQuery();
      if (this.status) {
        this['order/getOrdersType']([this.query, this.status]);
      } else {
        this['order/getOrders'](this.query);
      }
    }, SEARCH_DEBOUNCE_WAIT_TIMER),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .table-filters {
        background-color: #37474f;
        border-radius: 10px;
        margin-top: 12px;
        margin-bottom: 24px;
        padding: 0 20px;
    }
    .orders-pagination .v-pagination{
        margin-bottom: 20px;
    }
    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, .05);
      width: 15%;
    }
    tr:hover{
      background-color: rgba(0, 0, 0, .10);
      cursor: pointer;
    }

    @media (max-width: 959px){
        .orders-pagination{
            overflow-y: hidden;
            padding-left: 10px;
            padding-right: 10px;
        }
        .orders-pagination .v-pagination{
            overflow-y: scroll;
        }
    }
</style>
