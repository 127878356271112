<template>
  <v-card
      class="mx-auto"
      max-width="100%"
  >
    <v-card-actions>
      <h2>Informations supplémentaires</h2>
      <v-spacer/>
      <file-upload
          name="base-sdc-item-banking-account-signedSepa-upload-pdf"
          :extensions="extensionsBankingAccountSignedSepa"
          :accept="acceptSignedSepa"
          :directory="directoryBankingAccountSignedSepa"
          :maximum="maxFilesBankingAccountSignedSepa"
          :multiple=false
          :size="maxFileSizeBankingAccountSignedSepa"
          v-model="filesBankingAccountSignedSepa"
          :drop=true
          :drop-directory=true
          @input-filter="inputFilterBankingAccountSignedSepa"
          @input="uploadSdcBankingAccountSignedSepa"
          ref="uploadedPdf"
      >
        <v-btn color="blue-grey" class="white--text ma-2">Import Mandat Signé
          <v-icon right dark>cloud_upload</v-icon>
        </v-btn>
      </file-upload>
      <v-btn
          v-if="this.item.uploadedAt !== null"
          @click="downloadSdcBankingAccountSignedSepa(item)"
          icon class="mx-0"
          color="primary"
      >
        <v-icon>
          download
        </v-icon>
      </v-btn>
      <v-btn
          color="primary"
          icon
          @click="editMandate"
      >
        <v-icon>
          mdi-pencil-outline
        </v-icon>
      </v-btn>
      <BaseButtonDeleteSdcRib :id="this.item.id"/>
      <v-btn
          icon
          @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
                cols="10"
                class="d-flex justify-space-around"
                v-for="(indexfield, index) in headers"
                v-bind:key="index"
                v-if="isEditable === false"
            >
              <v-row>
                <v-col cols="2">
                  <p class="ma-1 font-italic d-flex align-center">
                  {{indexfield.text}}
                  </p>
                </v-col>
                <v-col cols="10">
                  <p class="d-flex justify-end">
                    {{item[indexfield.value]}}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <BaseEditSdcMandate :headers="headersEdit" :item="item" v-if="isEditable" :cancelEdit="editMandate"/>
          </v-row>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { fileSizeFormat } from '@/utils/common';
import BaseButtonDeleteSdcRib from './BaseButtonDeleteSdcRib';
import { DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER_DETAIL, DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER_EDIT } from '../models/customerSdcBankingAccountsHeader';
import BaseEditSdcMandate from './BaseEditSdcMandate';

const UPLOAD_MAX_FILES_SIGNED_SEPA = 1;
const UPLOAD_MAX_FILE_SIZE_SIGNED_SEPA = 1024 * 20000;

export default {
  name: 'BaseDetailSdcRibMandate',
  data() {
    return {
      headers: DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER_DETAIL,
      headersEdit: DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER_EDIT,
      isEditable: false,
      filesBankingAccountSignedSepa: [],
      errorsBankingAccountSignedSepa: [],
      maxFileSizeBankingAccountSignedSepa: UPLOAD_MAX_FILE_SIZE_SIGNED_SEPA,
      maxFilesBankingAccountSignedSepa: UPLOAD_MAX_FILES_SIGNED_SEPA,
      directoryBankingAccountSignedSepa: false,
      extensionsBankingAccountSignedSepa: ['pdf'],
      mimeTypesBankingAccountSignedSepa: ['application/pdf'],
    };
  },
  props: {
    show: {
      required: true,
    },
  },
  components: {
    BaseEditSdcMandate,
    BaseButtonDeleteSdcRib,
  },
  methods: {
    ...mapActions([
      'sdcBankingAccount/uploadSdcBankingAccountSignedSepa',
      'sdcBankingAccount/downloadSdcBankingAccountSignedSepa',
    ]),
    editMandate() {
      this.isEditable = !this.isEditable;
    },
    downloadSdcBankingAccountSignedSepa(sdcBankingAccount) {
      this['sdcBankingAccount/downloadSdcBankingAccountSignedSepa']({ customer: this.customer, sdcBankingAccount });
      this.empty();
    },
    uploadSdcBankingAccountSignedSepa() {
      this['sdcBankingAccount/uploadSdcBankingAccountSignedSepa']({ sdcBankingAccount: this.item, filesBankingAccountSignedSepa: this.filesBankingAccountSignedSepa, basicUser: this.basicUser.profile });
      this.empty();
    },
    concatWithColonSignedSepa() {
      return this.mimeTypesBankingAccountSignedSepa.length
        ? this.mimeTypesBankingAccountSignedSepa.reduce((accumulator, currentValue) => `${accumulator},${currentValue}`)
        : null;
    },
    inputFilterBankingAccountSignedSepa(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        if (
          UPLOAD_MAX_FILES_SIGNED_SEPA !== 0
            && this.filesBankingAccountSignedSepa.length === UPLOAD_MAX_FILES_SIGNED_SEPA
        ) {
          this.errors.push(`Le nombre de fichiers est limité à ${UPLOAD_MAX_FILES_SIGNED_SEPA}`);
          return prevent();
        }

        if (this.mimeTypesBankingAccountSignedSepa.length
            && !this.mimeTypesBankingAccountSignedSepa.includes(newFile.type)) {
          this.errorsBankingAccountSignedSepa.push(`Le type du fichier ${newFile.name} n'est pas accepté`);
          return prevent();
        }

        if (newFile.size > UPLOAD_MAX_FILE_SIZE_SIGNED_SEPA) {
          this.errorsBankingAccountSignedSepa.push(`La taille des fichiers est limité à ${fileSizeFormat(UPLOAD_MAX_FILE_SIZE_SIGNED_SEPA)}`);
          return prevent();
        }
      }

      return true;
    },
    empty() {
      this.filesBankingAccount = [];
    },
  },
  computed: {
    ...mapState({
      item: (state) => state.sdcBankingAccount.item,
      basicUser: (state) => state.user.oidcUser,
      customer: (state) => state.customer.item,
    }),
    acceptSignedSepa() {
      return this.concatWithColonSignedSepa();
    },
  },
};
</script>
