<template>
    <div>
        <v-layout row wrap align-center class="content-header-2col">
            <v-flex xs6 class="content-header-2col-title">
                <base-page-title v-bind:page-title="pageTitle"></base-page-title>
            </v-flex>
            <v-flex xs6 class="content-header-2col-btn">
                <v-btn
                        color="primary"
                        outline
                        round
                        depressed
                        @click="newItem()"
                >
                    Nouveau ticket
                </v-btn>
            </v-flex>
        </v-layout>
        <div class="table-filters">
            <v-layout row wrap>
                <v-flex xs12 sm4>
                    <v-select
                            :items="statuses"
                            v-model="statusFilter"
                            label="Statut"
                            dark
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            color="primary"
                            item-text="name"
                            item-value="name"
                            prepend-icon="query_builder"
                    ></v-select>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-select
                            :items="classifications"
                            v-model="classificationFilter"
                            label="Qualification"
                            dark
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            item-text="name"
                            item-value="name"
                            prepend-icon="check"
                    ></v-select>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Recherche"
                            hide-details
                            dark
                    ></v-text-field>
                </v-flex>
            </v-layout>
        </div>
        <v-card>
            <v-progress-circular
                    v-if="loading"
                    :size="50"
                    indeterminate color="primary"
            >
            </v-progress-circular>
            <v-data-table
                    :headers="headers"
                    :loading="loading"
                    :items="tickets"
                    :search="search"
                    :total-items="total"
                    class="ticket-list"
                    :pagination.sync="pagination"
                    :no-data-text="noDataMessage"
                    hide-actions
            >
                <template v-if="!loading" slot="items" slot-scope="props">
                    <td class="text-xs-left">{{ props.item.id }}</td>
                    <td class="text-xs-left">{{ props.item.createdAt | moment }}</td>
                    <td class="text-xs-left">{{ props.item.title }}</td>
                    <td class="text-xs-left">{{ props.item.contact }}</td>
                    <td class="text-xs-left">{{ props.item.status }}</td>
                    <td class="text-xs-left">
                        <div v-if="props.item.classification">
                            {{ props.item.classification }}
                        </div>
                    </td>
                    <td class="justify-center layout px-0">
                        <v-btn icon class="mx-0" @click="showItem(props.item)">
                            <v-icon color="secondary">visibility</v-icon>
                        </v-btn>
                    </td>
                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                </v-alert>
            </v-data-table>
            <div v-if="!loading" class="text-xs-center pt-2 tickets-pagination">
                <v-pagination v-model="pagination.page" :total-visible="7" :length="pages">
                </v-pagination>
            </div>
        </v-card>

    </div>

</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'BaseTicketsList',
  props: {
    isAdministrativeClosure: null,
    title: null,
    tickets: [],
    total: null,
  },
  data() {
    return {
      search: '',
      pagination: {
        sortBy: 'createdAt', descending: true, rowsPerPage: 10, page: 1,
      },
      query: { isAdministrativeClosure: null, itemsPerPage: 10 },
      page: 1,
      statusFilter: [],
      classificationFilter: [],
      date: null,
      menu: false,
    };
  },
  filters: {
    moment(date) {
      return moment.unix(date).format('DD/MM/YYYY HH:mm:ss');
    },
  },
  computed: {
    ...mapState({
      headers: (state) => state.tickets.header,
      loading: (state) => state.tickets.loading,
      pages: (state) => state.tickets.pagination.pages,
      order: (state) => state.tickets.order,
      statuses: (state) => state.tickets.statuses,
      classifications: (state) => state.tickets.classifications,
      noDataMessage: (state) => state.tickets.noDataMessage,
    }),
    pageTitle() {
      if (this.loading) {
        return `${this.title}`;
      }
      return `${this.title} (${this.total})`;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    statusFilter: {
      handler() {
        this.query.status = this.statusFilter;
        this.query.isAdministrativeClosure = this.isAdministrativeClosure;
        this.filterTickets();
      },
      deep: true,
    },
    classificationFilter: {
      handler() {
        this.query.classification = this.classificationFilter;
        this.query.isAdministrativeClosure = this.isAdministrativeClosure;
        this.filterTickets();
      },
      deep: true,

    },
  },
  created() {
    this.query.isAdministrativeClosure = this.isAdministrativeClosure;
  },
  methods: {
    showItem(item) {
      this.$router.push({ name: 'PageSupportTicketShow', params: { id: item.id } });
    },
    newItem() {
      this.$router.push({ name: 'PageSupportTicketNew' });
    },
    filterTickets() {
      const rowPerPage = 10;
      this.query.title = this.search;
      this.query.page = this.pagination.page;
      this.query.order = {};

      if (this.pagination.sortBy !== null) {
        this.query.order[this.pagination.sortBy ? this.pagination.sortBy : this.order] = this.pagination.descending ? 'desc' : 'asc';
      } else {
        this.query.order[this.order] = 'asc';
      }

      if (typeof this.pagination.rowsPerPage === 'undefined' || this.pagination.rowsPerPage === -1) {
        this.pagination.rowsPerPage = rowPerPage;
        this.query.itemsPerPage = rowPerPage;
      } else {
        this.query.itemsPerPage = this.pagination.rowsPerPage
          ? this.pagination.rowsPerPage : rowPerPage;
      }
      this.$store.dispatch('tickets/getAllTickets', this.query);
    },
    delaySearch: _.debounce(function search() {
      this.filterTickets();
    }, 1000),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .table-filters {
        background-color: #37474f;
        border-radius: 10px;
        margin-top: 12px;
        margin-bottom: 24px;
        padding: 0 20px;
    }

    .ticket-list {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .tickets-pagination .v-pagination{
        margin-bottom: 20px;
    }
    @media (max-width: 959px){
        .tickets-pagination{
            overflow-y: hidden;
            padding-left: 10px;
            padding-right: 10px;
        }
        .tickets-pagination .v-pagination{
            overflow-y: scroll;
        }
    }
</style>
