<template>
  <v-card class="pa-2">
    <v-card-title class="user-detail-title">
      <v-avatar color="primary" size="34">
        <v-icon dark>account_circle</v-icon>
      </v-avatar>
      <span>Localisation</span>
      <v-spacer></v-spacer>
      <v-btn
          v-if="showLocalisation"
          icon
          @click="editCustomer"
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn icon @click="showLocalisation = !showLocalisation">
        <v-icon>{{ isShowLocalisation }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-slide-y-transition>
      <v-container fluid v-show="showLocalisation">
        <v-layout row v-for="(item, index) in displayCustomerField" :key="index">
          <div v-if="!isEditing" class="list-item-customer">
            <v-flex xs6 class="list-item pa-0">
              <p><i>{{item.key}}</i></p>
            </v-flex>
            <v-flex xs6 class="list-item pa-0">
              <p v-if="item.key !== 'Adresse:'"><b>{{getAllValue(item.value)}}</b></p>
              <p v-else><b>{{getAllValue(item.value)| concatCustomerAddress}}</b></p>
            </v-flex>
          </div>
          <div v-else class="list-item-customer">
            <v-flex xs6 class="list-item pa-0">
              <p><i>{{item.key}}</i></p>
            </v-flex>
            <v-flex xs6 class="list-item pa-0">
              <v-text-field
                  v-if="item.key !== 'Adresse:'"
                  v-model="newAddress[item.value]"
              />
              <div v-else>
                <v-text-field
                    v-for="(addressField, key) in newAddress.address"
                    :key="key"
                    :value="addressField"
                    v-model="newAddress.address[key]"
                />
              </div>
            </v-flex>
          </div>
        </v-layout>
        <v-layout>
          <v-flex xs6>
            <v-btn
                color="red"
                v-if="isEditing"
                class="white--text"
                @click="cancelCustomerEditing"
            >
              Annuler
            </v-btn>
          </v-flex>
          <v-flex xs6>
            <v-btn
              color="green"
              v-if="isEditing"
              class="white--text"
              @click="sendCustomerEditing"
            >
              Modifier
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-slide-y-transition>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';
import EditingAddress from '@/models/editingAddress';
import fetchValue from '../utils/fetch-value';

export default {
  name: 'base-customer-location',
  props: {
    customer: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      newAddress: new EditingAddress(),
      showLocalisation: true,
      addressListField: {},
      isEditing: false,
      displayCustomerField: [
        {
          key: 'Adresse:',
          value: '',
        },
        {
          key: 'Ville:',
          value: 'city',
        },
        {
          key: 'Code Postale',
          value: 'zipCode',
        },
        {
          key: 'Pays:',
          value: 'country',
        },
      ],
    };
  },
  computed: {
    isShowLocalisation() {
      return this.showLocalisation ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
  },
  methods: {
    ...mapActions([
      'customer/updateCustomerInfo',
    ]),
    getAllValue(value) {
      return fetchValue(value, this.customer);
    },
    editCustomer() {
      this.isEditing = !this.isEditing;
    },
    sendCustomerEditing() {
      this['customer/updateCustomerInfo']({ id: this.customer.id, data: this.newAddress });
      this.isEditing = false;
    },
    cancelCustomerEditing() {
      this.isEditing = false;
      this.setNewAddress();
    },
    setNewAddress() {
      this.newAddress.address[0] = this.customer.addressFieldOne;
      this.newAddress.address[1] = this.customer.addressFieldTwo;
      this.newAddress.address[2] = this.customer.addressFieldThree;
      this.newAddress.city = this.customer.city;
      this.newAddress.zipCode = this.customer.zipCode;
      this.newAddress.country = this.customer.country;
    },
  },
  mounted() {
    this.setNewAddress();
  },
};
</script>
<style scoped>
.list-item {
  display: flex;
  justify-content: start;
}

.user-detail-title span {
  margin-left: 10px;
}

.user-roles-table tr {
  background-color: #f3f3f3;
}

.user-roles-table tr td {
  width: 50%;
  padding: 12px 15px;
  color: #565656;
}

.user-roles-table tr td:first-child {
  text-align: left;
}

.user-roles-table tr td:last-child {
  padding-bottom: 0;
}

.list-item-customer{
  display: flex;
  width: 100%;
  align-items: center;
}

@media (max-width: 599px) {
}
</style>
