export default function fetchValue(path, obj) {
  if (path === '') {
    return obj;
  }
  let result = obj;
  path.split('.').forEach((key) => {
    result = result[key];
  });
  return result;
}
