<template>
  <div class="hello">
    <div v-if="loading">
      <v-progress-circular
              :size="150"
              :width="7"
              color="primary"
              indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <v-layout row wrap>
        <v-flex xs12 md4 lg3>
          <v-card class="open-tickets">
            <v-card-title primary-title>
              <div class="number">{{ connection.message }}</div>
            </v-card-title>
            <div class="text-xs-center">
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashBoard',
  data() {
    return {
      connection: {
        status: false,
        color: 'black darken-2',
        icon: null,
        message: 'Hercule',
      },
      loading: true,
      timer: '',
    };
  },
  mounted() {
    this.connect();
    this.timer = setInterval(this.connect(), 300000);
  },
  methods: {
    connect() {
      this.loading = false;
    },
    /* api.getData('/api')
        .then(() => {
          this.connection.status = false;
          this.connection.color = 'green darken-2';
          this.connection.icon = 'sentiment_very_satisfied';
          this.connection.status = 'sync';
          this.loading = false;
          this.connection.message = 'Connection ok';
        })
        .catch((error) => {
          this.connection.message = error.message;
          this.connection.color = 'red darken-2';
          this.connection.icon = 'sentiment_very_dissatisfied';
          this.connection.status = 'sync_disabled';
          this.loading = false;
        });
    }, */

  },
  beforeDestroy() {
    clearInterval(this.timer);
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h1, h2 {
    font-weight: normal;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .open-tickets{
    padding-bottom: 35px;
    background: url('../assets/background_open_tickets_home.png') no-repeat center center #2980b9;
    color: #ffffff;
    font-size: 20px;
    font-family: 'ubuntumedium';
    text-transform: uppercase;
  }
  .open-tickets .v-card__title{
    display: block;
    padding-top: 50px;
    padding-bottom: 35px;
  }
  .open-tickets .number{
    width: 100%;
    margin-bottom: 25px;
    font-size: 52px;
    line-height: 1;
    font-family: 'UbuntuBold';
    word-wrap: break-word;
  }
  .open-tickets .v-btn{
    background-color: #2980b9!important;
  }
</style>
