<template>
  <v-container class="container-class">
    <v-btn
        dark
        @click="openDialogMandat"
        icon
        color="blue lighten-2">
      <v-icon>chat</v-icon>
    </v-btn>

    <v-dialog
        v-model="dialog"
        max-width="1500"
        transition="dialog-bottom-transition"
    >
      <v-card class="card-outter">
        <v-progress-circular
            v-if="loading"
            :size="50"
            indeterminate color="primary"
        >
        </v-progress-circular>
        <v-card-text class="comment-div" v-if="!loading">
          <v-timeline v-if="this.notes">
            <v-timeline-item
                v-for="(item, index) in this.notes"
                :key="index"
                color="red lighten-2"
                large
            >
              <template v-slot:opposite>
                <span>{{item.createdAt | cleanDate}}</span>
              </template>
              <v-card class="elevation-1">
                <v-card-title>
                  <p>
                    <b>{{item.author}}</b>
                  </p>
                </v-card-title>
                <v-card-text>
                  <p v-html="item.content">
                    {{item.content}}
                  </p>
                </v-card-text>
                <div>
                  <v-chip
                      v-for="(tag, key) in item.tags"
                      :key="key" class="caption pa-2"
                      small
                      color="primary"
                      text-color="white"
                  > {{ tag.label }}
                  </v-chip>
                </div>
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <p v-else>pas de commentaire</p>
        </v-card-text>
        <v-card-actions class="card-actions">
          <v-divider light width="100%"/>
          <v-layout class="card-action-item">
            <v-flex xs10>
              <quill-editor
                  :content="content"
                  :options="editorOption"
                  @change="onEditorChange($event)"
              />
            </v-flex>
            <v-flex xs2 class="d-flex justify-center flex-item">
              <v-icon
                  color="primary"
                  @click="addNewComment"
                  class="d-flex align-center">
                send
              </v-icon>
            </v-flex>
          </v-layout>
          <v-combobox
              v-model="label"
              :items="itemCombo"
              label="Ajouter un tag"
              chips
              clearable
              multiple
              class="combo-box-item"
          />

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import Notes from '../models/Notes';

export default {
  name: 'BaseNoteInformation',
  props: {
    customer: {
      required: true,
    },
    user: {
      required: true,
    },
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      dialog: false,
      label: [],
      contentNewComment: '',
      itemCombo: [],
      content: '',
      editorOption: {
        placeholder: 'Commentaire..',
      },
      newComment: new Notes(),
    };
  },
  computed: {
    ...mapState({
      notes: (state) => state.note.items,
      loading: (state) => state.note.loading,
      tags: (state) => state.tag.items,
    }),
  },
  methods: {
    ...mapActions([
      'note/addNewNote',
      'note/getNotes',
      'tag/getTags',
    ]),
    onEditorChange({ html }) {
      this.content = html;
    },
    openDialogMandat() {
      this.dialog = true;
    },
    addNewComment() {
      if (this.content !== '') {
        const tags = [];
        this.label.forEach((value) => {
          tags.push({ label: value });
        });
        const newCommentObject = tags.length !== 0
          ? { ...this.newComment, content: this.content, tags }
          : { ...this.newComment, content: this.content };

        this['note/addNewNote']({ newCommentObject, customer: this.customer.id });
      }
      this.chips = [];
      this.content = '';
    },
  },
  created() {
    this['note/getNotes']({ customer: this.customer.id });
    this['tag/getTags']();
    this.itemCombo = this.tags.map((tag) => tag.label);
    this.newComment.author = this.user.name;
  },
};
</script>
<style scoped>
.card-outter {
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.comment-div{
  overflow:scroll
}
.card-actions {
  flex-direction: column;
}

.card-action-item {
  width: 100%;
  align-items: center;
}

p {
  word-break: break-word;
}
.combo-box-item {
  width: 95%;
}
.container-class{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
}

</style>
