<template>
  <div class="hello content-header-h1">
    <h1>{{ pageTitle }}</h1>
  </div>
</template>

<script>
export default {
  name: 'BasePageTitle',
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  h1, h2 {
    font-weight: normal;
    text-align: left;
  }
  .content-header-h1{
    margin-bottom: 20px;
  }
</style>
