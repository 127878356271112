import api from '@/utils/api';
import { getDefaultPagination, commitPagination } from '@/utils/store-util';
import { addSettersMutations, addResetMutation } from '@/utils/module-util';
import { wrapActionWithRequest } from '@/utils/cancel-action';
import router from '@/router';
import Campaign from '@/models/campaign';

const initialState = () => ({
  items: [],
  pagination: getDefaultPagination(),
  loading: false,
  initialized: false,
  dialog: false,
  item: new Campaign(),
  order: 'createdAt',
  descending: true,
  total: 0,
  totalArchived: 0,
  noDataMessage: '',
  error: [],
  header: [
    {
      value: 'id',
      text: 'Numéro',
      sortable: true,
      align: 'left',
    },
    {
      value: 'createdAt',
      text: 'Date de création',
      sortable: true,
      format: 'datetime',
      align: 'left',
    },
    {
      value: 'name',
      text: 'Nom de la campagne',
      sortable: true,
      align: 'left',
    },
    {
      value: 'sender',
      text: 'Expéditeur',
      sortable: true,
      align: 'left',
    },
    {
      value: 'sendDate',
      text: 'A envoyer le',
      sortable: true,
      align: 'left',
    },
    {
      value: 'status',
      text: 'Statut',
      sortable: true,
      format: 'status',
      style: 'status',
    },
    {
      value: 'btn',
      text: '',
      sortable: false,
      format: 'btn_action',
      align: 'center',
    },
  ],
});

const getCampaignsAction = ({ commit }, query = {}) => {
  commit('setLoading', { loading: true });
  commit('setQuery', query);
  api.getData('/api/campaigns', query)
    .then((res) => {
      commit('setItems', res.data['hydra:member']);
      commit('setTotal', res.data['hydra:totalItems']);
      commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], query);
      commit('setLoading', { loading: false });
      commit('setNoDataMessage', api.emptyListMessage);
    })
    .catch(() => {
      commit('setLoading', { loading: false });
    });
};
const getters = {};
const actions = {
  getCampaigns: wrapActionWithRequest(getCampaignsAction, { autoAbort: true }),
  getItem({ commit }, id) {
    commit('setLoading', { loading: true });
    api.getData(`/api/campaigns/${id}`)
      .then((res) => {
        commit('setItem', res.data);
        commit('setLoading', { loading: false });
      });
  },
  save({ commit }, data) {
    commit('setLoading', { loading: true });
    api
      .postData('/api/campaigns', data.campaign)
      .then((res) => {
        commit('setItem', res.data);
        router.push({
          name: 'PageCampaignEdit',
          params: { id: res.data.id },
        });
        commit('setLoading', { loading: false });
        commit('dialogSwich', false);
        commit(
          'notice/setNotice',
          {
            text: 'Vous pouvez rajouter des fichiers à cette campagne',
            color: 'success',
          },
          { root: true },
        );
      })
      .catch((error) => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
        commit('setLoading', { loading: false });

        if (error.violations) {
          commit('setError', error.violations);
        }
      });
  },
  update({ commit }, data) {
    commit('setLoading', { loading: true });
    api
      .putData(`/api/campaigns/${data.owner}`, data.campaign)
      .then((res) => {
        commit('setItem', res.data);
        commit('setLoading', { loading: false });
        commit('dialogSwich', false);
        commit(
          'notice/setNotice',
          {
            text: 'Votre campagne a été mise à jour',
            color: 'success',
          },
          { root: true },
        );
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
        commit('setLoading', { loading: false });
      });
  },
  removeItem({ commit }, data) {
    commit('setLoading', { loading: true });
    api
      .putData(`/api/campaigns/${data.owner}/delete-upload-file`, data.item)
      .then((res) => {
        const campaignSaved = res.data;
        commit('setItem', campaignSaved);
        router.push({
          name: 'PageCampaignEdit',
          params: { id: campaignSaved.id },
        });
        commit('setLoading', { loading: false });
        commit(
          'notice/setNotice',
          {
            text: 'Vous pouvez rajouter des fichiers à cette campagne',
            color: 'success',
          },
          { root: true },
        );
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
        commit('setLoading', { loading: false });
      });
  },
  activate({ commit }, data) {
    commit('setLoading', { loading: true });
    api
      .putData(`/api/campaigns/${data.owner}/activate`, {})
      .then((res) => {
        const campaignSaved = res.data;
        commit('setItem', campaignSaved);
        router.push({ name: 'PageCampaignList' });
        commit(
          'notice/setNotice',
          {
            text: 'La campagne est activée',
            color: 'success',
          },
          { root: true },
        );
      })
      .catch((err) => {
        let errorMessage = api.errorMessage;
        if (err.data['hydra:description'].match(/^No entry for client reference/g)) {
          errorMessage = err.data['hydra:description'].replace(/No entry for client reference/g, 'Aucune entrée pour la référence du client :').replace(/\\n/g, '    ');
        }
        if (err.data['hydra:description'] === 'Incorrect drawdown notice campaign : no CSV file found.') {
          errorMessage = 'Impossible d\'activer cette campagne sans un fichier csv de destinataire';
        }

        if (err.data['hydra:description'] === 'No PDF file present') {
          errorMessage = 'Impossible d\'activer cette campagne sans un fichier pdf valide';
        }
        commit(
          'notice/setNotice',
          {
            text: errorMessage,
            color: 'error',
          },
          { root: true },
        );
        commit('setLoading', { loading: false });
      });
  },
  sendFiles({ commit }, files) {
    commit('setLoading', { loading: true });
    const data = new FormData();
    files.data.forEach((element, index) => {
      data.append(`files[${index}]`, element.file);
    });
    api
      .uploadloadFiles(`/api/campaigns/${files.owner}/upload-files`, data)
      .then((res) => {
        commit('setItem', res.data);
        router.push({
          name: 'PageCampaignEdit',
          params: { id: res.data.id },
        });
        commit(
          'notice/setNotice',
          {
            text: 'Fichier envoyé avec succès',
            color: 'success',
          },
          { root: true },
        );
        router.push({
          name: 'PageCampaignEdit',
          params: { id: files.owner },
        });
        commit('setLoading', { loading: false });
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
        router.push({
          name: 'PageCampaignEdit',
          params: { id: files.owner },
        });
        commit('setLoading', { loading: false });
      });
  },
  reset({ commit }) {
    return Promise.resolve(commit('reset'));
  },
};

const mutations = {
  ...addSettersMutations([
    'items',
    'total',
    'totalArchived',
    'pagination',
    'item',
    'order',
    'descending',
    'initialized',
    'noDataMessage',
    'error',
  ]),
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  resetItem(state) {
    state.item = new Campaign();
  },
  setQuery(state, query) {
    state.query = query;
  },
  dialogSwich(state, dialog) {
    state.dialog = dialog;
  },
  ...addResetMutation(initialState, [
    'items',
    'total',
    'initialized',
    'totalArchived',
    'order',
    'descending',
    'error',
  ]),
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
