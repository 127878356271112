<template>
    <div>
        <base-page-title v-bind:page-title="title"></base-page-title>
        <v-card>
            <v-data-table
                    :headers="headers"
                    :items="campaign.items"
                    class="elevation-1"
                    v-if="showItem"
                    rows-per-page-text="Nombre de lignes"
            >
                <template slot="items" slot-scope="props">
                    <td class="text-xs-left">{{ props.item.id }}</td>
                    <td class="text-xs-left">{{ props.item.clientReference}}</td>
                    <td class="text-xs-left">{{ props.item.filename }}</td>
                    <td class="text-xs-left">
                        <ul id="example-1" v-if="props.item.messages">
                            <li v-for="(item, key) in props.item.messages" v-bind:key="key">
                                {{ item.email }} | {{ item.status| translateInvitationState}}
                              <span v-if="item.errorDetail"> : {{item.errorDetail}} </span>
                            </li>
                        </ul>
                    </td>
                </template>
                <template
                        slot="pageText"
                        slot-scope="props"
                >{{ props.pageStart }} - {{ props.pageStop }} sur {{ props.itemsLength }}</template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'PageCampaignShow',
  data: () => ({
    toggle_exclusive: null,
    files: [],
    headers: [
      {
        value: 'id',
        text: '#',
        sortable: true,
        align: 'left',
      },
      {
        value: 'clientReference',
        text: 'Client',
        sortable: true,
        align: 'left',
      },
      {
        value: 'filename',
        text: 'Fichier',
        sortable: true,
      },
      {
        value: 'status',
        text: 'Messages',
        sortable: false,
        format: 'status',
        style: 'status',
      },
    ],
  }),
  computed: {
    showItem() {
      return (this.campaign.items.length !== 0);
    },
    showActivateBtn() {
      return (this.campaign.status === 'pending');
    },
    ...mapState({
      campaign: (state) => state.campaign.item,
      loading: (state) => state.campaign.loading,
    }),
    title() {
      if (this.campaign.createdAt) {
        return `Campagne ${this.campaign.name} du ${moment(this.campaign.sendDate).format('DD/MM/YYYY')} (${this.translateCampaignState(this.campaign.status)})`;
      }
      return '';
    },
  },
  methods: {
    submit() {
      this.$store.dispatch('campaign/sendFiles', {
        owner: this.campaign.id,
        data: this.files,
      });
    },
    activate() {
      this.$store.dispatch('campaign/activate', { owner: this.campaign.id });
    },
    clear() {
      this.$v.$reset();
      this.$store.commit('campaign/resetItem');
    },
    load() {
      if (this.$route.params.id) {
        this.$store.dispatch('campaign/getItem', this.$route.params.id, this.query);
      }
    },
    translateCampaignState(state) {
      let translatedState = '';

      switch (state) {
        case 'created':
          translatedState = 'En attente de fichier';
          break;
        case 'ready':
          translatedState = 'Validée en attente de traitement';
          break;
        case 'pending':
          translatedState = 'Brouillon en attente de validation';
          break;
        case 'done':
          translatedState = 'Traitée';
          break;
        case 'error':
          translatedState = 'Impossible de traiter votre demande';
          break;
        default:
          translatedState = '';
      }

      return translatedState;
    },
  },
  filters: {
    translateInvitationState(state) {
      let translatedState = '';

      switch (state) {
        case 'sent':
          translatedState = 'Envoyé';
          break;
        case 'opened':
          translatedState = 'Ouvert';
          break;
        case 'unknown':
          translatedState = 'Inconnu';
          break;
        case 'queued':
          translatedState = 'En cours de traitement';
          break;
        case 'clicked':
          translatedState = 'Ouvert';
          break;
        case 'spam':
          translatedState = 'Spam';
          break;
        case 'blocked':
          translatedState = 'Bloqué';
          break;
        case 'created':
          translatedState = 'En attente d\'envoi';
          break;
        case 'fail_request':
          translatedState = 'Statut indisponible ';
          break;
        default:
          translatedState = state;
      }

      return translatedState;
    },
    moment(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },

  },
  created() {
    this.load();
  },
};
</script>

<style scoped>

</style>
