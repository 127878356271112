export default class Campaign {
  constructor() {
    this.type = 'drawdown-notice';
    this.items = [];
    this.uploadedFiles = [];
    this.sender = null;
    this.sendDate = null;
    this.subject = null;
    this.body = 'Madame, Monsieur, Bonjour,<br>'
      + '<br>'
      + 'Veuillez trouver ci-joint l’avis concernant le(s) prélèvement(s) qui seront effectués en date de l’échéance indiquée.<br>'
      + '<br>'
      + 'Vous en souhaitant bonne réception.<br>'
      + '<br>'
      + 'Bien à vous,<br>'
      + '<br>'
      + 'Votre chargée de Recouvrement<br>'
      + 'ATHOME<br>';
  }
}
