<template>
  <div>
    <v-btn
        color="primary"
        outlined
        rounded
        depressed
        @click="openDialogLre"
    >
      <v-icon left light>email</v-icon>
      Demo Lre
    </v-btn>
    <v-dialog v-model="dialogLre" max-width="500">
     <v-card v-if="loading">
       <v-progress-circular
           :size="50"
           indeterminate color="primary"
       />
     </v-card>
      <v-card v-if="!loading">
        <v-card-title class="title-column">
          <p class="pa-0 ma-0">DEMO LRE</p>
          <v-spacer></v-spacer>
          <v-icon @click="reset">close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-select
                v-model="lreToSend.type"
                :items="sendType"
                :rules="[v => !!v || 'Le type est obligatoire']"
                label="Type d'envoi"
                required
            />
            <div v-if="lreToSend.type">
              <v-text-field
                  v-model="lreToSend.senderAddress"
                  :rules="emailRules"
                  label="Email de l'expéditeur"
                  required
              />
              <div class="name-recipient">
                <v-flex xs5>
                  <v-text-field
                      v-model="lreToSend.recipientFirstName"
                      :rules="requiredRules"
                      label="Prénom du destinataire"
                      required
                  />
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                      v-model="lreToSend.recipientLastName"
                      :rules="requiredRules"
                      label="Nom du destinataire"
                      required
                  />
                </v-flex>
              </div>
              <v-text-field
                  v-model="lreToSend.recipientAddress"
                  :rules="emailRules"
                  label="Email du destinataire"
                  required
              />
              <v-text-field
                  v-model="lreToSend.subject"
                  :rules="requiredRules"
                  label="Objet du mail"
                  required
              />
              <v-textarea
                  v-if=" lreToSend.type === 'email'"
                  v-model="lreToSend.content"
                  outlined
                  label="Contenu du mail"
              />
              <v-btn
                  color="error"
                  @click="reset"
              >
                Annuler
              </v-btn>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="validate"
              >
                Envoyer
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Lre from '../models/lre';

export default {
  name: 'BaseDemoLreSend',
  data() {
    return {
      dialogLre: false,
      valid: true,
      lreToSend: new Lre(),
      requiredRules: [
        (v) => !!v || 'Ce champs est obligatoire',
      ],

      emailRules: [
        (v) => !!v || 'E-mail est obligatoire',
        (v) => /.+@.+/.test(v) || 'L\'email doit correspondre a une forme Email',
      ],
      selectedType: null,
      sendType: [
        'lres-equisign',
        'lreq-equisign',
        'email',
      ],
    };
  },
  methods: {
    ...mapActions([
      'communication/createCommunication',
    ]),
    openDialogLre() {
      this.dialogLre = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this['communication/createCommunication'](this.lreToSend);
      }
    },
    reset() {
      this.$refs.form.reset();
      this.dialogLre = false;
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.communication.items,
      loading: (state) => state.communication.loading,
    }),
  },
  watch: {
    loading(newVal) {
      if (newVal === false) {
        this.dialogLre = false;
      }
    },
  },
};
</script>

<style scoped>
.name-recipient{
  display: flex;
  justify-content: space-between;
}
</style>
