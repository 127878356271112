<template>
  <v-card>
    <div>
      <v-card-title class="user-detail-title">
      <div>
        <div class="title-file">
          <v-avatar color="primary" size="34">
            <v-icon dark>folder</v-icon>
          </v-avatar>
          <span>Document({{orderFilesCount}})</span>
        </div>
        <v-subheader>
          {{countSummaryFile}}
          {{countNominativeFile}}
          {{countAnonymousFile}}
          {{countMatimFile}}
          </v-subheader>
      </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = !show">
          <v-icon>{{ isShow}}</v-icon>
        </v-btn>
      </v-card-title>
    </div>
    <transition-group>
      <v-container
          fill-height
          fluid
          v-show="show"
          v-for="(file, key) in this.order.orderFiles"
          :key="key"
      >
        <v-layout fill-height class="user-detail-layout">
          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                Numero du document
              </p>
              <p class="item">
                {{ file.id }}
              </p>
            </div>
          </v-flex>

          <v-divider vertical/>

          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                Type de doucment
              </p>
              <p class="item">
                {{ $t(file.type)}}
              </p>
            </div>
          </v-flex>

          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                Nom du document
              </p>
              <p class="item">
                {{ file.fileName}}
              </p>
            </div>
          </v-flex>
          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                source
              </p>
              <p class="item">
                {{ $t(file.source)}}
              </p>
            </div>
          </v-flex>
          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                wording
              </p>
              <p class="item">
                {{ $t(file.wording)}}
              </p>
            </div>
          </v-flex>
          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                Positionnement des documents
              </p>
              <p class="item">
                {{ $t(file.orderPosition)}}
              </p>
            </div>
          </v-flex>
          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                Document natif
              </p>
              <p class="item">
                {{ file.isNative | isYesOrNO}}
              </p>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </transition-group>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrderDetailDocuments',
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapState({
      order: (state) => state.order.item,
    }),
    orderFilesCount() {
      return this.order.orderFiles.length;
    },
    isShow() {
      return this.show ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
    countAnonymousFile() {
      const countAnonymous = this.order.orderFiles.filter((file) => file.type === 'anonymous' || file.type === 'right').length;
      if (countAnonymous === 0) {
        return '';
      }
      return `${countAnonymous} ${this.$tc('anonymous-count', countAnonymous)}`;
    },
    countMatimFile() {
      const countMatim = this.order.orderFiles.filter((file) => file.type === 'matim').length;
      if (countMatim === 0) {
        return '';
      }
      return `${countMatim} ${this.$tc('matim-count', countMatim)}`;
    },
    countNominativeFile() {
      const countNominative = this.order.orderFiles.filter((file) => file.type === 'nominative').length;
      if (countNominative === 0) {
        return '';
      }
      return `${countNominative} ${this.$tc('nominative-count', countNominative)}`;
    },
    countSummaryFile() {
      const countSummary = this.order.orderFiles.filter((file) => file.type === 'summary').length;
      if (countSummary === 0) {
        return '';
      }
      return `${countSummary} ${this.$tc('summary-count', countSummary)}`;
    },
  },
};
</script>

<style scoped>
.title-file{
  display: flex;
  align-items: center;
}
</style>
