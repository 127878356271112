<template>
  <v-container fluid pa-0>
    <v-card>
      <v-progress-circular
          v-if="loading"
          :size="50"
          indeterminate color="primary"
      >
      </v-progress-circular>
      <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          :no-data-text="noDataMessage"
          hide-default-footer
      >
        <template v-if="!loading" slot="items" slot-scope="props" >
          <tr>
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.type }}</td>
            <td>{{ props.item.senderAddress }}</td>
            <td>{{ props.item.recipientAddress }}</td>
            <td>{{ props.item.status | sendStatusCommunication}}</td>
            <td>{{ props.item.providerStatus | castProviderStatus}}</td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2 clients-pagination">
        <v-pagination v-model="page" :total-visible="7" :length="pages">
        </v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import Lre from '../models/lre';
import { SEARCH_DEBOUNCE_WAIT_TIMER } from '../utils/constants';

const DEFAULT_ROWS_PER_PAGE = 30;
export default {
  name: 'BaseDemoLreList',
  data() {
    return {
      dialogLre: false,
      valid: true,
      lreToSend: new Lre(),
      requiredRules: [
        (v) => !!v || 'Ce champs est obligatoire',
      ],

      emailRules: [
        (v) => !!v || 'E-mail est obligatoire',
        (v) => /.+@.+/.test(v) || 'L\'email doit correspondre a une forme Email',
      ],
      selectedType: null,
      sendType: [
        'lres-equisign',
        'lreq-equisign',
        'email',
      ],
      page: 1,
      pagination: {
        sortBy: 'id', descending: false, rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
      query: {
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
    };
  },
  methods: {
    ...mapActions([
      'communication/createCommunication',
      'communication/getCommunication',
    ]),
    openDialogLre() {
      this.dialogLre = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this['communication/createCommunication'](this.lreToSend);
      }
    },
    reset() {
      this.$refs.form.reset();
      this.dialogLre = false;
    },
    buildQuery() {
      this.query.page = this.page;
    },
    delaySearch: debounce(function search() {
      this.buildQuery();
      this['communication/getCommunication'](this.query);
    }, SEARCH_DEBOUNCE_WAIT_TIMER),
  },
  computed: {
    ...mapState({
      items: (state) => state.communication.items,
      loading: (state) => state.communication.loading,
      headers: (state) => state.communication.headers,
      noDataMessage: (state) => state.communication.noDataMessage,
      total: (state) => state.communication.total,
      stateQuery: (state) => state.communication.query,
      pages: (state) => state.communication.pagination.pages,
    }),
  },
  watch: {
    loading(newVal) {
      if (newVal === false) {
        this.dialogLre = false;
      }
    },
    search: {
      handler() {
        this.query.id = this.search;
        this.delaySearch();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    page: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('communication/getCommunication');
  },
};
</script>

<style scoped>

</style>
