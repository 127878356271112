export const DEFAULT_CUSTOMER_HEADER = [
  {
    value: 'id',
    text: 'N°',
    sortable: true,
    align: 'left',
  },
  {
    value: 'clientReference',
    text: 'Référence client',
    sortable: false,
  },
  {
    value: 'name',
    text: 'Raison sociale',
    sortable: false,
  },
  {
    value: 'salesman',
    text: 'Commercial',
    sortable: false,
  },
  {
    value: 'lreContextType',
    text: 'LRE',
    sortable: false,
  },
  {
    value: 'office',
    text: 'Agence',
    sortable: false,
  },
  {
    value: '#',
    text: 'Actions',
    sortable: false,
  },
];

export default {
  buildEditHeader(activatedField = null) {
    if (!activatedField) {
      return this.defaultheader;
    }
    return this.defaultheader.filter((field) => activatedField.includes(field.value));
  },
  buildInitialHeader() {
    return this.defaultheader.filter((field) => {
      if (field.checked !== false) {
        return field;
      }
      return null;
    });
  },
  defaultheader: DEFAULT_CUSTOMER_HEADER,
};
