<template>
  <div class="not-found-wrapper">
    <h1>{{ msg }}</h1>
    <v-icon size="200">pan_tool</v-icon>
  </div>
</template>

<script>
export default {
  name: 'Denied',
  data() {
    return {
      msg: 'Accès refusé, merci de vous rapprocher de votre administrateur ou de l\'assistance Athome.',
    };
  },
};

</script>

<style scoped>

</style>
