<template>
  <v-container>
    <v-progress-circular
        v-if="loading"
        :size="50"
        indeterminate color="primary"
    />
    <div v-if="!loading">
      <v-row no-gutters >
        <v-col cols="8">
          <base-page-title :page-title="pageTitle"></base-page-title>
        </v-col>
        <v-col cols="4" class="d-flex justify-end">
          <v-btn
              color="primary"
              outlined
              rounded
              depressed
              @click="back"
          >
            <v-icon left light>arrow_back</v-icon>
            Retour à la liste
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <BaseDetailSdcRibMandate :show="show"/>
        </v-col>
      </v-row>
    </div>

  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseDetailSdcRibMandate from './BaseDetailSdcRibMandate';

export default {
  name: 'BaseCustomerDetailSdcBanking',
  data() {
    return {
      show: true,
    };
  },
  components: {
    BaseDetailSdcRibMandate,
  },
  methods: {
    ...mapActions([
      'sdcBankingAccount/get',
    ]),
    back() {
      this.$router.push({ path: `/clients/${this.customer.item.id}`, query: { isSdc: '1' } });
    },
  },
  computed: {
    ...mapState({
      customer: (state) => state.customer,
      sdcBankingAccount: (state) => state.sdcBankingAccount,
      loading: (state) => state.sdcBankingAccount.loading,
    }),

    pageTitle() {
      return `Détail pour le RIB SDC N° ${this.sdcBankingAccount.item.id}`;
    },
  },
};
</script>
