<template>
  <v-container fluid pa-0 >
    <base-mandate-list :mandateType="mandateType"/>
  </v-container>
</template>

<script>
import BaseMandateList from '@/components/BaseMandateList';

export default {
  name: 'PageMandateListLreSimple',
  components: { BaseMandateList },
  data() {
    return {
      mandateType: 'lre-simple',
    };
  },
};
</script>

<style scoped>

</style>
