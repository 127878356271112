<template>
  <v-card>
    <v-card-title class="user-detail-title">
      <v-avatar color="primary" size="34">
        <v-icon dark>receipt</v-icon>
      </v-avatar>
      <span>Informations sur les montants de la facture</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="isShowInvoice = !isShowInvoice">
        <v-icon>{{ isShowInvoiceDetail }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-slide-y-transition>
      <v-container fluid v-show="isShowInvoice">
        <v-layout row v-for="(item, index) in listToShow" :key="index">
          <v-flex xs6 class="list-item pa-0">
            <p><i>{{(item.key)}}</i></p>
          </v-flex>
          <v-flex xs6 class="list-item pa-0">
            <p><b>{{getAllValue(item.value) | getCurrentMoney}}</b></p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
import fetchValue from '../utils/fetch-value';

export default {
  name: 'BaseInvoicePriceInfo',
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isShowInvoice: true,
      listToShow: [
        {
          key: 'Total sans Tva Ht ',
          value: 'totalWithoutTvaHt',
        },
        {
          key: 'Total avec Tva Ht ',
          value: 'totalWithTvaHt',
        },
        {
          key: 'TVA',
          value: 'totalTva',
        },
        {
          key: 'Total TTC',
          value: 'totalTtc',
        },
      ],
    };
  },
  computed: {
    isShowInvoiceDetail() {
      return this.isShowInvoice ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
  },
  methods: {
    getAllValue(value) {
      return fetchValue(value, this.item);
    },
  },
};
</script>

<style scoped>

</style>
