<template>
  <v-card>
    <v-card-title class="user-detail-title">
      <v-avatar color="primary" size="34">
        <v-icon dark>people</v-icon>
      </v-avatar>
      <span>Commande N°{{order.id}}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="show = !show">
        <v-icon>{{ isShow }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-slide-y-transition>
      <v-container fill-height fluid v-show="show">
        <v-layout fill-height class="user-detail-layout">
          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                Numero de l'immeuble
              </p>
              <p class="item">
                {{ this.order.buildingNumber}}
              </p>
            </div>
          </v-flex>
          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                Date limite d'envoi
              </p>
              <p class="item">
                {{ this.order.dla | cleanDate}}
              </p>
            </div>
          </v-flex>
          <v-divider vertical/>
          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                Type de commande
              </p>
              <p class="item">
                {{ $t(this.order.sendType)}}
              </p>
            </div>
          </v-flex>

          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                Date limite d'envoi
              </p>
              <p class="item">
                {{ this.order.dla | cleanDate}}
              </p>
            </div>
          </v-flex>
          <v-flex xs12 md3>
            <div class="text-xs-left user-detail-infos">
              <p class="item-title">
                statut
              </p>
              <p class="item">
                {{ $t(this.order.status)}}
              </p>
            </div>
          </v-flex>
        </v-layout>
    </v-container>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrderDetailInformation',
  data() {
    return {
      show: true,
    };
  },
  methods: {

  },
  computed: {
    ...mapState({
      order: (state) => state.order.item,
    }),
    adressParse() {
      return `${(this.order.buildingAddressFieldOne ? this.order.buildingAddressFieldOne : ' ') + (this.order.buildingAddressFieldTwo ? this.order.buildingAddressFieldTwo : ' ') + (this.order.buildingAddressFieldThree ? this.order.buildingAddressFieldThree : ' ')}${this.order.buildingZipCode} ${this.order.buildingCity}`;
    },
    orderFilesCount() {
      return this.order.orderFiles.length;
    },
    isShow() {
      return this.show ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
  },
};
</script>

<style scoped>

</style>
