<template>
  <v-layout >
    <v-flex d-flex xs12>
      <v-card
          class="card-item"
          elevation="0"
      >
        <span class="headline">{{ $t('refine by')}}</span>
        <div>
          <v-btn color="red" @click="reloadPage"><v-icon>mdi-cancel</v-icon>{{ $t('remove all filters')}}</v-btn>
        </div>
        <div id="filter-block" class="collapse in" aria-expanded="false">
          <v-container
                  fluid
                  grid-list-lg
          >
            <v-layout row wrap>
              <v-flex xs12 v-for="(facet, name, index) in facets.facets" :key= "index">
                <v-card color="blue-grey darken-2" class="white--text" v-if="ObjectLength(facet, name)">
                  <v-card-title primary-title>
                    <div>
<!--                      <span class="headline">{{ $t(facets.facetTags[name]['display_name']) }}</span> -->
                    </div>
                  </v-card-title>
                  <v-card-actions>
                    <div :id="'list'+ index" class="collapse" aria-expanded="false">
                      <div
                          v-for="(element) in facet"
                          :key= "element.key"
                      >
                        <div v-if="facetLength[name] < 15">
                          <v-checkbox
                              dark
                              v-if="
                                element.doc_count
                                && facets.facetTags[name]['keyword'] !== true
                              "
                              v-model='filters.taxons[`${name}`]'
                              :label="element | formatLabel"
                              :value="`${name}_${element.key}`"
                          >
                          </v-checkbox>
                          <v-checkbox
                              dark
                              v-if="
                                element.doc_count
                                && facets.facetTags[name]['keyword'] === true
                              "
                              v-model='filters.taxons[`${name}`]'
                              :label="element | formatLabel"
                              :value="`${name}.keyword_${element.key}`"
                          >
                          </v-checkbox>
                        </div>
                      </div>
                      <div v-if="facetLength[name] >= 15">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-text-field
                                v-model="searchText[facets.facetTags[name]['display_name']]"
                                append-icon="search"
                                label="Recherche"
                                hide-details
                                dark
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div v-if="facets.facetTags[name]['type'] === 'price' && saveOnLoad(facets.facetTags[name]['values'], name)">
                        <v-layout
                            v-for="(element) in facet" :key= "element.key"
                        >
                          <v-flex>
                            <v-range-slider
                                v-model="range[name]"
                                v-on:change="searchRange(name)"
                                :max="facets.facetTags[name]['limits'][1]"
                                :min="facets.facetTags[name]['limits'][0]"
                                :step="0.01"
                                hide-details
                                class="align-center"
                            >
                            <template v-slot:prepend>
                              <v-text-field
                                  dark
                                  :value="range[name][0]"
                                  v-on:change="updateRangePrice($set(range[name], 0, $event), name, 0)"
                                  class="mt-0 pt-0"
                                  single-line
                                  type="number"
                                  style="width: auto"
                              ></v-text-field>
                            </template>
                            <template v-slot:append>
                              <v-text-field
                                  dark
                                  :value="range[name][1]"
                                  v-on:change="updateRangePrice($set(range[name], 1, $event), name, 1)"
                                  class="mt-0 pt-0"
                                  single-line
                                  type="number"
                                  style="width: auto"
                              ></v-text-field>
                            </template>
                            </v-range-slider>
                          </v-flex>
                        </v-layout>
                      </div>
                    </div>
                    <div v-if="facets.facetTags[name]['type'] === 'date'">
                        <v-col
                            cols="12"
                            sm="12"
                        >
                          <v-menu
                              ref="menu"
                              v-model="menu[name]"
                              :close-on-content-click="false"
                              :return-value.sync="dates[name]"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  dark
                                  v-model="dateRangeText[name]"
                                  label="Sélectionnez une ou deux dates"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dates[name]"
                                range
                                no-title
                                scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="menu[name] = false"
                              >
                                {{ $t('cancel')}}
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="searchRangeDates(name)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import i18n from '@/i18n';

const DEFAULT_ROWS_PER_PAGE = 15;

export default {
  name: 'BaseFacets',
  props: {
    facets: {
      default: null,
    },
  },
  data: () => ({
    filters: {
      taxons: {},
      range: {},
      dates: {},
    },
    searchText: {},
    range: {},
    query: {
      itemsPerPage: DEFAULT_ROWS_PER_PAGE,
      order: {},
      filters: {},
    },
    dates: [],
    menu: [],
    dateRangeText: [],
    page: 1,
    facetLength: [],
  }),
  watch: {
    filters: {
      handler() {
        this.onInputUpdateParent();
      },
      deep: true,
    },
    searchText: {
      handler() {
        Object.entries(this.searchText).forEach((entry) => {
          this.query.string = `${entry[0]}:*${entry[1]}*`;
        });
        this.onSearchUpdateParent();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      stateQuery: (state) => state.invoice.query,
    }),
    pageTitle() {
      return this.loading ? `${this.title}` : `${this.title} (${this.total})`;
    },
  },
  methods: {
    onInputUpdateParent() {
      this.$emit('updateFacets', this.filters);
    },
    onSearchUpdateParent() {
      this.$emit('updateFacets', this.query.string);
    },
    translateFacetTypeMethod(name) {
      let composedName = name;
      if (name.match(/taxons/g)) {
        composedName = 'taxons';
      }
      if (name.match(/range-date/g)) {
        composedName = 'range-date';
      }
      if (name.match(/range-price/g)) {
        composedName = 'range-price';
      }
      if (name.startsWith('date')) {
        composedName = 'date';
      }
      return composedName;
    },
    saveOnLoad(values, name) {
      if (!this.range.length) {
        this.range[name] = values;
      }

      return true;
    },
    searchRangeDates(name) {
      if (this.dates[name][0] > this.dates[name][1]) {
        this.dates[name].reverse();
      }
      this.dateRangeText[name] = this.dates[name].join(' ~ ');
      this.menu[name] = false;
      this.filters.dates[name] = `${name}_min_${this.dates[name][0]}&${name}_max_${this.dates[name][1]}`;
      this.onInputUpdateParent();
    },
    searchRange(name) {
      this.filters.range[name] = `${name}_min_${this.range[name][0]}&${name}_max_${this.range[name][1]}`;
      this.onInputUpdateParent();
    },
    updateRangePrice(value, name, type) {
      if (type === 0) {
        this.range[name][0] = value;
      }
      if (type === 1) {
        this.range[name][1] = value;
      }
      if (this.range[name][0] > this.range[name][1]) {
        this.range[name].reverse();
      }
      this.searchRange(name);
    },
    ObjectLength(facet, name) {
      let length = 0;
      Object.values(facet).forEach((value) => {
        if (value.doc_count > 0) {
          length += 1;
        }
      });
      this.facetLength[name] = length;

      return true;
    },
    ...mapActions([
      'invoice/getInvoices',
    ]),
    buildQuery() {
      this.query.page = this.page;
      this.query.sortBy = [];
    },
    reloadPage() {
      window.location.reload();
    },
  },
  filters: {
    translateFacetType(name) {
      return this.translateFacetTypeMethod(name);
    },
    formatLabel(element) {
      const label = element.label.includes(':') ? element.label.split(':')[1] : element.label;
      return `${i18n.t(label)} (${element.doc_count})`;
    },
  },
  created() {
  },
};
</script>

<style scoped>
.card-item{
  background-color: whitesmoke;
  overflow: scroll;
  height: 800px;
}
</style>
