<template>
  <div class="hello content-header-h2">
    <h1>{{ pageTitle }}</h1>
  </div>
</template>

<script>
export default {
  name: 'BasePageSubTitle',
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  h1, h2 {
    font-weight: normal;
    text-align: left;
  }
  .content-header-h2{
    margin-bottom: 20px;
    margin-left: 15px;
  }
</style>
