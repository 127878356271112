<template>
  <v-card class="pa-2">
    <v-card-title class="user-detail-title">
      <v-avatar color="primary" size="34">
        <v-icon dark>account_circle</v-icon>
      </v-avatar>
      <span>Commercial</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="showCommercial = !showCommercial">
        <v-icon>{{ isShowCommercial }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-slide-y-transition>
      <v-container fluid v-show="showCommercial">
        <v-layout row v-for="(item, index) in displayCustomerField" :key="index">
          <v-flex xs6 class="list-item pa-0">
            <p><i>{{item.key}}</i></p>
          </v-flex>
          <v-flex xs6 class="list-item pa-0">
            <p v-if="item.value !== 'salesman'"><b>{{getAllValue(item.value)}}</b></p>
            <p v-else><b>{{getAllValue(item.value) | formatSalesmanName}}</b></p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-slide-y-transition>
  </v-card>
</template>
<script>
import fetchValue from '../utils/fetch-value';

export default {
  name: 'base-customer-salesman-detail',
  props: {
    customer: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showCommercial: true,
      displayCustomerField: [
        {
          key: 'Nom:',
          value: 'salesman',
        },
        {
          key: 'Email:',
          value: 'salesman.email',
        },
        {
          key: 'Rattachement Athome: ',
          value: 'salesman.office.name',
        },
      ],
    };
  },
  computed: {
    isShowCommercial() {
      return this.showCommercial ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
  },
  methods: {
    getAllValue(value) {
      return fetchValue(value, this.customer);
    },
  },
};
</script>
<style scoped>
.list-item {
  display: flex;
  justify-content: start;
}

.user-detail-title span {
  margin-left: 10px;
}

.user-roles-table tr {
  background-color: #f3f3f3;
}

.user-roles-table tr td {
  width: 50%;
  padding: 12px 15px;
  color: #565656;
}

.user-roles-table tr td:first-child {
  text-align: left;
}

.user-roles-table tr td:last-child {
  padding-bottom: 0;
}

@media (max-width: 599px) {
}
</style>
