<template>
  <v-card>
    <v-card-title class="user-detail-title">
      <v-avatar color="primary" size="34">
        <v-icon dark>home</v-icon>
      </v-avatar>
      <span>Adresse de l'immeuble</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="isShowInvoice = !isShowInvoice">
        <v-icon>{{ isShowInvoiceDetail }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-slide-y-transition>
      <v-container fluid v-show="isShowInvoice">
        <v-layout row v-for="(item, index) in listToShow" :key="index">
          <v-flex xs6 class="list-item pa-0">
            <p><i>{{(item.key)}}</i></p>
          </v-flex>
          <v-flex xs6 class="list-item pa-0">
            <p><b>{{$i18n.t(getAllValue(item.value))}}</b></p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
import fetchValue from '@/utils/fetch-value';

export default {
  name: 'BaseInvoiceRecipientAddress',
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isShowInvoice: true,
      listToShow: [
        {
          key: 'Adresse de l\'immeuble a facture ',
          value: 'recipientAddress.placeOfDelivery',
        },
        {
          key: 'supplément d\'adresse de l\'immeuble',
          value: 'recipientAddress.supplementLocationOfConstruction',
        },
        {
          key: 'Rue',
          value: 'recipientAddress.streetAddress',
        },
        {
          key: 'adresse additionnel',
          value: 'recipientAddress.additional',
        },
        {
          key: 'Code postal',
          value: 'recipientAddress.postalCode',
        },
        {
          key: 'Ville',
          value: 'recipientAddress.addressLocality',
        },
        {
          key: 'Pays',
          value: 'recipientAddress.addressCountry',
        },
      ],
    };
  },
  computed: {
    isShowInvoiceDetail() {
      return this.isShowInvoice ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
  },
  methods: {
    getAllValue(value) {
      return fetchValue(value, this.item);
    },
  },
};
</script>

<style scoped>

</style>
