import Vue from 'vue';
import Router from 'vue-router';
import PageSupportCustomersList from '@/pages/PageSupportCustomersList';
import PageSupportClientShow from '@/pages/PageSupportClientShow';
import PageOrderForm from '@/pages/PageOrderForm';
import PageOrdersList from '@/pages/PageOrdersList';
import PageOrderListInbox from '@/pages/PageOrderListInbox';
import PageOrderListProcessed from '@/pages/PageOrderListProcessed';
import PageDashBoard from '@/pages/PageDashBoard';
import PageInvoiceList from '@/pages/PageInvoiceList';
import PageInvoiceDetail from '@/pages/PageInvoiceShow';
import PageCampaignNew from '@/pages/PageCampaignNew';
import PageCampaignShow from '@/pages/PageCampaignShow';
import PageCampaignList from '@/pages/PageCampaignList';
import PageCampaignWrapper from '@/pages/PageCampaignWrapper';
import PageMandateListLreQ from '@/pages/PageMandateListLreQ';
import PageMandateDetails from '@/pages/PageMandateDetails';
import PageMandateListLreSimple from '@/pages/PageMandateListLreSimple';

import NotFound from '@/components/NotFound';
import Denied from '@/components/Denied';
import AuthenticationGuard from '@/utils/AuthGuard';
import PageMandateSdcDetail from './pages/PageMandateSdcDetail';
import store from './store';
import PageDemoClient from './pages/PageDemoClient';

Vue.use(Router);
const roles = [
  {
    name: 'PageMandateListSimple',
    role: ['FINANCE'],
  },
  {
    name: 'PageMandateDetails',
    role: ['FINANCE'],
  },
  {
    name: 'PageMandateList',
    role: ['FINANCE'],
  },
  {
    name: 'PageCampaignList',
    role: ['FINANCE'],
  },
  {
    name: 'PageCampaignNew',
    role: ['FINANCE'],
  },
  {
    name: 'PageCampaignEdit',
    role: ['FINANCE'],
  },
  {
    name: 'PageCampaignShow',
    role: ['FINANCE'],
  },
];

function requireAuth(to, from, next) {
  if (!AuthenticationGuard.isLoggedIn()) {
    AuthenticationGuard.login();
  } else {
    next();
  }
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: PageDashBoard,
      beforeEnter: requireAuth,
    },
    {
      path: '/clients',
      name: 'PageSupportCustomersList',
      component: PageSupportCustomersList,
      beforeEnter: requireAuth,
    },
    {
      path: '/mandate-sdc/:id',
      name: 'PageMandateSdcDetail',
      component: PageMandateSdcDetail,
      beforeEnter: requireAuth,
    },
    {
      path: '/clients/:id',
      name: 'PageClientContactShow',
      component: PageSupportClientShow,
      beforeEnter: requireAuth,
    },
    {
      path: '/orders',
      name: 'PageOrdersList',
      component: PageOrdersList,
    },
    {
      path: '/orders/inbox',
      name: 'PageOrdersListInbox',
      component: PageOrderListInbox,
    },
    {
      path: '/orders/processed',
      name: 'PageOrderListProcessed',
      component: PageOrderListProcessed,
    },
    {
      path: '/order/:id',
      name: 'PageOrderForm',
      component: PageOrderForm,
    },
    {
      path: '/finance',
      name: 'PageInvoiceList',
      component: PageInvoiceList,
      beforeEnter: requireAuth,
    },
    {
      path: '/invoice/:id',
      name: 'PageInvoiceDetail',
      component: PageInvoiceDetail,
      beforeEnter: requireAuth,
    },
    {
      path: '/mandate',
      name: 'PageMandateListLreSimple',
      component: PageMandateListLreSimple,
      beforeEnter: requireAuth,
    },
    {
      path: '/mandate/lreq',
      name: 'PageMandateListLreQ',
      component: PageMandateListLreQ,
      beforeEnter: requireAuth,
    },
    {
      path: '/mandate/lre-simple',
      name: 'PageMandateListSimple',
      component: PageMandateListLreSimple,
      beforeEnter: requireAuth,
    },
    {
      path: '/mandate/:id',
      name: 'PageMandateDetails',
      component: PageMandateDetails,
      beforeEnter: requireAuth,
    },
    {
      path: '/recouvrements/outils',
      component: PageCampaignWrapper,
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          name: 'PageRecouvrement',
          component: PageCampaignList,
          beforeEnter: requireAuth,
        },
        {
          path: 'campagne-prelevement',
          name: 'PageCampaignList',
          component: PageCampaignList,
          beforeEnter: requireAuth,
        },
        {
          path: 'new',
          name: 'PageCampaignNew',
          component: PageCampaignNew,
          beforeEnter: requireAuth,
        },
        {
          path: 'campaign/edition/:id',
          name: 'PageCampaignEdit',
          component: PageCampaignNew,
          beforeEnter: requireAuth,
        },
        {
          path: 'campaign/show/:id',
          name: 'PageCampaignShow',
          component: PageCampaignShow,
          beforeEnter: requireAuth,
        },
      ],
    },
    {
      path: '/demo-client',
      name: 'PageDemoclient',
      component: PageDemoClient,
      beforeEnter: requireAuth,
    },
    {
      path: '/403',
      name: 'Denied',
      component: Denied,
    },
    {
      path: '/404',
      name: 'NotFound',
      component: NotFound,
    },

  ],
});

router.beforeEach((to, from, next) => {
  store.commit('notice/unsetNotice');
  if (!to.matched.length) {
    next('/404');
  } else {
    if (roles.find((element) => element.name === to.name)) {
      const requiredRole = roles.find((element) => element.name === to.name);
      if (requiredRole) {
        if (AuthenticationGuard.isGranted(requiredRole.role)) {
          next();
        } else {
          next('/403');
        }
      }
    }
    next();
  }
});
export default router;
