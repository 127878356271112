<template>
  <div>
    <v-layout row wrap class="content-header-2col">
      <v-flex xs2 >
        <base-demo-lre-send/>
      </v-flex>
      <v-flex xs10 fluid>
        <base-demo-lre-list/>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import BaseDemoLreSend from '../components/BaseDemoLreSend';
import BaseDemoLreList from '../components/BaseDemoLreList';

export default {
  name: 'PageDemoClient',
  components: { BaseDemoLreList, BaseDemoLreSend },
};
</script>

<style scoped>
</style>
