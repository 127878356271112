import cleanDate from '@/filters/cleanDate';
import cleanDateTime from '@/filters/cleanDateTime';
import isYesOrNo from '@/filters/isYesOrNo';
import getCurrentMoney from '@/filters/getCurrentMoney';
import concatSalesmanName from '@/filters/concatSalesmanName';
import concatCustomerAddress from '@/filters/concatCustomerAddress';
import sendStatusCommunication from '@/filters/sendStatusCommunication';
import castProviderStatus from '@/filters/castProviderStatus';
import formatSalesmanName from '@/filters/formatSalesmanName';
import formatFileSize from '@/filters/formatFileSize';

export default {
  install(Vue) {
    Vue.filter('cleanDate', cleanDate);
    Vue.filter('cleanDateTime', cleanDateTime);
    Vue.filter('isYesOrNO', isYesOrNo);
    Vue.filter('getCurrentMoney', getCurrentMoney);
    Vue.filter('concatSalesmanName', concatSalesmanName);
    Vue.filter('concatCustomerAddress', concatCustomerAddress);
    Vue.filter('sendStatusCommunication', sendStatusCommunication);
    Vue.filter('castProviderStatus', castProviderStatus);
    Vue.filter('formatSalesmanName', formatSalesmanName);
    Vue.filter('formatFileSize', formatFileSize);
  },
};
