<template>
  <v-card class="pa-2">
    <v-card-title>
      <v-flex
          sm12
          class="
        rounded-card
        content-header-2col-title
        d-flex justify-center
        align-center
        fill-height
        white
        flex-card"
      >
        <div>
          <base-page-title
              v-bind:page-title="
          ` Visualisation du Mandat
          ${this.mandateLocal.mandateType}
          N°${this.mandateLocal.id}`"
          />
        </div>
        <v-btn text icon color="red lighten-2" @click="deleteMandate" >
          <v-icon>delete</v-icon>
        </v-btn>
        <v-btn
            color="primary"
            outlined
            rounded
            depressed
            @click="returnToList"
            :replace=true

        >
          <v-icon left light>arrow_back</v-icon>
          Retour
        </v-btn>
      </v-flex>
    </v-card-title>
    <v-divider/>
    <v-layout class="pa-1">
      <v-flex xs6>
        <v-text-field
            v-model="mandateLocal.customer"
            label="Client"
            required
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
            v-model="mandateLocal.customerId"
            label="N° Client"
            required
        />
      </v-flex>
    </v-layout>
    <v-layout class="pa-1">
      <v-flex xs6>
        <v-text-field
            v-model="mandateLocal.salesMan"
            label="Commercial"
            required
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
            v-model="mandateLocal.office"
            label="Agence"
            required
        />
      </v-flex>
    </v-layout>
    <v-divider/>
    <!-- lre q -->
    <div v-if="mandateType=== 'lre-q'">
      <v-layout class="pa-1">
        <v-flex xs6>
          <v-select
              v-model="mandateLocal.sendMode"
              :items="items"
              item-text="state"
              item-value="abbr"
              label="Mode d'envoi"
              persistent-hint
              single-line
          />
        </v-flex>
        <v-flex xs6>
          <v-menu
              v-model="datePickerReturnedAt"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="setupReturnedAt"
                  v-on="on"
                  label="Date d'envoi au client"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="event"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="mandateLocal.returnedAt"
                no-title
                @input="datePickerReturnedAt = false"/>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout class="pa-1">
        <v-flex xs6>
          <v-menu
              v-model="datePickerSignedAt"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="setupSignedAt"
                  v-on="on"
                  label="Date de signature par le client"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="event"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="mandateLocal.signedAt"
                no-title
                @input="datePickerSignedAt = false"
            />
          </v-menu>
        </v-flex>
         <v-flex xs6>
           <v-menu
               v-model="activateLreAt"
               :close-on-content-click="false"
               :nudge-right="40"
               lazy
               transition="scale-transition"
               offset-y
               full-width
               max-width="290px"
               min-width="290px"
           >
             <template v-slot:activator="{ on }">
               <v-text-field
                   v-model="lreActivatedAt"
                   v-on="on"
                   label="Date d'activation LRE Qualifiée"
                   hint="DD/MM/YYYY format"
                   persistent-hint
                   prepend-icon="event"
                   @click.stop
                   readonly
               ></v-text-field>
             </template>
             <v-date-picker
                 v-model="mandateLocal.activationLreSimpleAt"
                 no-title
                 ref="datepicker"
                 @input="activateLreAt = false"
             />
           </v-menu>
        </v-flex>
      </v-layout>
    </div>
    <!-- lre simple -->
    <div v-if="mandateType=== 'lre-simple'">
      <v-layout class="pa-1">
        <v-flex xs6>
          <v-text-field
              v-model="mandateLocal.returnedPaper"
              label="Nombre de jours pour le retour papier"
          />
        </v-flex>
        <v-flex xs6>
          <v-menu
              v-model="askedLreAt"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="setupAskedAt"
                  v-on="on"
                  label="Date de demande au client"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="event"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="mandateLocal.askedAt"
                no-title
                @input="askedLreAt = false"/>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout class="pa-1">
        <v-flex xs6>
          <v-menu
              v-model="activateLreAt"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="lreActivatedAt"
                  v-on="on"
                  label="Date d'activation Lre simple"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="event"
                  @click.stop
                  readonly
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="mandateLocal.activationLreSimpleAt"
                no-title
                ref="datepicker"
                @input="activateLreAt = false"
            />
          </v-menu>
        </v-flex>
      </v-layout>
    </div>
    <v-divider/>
    <v-layout row wrap>
      <v-flex xs12 sm4 md4>
        <v-switch
            v-model="mandateLocal.isActivate"
            label="décision retenue (Mandat ok)"
            hide-details
        ></v-switch>
      </v-flex>
      <v-flex xs12 sm4 md4>
        <v-switch
            v-model="mandateLocal.isWorkFlowReady"
            label="Mise en place WFDS"
            hide-details
        ></v-switch>
      </v-flex>
      <v-flex xs12 sm4 md4>
        <v-switch
            v-model="mandateLocal.isActivateLre"
            @change="focusActivateLreAt"
            label="Activation LRE"
            hide-details
        ></v-switch>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <v-textarea
            v-model="mandateLocal.comment"
            :value="mandateLocal.comment"
            auto-grow
            filled
            label="Commentaire"
            rows="2"
        ></v-textarea>
      </v-flex>
    </v-layout>
    <v-divider/>
    <div class="d-flex">
      <base-upload-mandate
          v-bind:extensions="filesExtension"
          v-bind:mime-types="mimeTypes"
          v-on:newUploadedFile="updateFilesToUpload"
      />
      <v-btn class="light-green" @click="updateMandate">Valider</v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Mandate from '@/models/mandate';

export default {
  name: 'BaseMandateUpdate',
  props: {
    mandateType: { required: true },
  },
  data: () => ({
    mandateLocal: new Mandate(),
    items: [
      { state: 'En avance', abbr: 'advance' },
      { state: 'En concomitance', abbr: 'concomitant' },
    ],
    datePickerReturnedAt: false,
    datePickerSignedAt: false,
    askedLreAt: false,
    activateLreAt: false,
    filesExtension: ['csv', 'pdf'],
    mimeTypes: ['application/pdf', 'text/csv', 'application/vnd.ms-excel'],
  }),
  computed: {
    ...mapState({
      mandate: (state) => state.mandate.item,
    }),
    setupSignedAt() {
      if (this.mandateLocal.signedAt) {
        return moment(String(this.mandateLocal.signedAt)).format('DD/MM/YYYY');
      }
      return '';
    },
    setupAskedAt() {
      if (this.mandateLocal.askedAt) {
        return moment(String(this.mandateLocal.askedAt)).format('DD/MM/YYYY');
      }
      return '';
    },
    lreActivatedAt() {
      if (this.mandateLocal.activationLreSimpleAt) {
        return moment(String(this.mandateLocal.activationLreSimpleAt)).format('DD/MM/YYYY');
      }
      return '';
    },
    setupReturnedAt() {
      if (this.mandateLocal.returnedAt) {
        return moment(String(this.mandateLocal.returnedAt)).format('DD/MM/YYYY');
      }
      return '';
    },
  },
  mounted() {
    this.mandateLocal = JSON.parse(JSON.stringify(this.mandate));
  },
  methods: {
    focusActivateLreAt() {
      if (this.mandateLocal.isActivateLre) {
        this.activateLreAt = true;
      } else {
        this.mandateLocal.activationLreSimpleAt = '';
      }
    },
    updateMandate() {
      const updatedMandate = this.mandateLocal;
      delete updatedMandate.uploadedFiles;
      if (this.mandateLocal.sendMode === null) {
        updatedMandate.sendMode = 'null';
      }
      this.$store.dispatch('mandate/updateMandate', updatedMandate);
    },
    deleteMandate() {
      this.$store.dispatch('mandate/deleteMandate', this.mandateLocal.id);
      this.$router.push({ name: (this.mandate.mandateType === 'lre-simple') ? 'PageMandateListSimple' : 'PageMandateList' });
    },
    deleteMandateFile(fileId) {
      const payload = { mandate: this.mandate, fileId };
      this.$store.dispatch('mandate/deleteMandateFile', payload);
    },
    returnToList() {
      this.$router.push({ name: (this.mandate.mandateType === 'lre-simple') ? 'PageMandateListSimple' : 'PageMandateList' });
      this.$store.commit('mandate/RESET_MANDATE');
    },
    updateFilesToUpload(files) {
      const payload = { mandate: this.mandate, files };
      this.$store.dispatch('mandate/uploadMandateFile', payload);
    },
  },
};
</script>

<style scoped>

</style>
