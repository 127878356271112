import api from '@/utils/api';
import createDownloadFile from '@/utils/generateDownloadPdf';
import Router from '../../router';

const state = {
  loading: false,
  item: null,
};

const actions = {
  get({ commit }, id) {
    commit('setLoading', { loading: true });
    api.getData(`/api/sdc-banking-accounts/${id}`)
      .then((res) => {
        commit('SET_ITEM_BANKING_ACCOUNT', res.data);
        commit('setLoading', { loading: false });
      })
      .catch((err) => console.log(err));
  },
  create({ commit }, payload) {
    commit('setLoading', { loading: true });
    const data = JSON.stringify(payload.data);
    const jsonObjectData = JSON.parse(data);
    jsonObjectData.customer = `/api/customers/${payload.customerId}`;
    api
      .postData('/api/sdc-banking-accounts', JSON.stringify(jsonObjectData))
      .then(() => {
        commit('setLoading', { loading: false });
        commit(
          'notice/setNotice',
          {
            text: 'Mandat créé avec succès',
            color: 'info  ',
          },
          { root: true },
        );
      })
      .catch((err) => {
        commit(
          'notice/setNotice',
          {
            text: `${err.data['hydra:description']}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  update({ commit, rootState }, payload) {
    commit('setLoading', { loading: true });
    api
      .putData(`/api/sdc-banking-accounts/${payload.id}`, JSON.stringify(payload.data))
      .then(() => {
        commit('setLoading', { loading: false });
        commit(
          'notice/setNotice',
          {
            text: 'Mandat modifié',
            color: 'info  ',
          },
          { root: true },
        );

        Router.push({ path: `/clients/${rootState.customer.item.id}`, query: { isSdc: '1' } });
      })
      .catch((err) => {
        commit(
          'notice/setNotice',
          {
            text: `${err.data['hydra:description']}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  delete({ commit }, payload) {
    commit('setLoading', { loading: true });
    const data = new FormData();
    data.append('userIdentifier', payload.basicUser.email);
    api
      .postData(`/api/sdc-banking-accounts/${payload.sdcBankingAccountId}/delete`, data)
      .then(() => {
        commit('setLoading', { loading: false });
        commit(
          'notice/setNotice',
          {
            text: 'Mandat supprimé',
            color: 'info  ',
          },
          { root: true },
        );
      })
      .catch((err) => {
        commit(
          'notice/setNotice',
          {
            text: `${err.data['hydra:description']}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  downloadSdcBankingAccountSignedSepa({ commit }, payload) {
    return api.downloadFile(`/api/sdc-banking-accounts/${payload.sdcBankingAccount.id}/download`)
      .then((res) => {
        createDownloadFile(res, payload.customer, 'pdf', payload.sdcBankingAccount.rumPath);
        commit('setLoading', { loading: false });
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
  uploadSdcBankingAccountSignedSepa({ commit }, payload) {
    console.log('toto');
    commit('setLoading', { loading: true });
    const data = new FormData();
    payload.filesBankingAccountSignedSepa.forEach((element) => {
      data.append('signedSepa', element.file);
    });
    data.append('userIdentifier', payload.basicUser.email);
    api
      .uploadloadFiles(`/api/sdc-banking-accounts/${payload.sdcBankingAccount.id}/upload-one-signed-sepa`, data)
      .then(() => {
        commit('setLoading', { loading: false });
        commit(
          'notice/setNotice',
          {
            text: 'Document(s) ajouté(s)',
            color: 'info  ',
          },
          { root: true },
        );
      })
      .catch((err) => {
        commit(
          'notice/setNotice',
          {
            text: `${err.data['hydra:description']}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
};

const mutations = {
  SET_ITEM_BANKING_ACCOUNT(state, itemBankingSdc) {
    state.item = itemBankingSdc;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
