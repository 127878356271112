import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import paramConverter from 'jquery-param';
import store from '@/store';
import AuthenticationGuard from '@/utils/AuthGuard';
import router from '@/router';
import { isActionCancelledError } from '@/utils/cancel-action';

const BASE_URL = process.env.VUE_APP_API_FLUX_BASE_URL;
const DEFAULT_ERROR_MESSAGE = 'impossible de faire aboutir votre requête. Merci de réessayer plus tard, ou de contacter l\'assistance';
const DEFAULT_LOADING_MESSAGE = 'Chargement ...';
const DEFAULT_EMPTY_LIST_MESSAGE = 'Aucun Résultat ...';
const DEFAULT_AUTO_ABORT_MESSAGE = '__AUTO_ABORTED__';

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: false,
  params: {}, // In need if you want to add new params.
});

httpClient.interceptors.request.use(
  (config) => {
    const defaultConfig = config;
    const token = store.state.user.oidcUser.access_token;
    const baseUrl = store.state.config.url;
    if (baseUrl) {
      defaultConfig.baseURL = baseUrl;
    }

    if (token) {
      defaultConfig.headers.common.Authorization = `Bearer ${token}`;
    }

    return defaultConfig;
  },
  (error) => Promise.reject(error),
);

httpClient.interceptors.response.use((response) => response, (error) => {
  const repStatus = error && error.response ? error.response.status : null;
  if (repStatus === 401) {
    AuthenticationGuard.login();
  }

  if (repStatus === 403) {
    router.push('/403');
  }

  /* if (repStatus === 404) {
    router.push('/404');
  } */

  return Promise.reject(error.response);
});

/**
 * Override the axios params setter
 * @param {Object} params
 * @param {CancelToken} params.cancelToken
 */
const addParam = ({ cancelToken = null, ...rest } = {}) => {
  const result = {
    ...rest,
  };
  if (cancelToken) {
    result.cancelToken = cancelToken;
  }
  return result;
};

export default {
  isAnAbortError: (error) => (error && error.message === DEFAULT_AUTO_ABORT_MESSAGE)
      || isActionCancelledError(error)
      || axios.isCancel(error),
  getCancelToken: () => axios.CancelToken.source(),
  abort(cancelToken, message = DEFAULT_AUTO_ABORT_MESSAGE) {
    if (!cancelToken || !cancelToken.cancel) return;
    cancelToken.cancel(message);
  },
  getData(action, query = {}, requestParams = {}) {
    let urlToCall = action;
    if (!isEmpty(query)) {
      urlToCall += `?${paramConverter(query)}`;
    }
    return httpClient.get(urlToCall, addParam(requestParams)).then();
  },
  postData: (action, data, requestParams = {}) => httpClient.post(action, data, addParam(requestParams)),
  putData: (action, data) => httpClient.put(action, data),
  deleteData: (action) => httpClient.delete(action),
  patchData: (action, data) => httpClient.patch(action, data),
  login: (action, data) => httpClient.get(action, data),
  getTrust: (action, idToken, accessToken, requestParams = {}) => httpClient.post(
    action,
    [],
    addParam({
      ...requestParams,
      headers: {
        'x-fiducial-token': `Bearer ${idToken}`,
        'x-fiducial-at': `Bearer ${accessToken}`,
      },
    }),
  ),
  downloadFile: (action, requestParams = {}) => httpClient.get(
    action,
    addParam({
      ...requestParams,
      responseType: 'blob',
    }),
  ),
  downloadFilePost: (action, body, requestParams = {}) => httpClient.post(
    action,
    body,
    addParam({
      ...requestParams,
      responseType: 'blob',
    }),
  ),
  uploadloadFiles: (action, data, requestParams = {}) => httpClient.post(
    action,
    data,
    addParam({
      ...requestParams,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  ),
  fillErrorListMessage: (string) => `Impossible d'obtenir votre liste ${string}. Merci de réessayer plus tard, ou de contacter l'assistance`,
  redirectOnNotFound(status) {
    if (status === 404) {
      router.push(`/${status}`);
    }
  },
  errorMessage: DEFAULT_ERROR_MESSAGE,
  loadingMessage: DEFAULT_LOADING_MESSAGE,
  emptyListMessage: DEFAULT_EMPTY_LIST_MESSAGE,
};
