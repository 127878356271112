import api from '@/utils/api';
import { wrapActionWithRequest } from '@/utils/cancel-action';

const state = {
  noDataMessage: '',
  loading: false,
  items: [],
  states: [],
  item: null,
};

const getNotesAction = ({ commit }, query = { }) => {
  commit('setLoading', { loading: true });
  commit('setQuery', query);
  api.getData(`/api/customers/${query.customer}/notes`, query).then((res) => {
    commit('setItems', res.data);
    commit('setNoDataMessage', api.emptyListMessage);
  }).catch(() => {
    commit('setLoading', { loading: false });
  });
};

const actions = {
  getNotes: wrapActionWithRequest(getNotesAction, { autoAbort: true }),
  addNewNote({ commit }, payloadNote) {
    api.postData(`/api/customers/${payloadNote.customer}/notes`, payloadNote.newCommentObject).then((response) => {
      commit('ADD_NEW_NOTE', response.data);
    });
  },
};

const mutations = {
  setItems(state, notes) {
    state.items = notes;
    state.loading = false;
  },
  setNoDataMessage(state, message) {
    state.noDataMessage = message;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    state.items = [];
    state.total = 0;
  },
  setQuery(state, query) {
    state.query = query;
  },
  ADD_NEW_NOTE(state, newNote) {
    state.items.push(newNote);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
