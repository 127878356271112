<template>
  <v-card>

    <div class="pdf-review">
      <iframe
          :src="urlConcact"
          class="pdf-view"/>
    </div>
    <v-layout class="pa-1">
      <v-flex xs6>
        <div>
          <v-btn icon @click="changeIndex(indexFile - 1)">
            <v-icon>remove</v-icon>
          </v-btn>
          {{nbrpage}}
          <v-btn icon @click="changeIndex(indexFile + 1)">
            <v-icon>add</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs6>
        <v-btn
            class="error"
            @click.stop="dialog = true">
          Supprimer ce document
        </v-btn>
        <v-dialog
            v-model="dialog"
            max-width="290"
        >
          <v-card>
            <v-card-text>
              <p>Êtes-vous sûr de vouloir supprimer ce document ?</p>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-around">
              <v-spacer></v-spacer>
              <v-btn
                  flat="flat"
                  @click="dialog = false"
              >
                Annuler
              </v-btn>
              <v-spacer/>
              <v-btn
                  color="red"
                  flat="flat"
                  @click="deleteMandateFile(mandateLocal.uploadedFiles[indexFile].id)"
              >
                Supprimer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

const BASE_URL = process.env.VUE_APP_API_FLUX_BASE_URL;

export default {
  name: 'BaseMandateFileRender',
  props: {
    uploadedFiles: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    indexFile: 0,
    dialog: false,
  }),
  computed: {
    ...mapState({
      mandate: (state) => state.mandate.item,
    }),
    mandateLocal() {
      return JSON.parse(JSON.stringify(this.mandate));
    },
    nbrpage() {
      return `${this.indexFile + 1}/${this.mandateLocal.uploadedFiles.length}`;
    },
    urlConcact() {
      return `${BASE_URL}/uploads/mandates/${this.mandateLocal.uploadedFiles[this.indexFile].fileName}`;
    },
  },
  methods: {
    deleteMandateFile(fileId) {
      this.dialog = false;
      const payload = { mandate: this.mandateLocal, fileId };
      this.$store.dispatch('mandate/deleteMandateFile', payload);
      this.indexFile = 0;
    },
    changeIndex(indexChange) {
      if (indexChange >= 0 && indexChange < this.mandateLocal.uploadedFiles.length) {
        this.indexFile = indexChange;
      }
    },
  },
};
</script>

<style scoped>
.pdf-review{
  height: 560px;
  width: 100%
}
.pdf-view{
  height: 100%;
  width: 100%
}
</style>
