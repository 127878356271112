<template>
    <div class="text-xs-center">
        <v-dialog
                v-model="dialog"
                width="550"
                persistent
                transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title
                        class="headline secondary white--text"
                        primary-title
                >
                    <v-btn icon dark
                           @click="dialogSwitch"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                    Veuillez définir votre nouvelle campagne.
                </v-card-title>

                <v-card-text>
                    <v-flex xs12>
                        <v-text-field
                                v-model.trim="campaign.name"
                                :error-messages="nameErrors"
                                label="Nom de la campagne"
                                required
                                @input="$v.campaign.name.$touch()"
                                @blur="$v.campaign.name.$touch()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                                v-model="campaign.sender"
                                label="Expéditeur*"
                                required
                                :error-messages="emailErrors"
                                @input="$v.campaign.sender.$touch()"
                                @blur="$v.campaign.sender.$touch()"

                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                                v-model="campaign.subject"
                                label="Sujet*"
                                required
                                :error-messages="subjectErrors"
                                @input="$v.campaign.subject.$touch()"
                                @blur="$v.campaign.subject.$touch()"

                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-menu
                                ref="menu1"
                                v-model="menu1"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                max-width="290px"
                                min-width="290px"
                        >
                            <template slot="activator">
                                <v-text-field
                                        v-model="dateFormatted"
                                        label="Date"
                                        hint="DD/MM/YYYY format"
                                        persistent-hint
                                        prepend-icon="event"
                                        @blur="campaign.sendDate = parseDate(dateFormatted)"
                                        :error-messages="sendDateErrors"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="campaign.sendDate"
                                no-title
                                @input="menu1 = false"/>
                        </v-menu>
                    </v-flex>
                    <v-flex xs12>
                    <base-rich-text-area
                            v-if ="dialog"
                            v-model="campaign.body"
                            :value="campaign.body"
                            :error-messages="bodyErrors"
                            @input="$v.campaign.body.$touch()"
                            @blur="$v.campaign.body.$touch()"
                    ></base-rich-text-area>
                    </v-flex>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            round
                            depressed
                            mx-3
                            @click="close"
                            :loading="loading"
                    >
                        Continuer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import Campaign from '@/models/campaign';

export default {
  name: 'BaseCampaignDialog',
  mixins: [validationMixin],
  validations: {
    campaign: {
      sender: { required, email },
      name: { required },
      subject: { required },
      body: { required },
      sendDate: { required },
    },
  },
  data() {
    return {
      dialog2: false,
      campaign: new Campaign(),
      date: null,
      dateFormatted: null,
      menu1: false,
    };
  },
  computed: {
    ...mapState({
      dialog: (state) => state.campaign.dialog,
      loading: (state) => state.campaign.loading,
      campaignStore: (state) => state.campaign.item,

    }),
    computedDateFormatted() {
      return this.formatDate(this.campaign.sendDate);
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.campaign.sender.$dirty) return errors;

      if (!this.$v.campaign.sender.email) {
        errors.push('L\'email n\'est pas conforme');
      }
      if (!this.$v.campaign.sender.required) {
        errors.push('Ce champ est requis');
      }

      if (this.campaign.sender && !this.campaign.sender.match(/@athome\.fr$/g)) {
        errors.push(`L'adresse mail de l'expéditeur" ${this.campaign.sender} " est incorrecte, elle doit être une adresse @athome.fr.`);
      }

      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.campaign.name.$dirty) return errors;

      if (!this.$v.campaign.name.required) {
        errors.push('Ce champ est requis');
      }

      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v.campaign.subject.$dirty) return errors;

      if (!this.$v.campaign.subject.required) {
        errors.push('Ce champ est requis');
      }

      return errors;
    },
    bodyErrors() {
      const errors = [];
      if (!this.$v.campaign.body.$dirty) return errors;

      if (!this.$v.campaign.body.required) {
        errors.push('Ce champ est requis');
      }

      return errors;
    },
    sendDateErrors() {
      const errors = [];
      if (!this.$v.campaign.sendDate.$dirty) return errors;

      if (!this.$v.campaign.sendDate.required) {
        errors.push('Ce champ est requis');
      }

      return errors;
    },
  },
  watch: {
    campaign: {
      handler() {
        this.dateFormatted = this.formatDate(this.campaign.sendDate);
      },
      deep: true,
    },
    dialog() {
      this.loadCampaign();
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    close() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // eslint-disable-next-line no-unused-expressions
        (!this.$route.params.id) ? this.$store.dispatch('campaign/save', { campaign: this.campaign }) : this.$store.dispatch('campaign/update', { campaign: this.campaign, owner: this.$route.params.id });
      }
    },
    dialogSwitch() {
      this.$store.commit('campaign/dialogSwich', false);
    },
    loadCampaign() {
      if (this.campaignStore && this.$route.params.id) {
        this.campaign.sender = this.campaignStore.sender;
        this.campaign.body = this.campaignStore.body;
        this.campaign.name = this.campaignStore.name;
        this.campaign.subject = this.campaignStore.subject;
        this.campaign.sendDate = moment(this.campaignStore.sendDate)
          .format('YYYY-MM-DD');
      } else {
        this.campaign = new Campaign();
      }
    },
  },
  created() {
    this.loadCampaign();
  },
};
</script>

<style scoped>

</style>
