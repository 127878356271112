<template>
  <div>
    <v-btn
        color="error"
        @click.stop="isDeleted = true"
        icon
    >
      <v-icon>
        mdi-trash-can-outline
      </v-icon>
    </v-btn>

    <v-dialog
        v-model="isDeleted"
        max-width="420"
    >
      <v-card>
        <v-card-title>
          Voulez vous vraiment supprimer ce rib ?
        </v-card-title>
        <v-card-actions class="d-flex justify-space-between">

          <v-btn
              color="blue"
              text
              @click="toggleDialog"
          >
            Annuler
          </v-btn>

          <v-btn
              color="red"
              text
              @click="deleteRib"
          >
            Supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BaseButtonDeleteSdcRib',
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isDeleted: false,
    };
  },
  computed: {
    ...mapState({
      basicUser: (state) => state.user.oidcUser,
    }),
  },
  methods: {
    ...mapActions([
      'sdcBankingAccount/delete',
    ]),
    toggleDialog() {
      this.isDeleted = !this.isDeleted;
    },
    deleteRib() {
      const sdcBankingAccountId = this.id;
      this['sdcBankingAccount/delete']({ sdcBankingAccountId, basicUser: this.basicUser.profile });
      this.isDeleted = false;
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>

</style>
