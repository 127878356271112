const getDefaultState = () => ({
  headers: [
    { text: 'Client', value: 'customer' },
    { text: 'Agence ', value: 'office' },
    { text: 'Commercial', value: 'salesMan' },
    { text: 'N° Client', value: 'customerId' },
    { text: 'décision retenue', value: 'mandatOk' },
    { text: 'Mode d\'envoi', value: 'sendMode' },
    { text: 'Activation LREQ NS', value: 'lreActivated' },
    { text: 'Date d\'activation LRE', value: 'lreActivatedAt' },
    { text: 'WorkFlow', value: 'workFlow' },
    { text: 'Date signature contrat', value: 'signedAt' },
    { text: 'Date de retour au client', value: 'returnedAt' },
    { text: 'Commentaire', value: 'comment' },
    { text: 'Crée le :', value: 'createdAt' },
    { text: 'Action', value: 'actions' },
  ],
  lreSimpleHeader: [
    { text: 'Client', value: 'customer' },
    { text: 'N° Client', value: 'customerId' },
    { text: 'Retour papier', value: 'paperReturnedCount' },
    { text: 'Activation LRE', value: 'isActivateLre' },
    { text: 'Mandat Ok', value: 'mandateOk' },
    { text: 'WorkFlow', value: 'workFlow' },
    { text: 'Date de demande', value: 'askedAt' },
    { text: 'Date d\'activation LRE', value: 'lreActivatedAt' },
    { text: 'Agence ', value: 'office' },
    { text: 'Commercial', value: 'salesMan' },
    { text: 'Crée le ', value: 'createdAt' },
    { text: 'Commentaire', value: 'comment' },
    { text: 'Action', value: 'actions' },
  ],
});
const state = getDefaultState();

const mutations = {};
const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
